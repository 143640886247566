import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useNavigate } from 'react-router-dom';

import AppTopbar from './AppTopbar';
// import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';

import Dashboard from './components/Dashboard';
import Login from './pages/Login';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import '@fortawesome/fontawesome-free/css/all.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import './assets/fonts/fonts.css';

import ShowMessageDialog from './utilities/ShowMessageDialog';
import { useSelector, useDispatch } from 'react-redux';
import { showMessageAction } from './redux/actions/showMessageAction';
import Module from './pages/UserManagement/module/Module';
import Menu from './pages/UserManagement/menu/Menu';
import Roles from './pages/UserManagement/roles/Roles';
import RoleRights from './pages/UserManagement/rolerights/RoleRights';
import Users from './pages/UserManagement/users/Users';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManageCustomers from './pages/ManageCustomers/ManageCustomers';
import ViewManageCustomers from './pages/ManageCustomers/ViewManageCustomers';
import LoadingOverlay from 'react-loading-overlay';
import Validators from './pages/Configurations/Validators/Validators';
import AddEditValidators from './pages/Configurations/Validators/AddEditValidators';
import KycSets from './pages/Configurations/KycSets/KycSets';
import KycAttributes from './pages/Configurations/KycAttributes/KycAttributes';
import TransactionLimitValidator from './pages/Configurations/TransactionLimitValidator/TransactionLimitValidator';
import AddEditTransactionLimitValidator from './pages/Configurations/TransactionLimitValidator/AddEditTransactionLimitValidator';
import Fees from './pages/Configurations/Fee/FeeConfiguration/Fees';
import AddEditFees from './pages/Configurations/Fee/FeeConfiguration/AddEditFees';
// import ThresholdFee from './pages/Configurations/Fee/ThresholdFeeConfiguration/ThresholdFee';
import AddEditThresholdFee from './pages/Configurations/Fee/ThresholdFeeConfiguration/AddEditThresholdFee';
import FeeShareConfiguration from './pages/Configurations/Fee/FeeShareConfiguration/FeeShareConfiguration';
import AddFeeShareConfiguration from './pages/Configurations/Fee/FeeShareConfiguration/AddFeeShareConfiguration';
import AgentCommissionFee from './pages/Configurations/Fee/FeeShareConfiguration/AgentCommissionFee';
// import RegimeLevel from './pages/Configurations/Tax/RegimeLevel/RegimeLevel';
// import TaxType from './pages/Configurations/Tax/TaxType/TaxType';
import UserGroup from './pages/UserGroup/UserGroup';
import AddUsergroup from './pages/UserGroup/AddUserGroup';
import UserManagement from './pages/UserManagement/UserManagement';
import AddUserManagement from './pages/UserManagement/AddUserManagement';
import EditUserManagement from './pages/UserManagement/EditUserManagement';
import EditUsergroup from './pages/UserGroup/EditUserGroup';
import TaxProfile from './pages/TaxProfile/TaxProfile';
import AddTaxProfile from './pages/TaxProfile/AddTaxProfile';
import TypeOfAccount from './pages/TypeOfAccount/TypeOfAccount';
import AddTypeOfAccount from './pages/TypeOfAccount/AddTypeOfAccount';
import EditTypeOfAccount from './pages/TypeOfAccount/EditTypeOfAccount';
import TransactionWiseLimit from './pages/transactionLimit/TransactionWiseLimit';
import EditTransactionwiseLimit from './pages/transactionLimit/EditTransactionwiseLimit';
import AddtransactionwiseLimit from './pages/transactionLimit/AddtransactionwiseLimit';
import ViewTransactionwiseLimit from './pages/transactionLimit/ViewTransactionwiseLimit';
import RequestChecker from './pages/Administration/Authorization/RequestChecker';
import EditTypeOfAccountChecker from './pages/TypeOfAccount/Checker/EditTypeOfAccountChecker';
import ViewTypeOfAccountChecker from './pages/TypeOfAccount/Checker/ViewTypeOfAccountChecker';
import EditTransactionwiseLimitChecker from './pages/transactionLimit/checker/EditTransactionwiseLimitChecker';
import ViewTransactionwiseLimitChecker from './pages/transactionLimit/checker/ViewTransactionwiseLimitChecker';
import ViewTypeOfAccount from './pages/TypeOfAccount/ViewTypeOfAccount';
import ViewUsergroup from './pages/UserGroup/ViewUserGroup';
import ViewUsergroupChecker from './pages/UserGroup/Checker/ViewUserGroupChecker';
import EditUsergroupChecker from './pages/UserGroup/Checker/EditUserGroupChecker';
import EditKycAttributesChecker from './pages/Configurations/KycAttributes/Checker/EditKycAttributesChecker';
import ViewKycAttributesChecker from './pages/Configurations/KycAttributes/Checker/ViewKycAttributesChecker';
import ViewUserManagement from './pages/UserManagement/ViewUserManagement';
import EditTaxProfile from './pages/TaxProfile/EditTaxProfile';
import EditTaxProfileChecker from './pages/TaxProfile/Checker/EditTaxProfileChecker';
import ViewTaxProfileChecker from './pages/TaxProfile/Checker/ViewTaxProfileChecker';
import ViewTaxProfile from './pages/TaxProfile/ViewTaxProfile';
import EditKycSetsChecker from './pages/Configurations/KycSets/Checker/EditKycSetsChecker';
import ViewKycSetsChecker from './pages/Configurations/KycSets/Checker/ViewKycSetsChecker';
import EditUserChecker from './pages/UserManagement/Checker/EditUserChecker';
import ViewUserManageChecker from './pages/UserManagement/Checker/ViewUserManageChecker';
import Segments from './pages/Segments/Segments';
import EditValidatorChecker from './pages/Configurations/Validators/Checker/EditValidatorChecker';
import ViewValidatorChecker from './pages/Configurations/Validators/Checker/ViewValidatorChecker';
import AddSegment from './pages/Segments/AddSegment';
import EditSegments from './pages/Segments/EditSegments';
import ViewSegment from './pages/Segments/ViewSegment';
import EditCheckerSegment from './pages/Segments/checker/EditCheckerSegment';
import ViewCheckerSegment from './pages/Segments/checker/ViewCheckerSegment';
import EditTaxTypeChecker from './pages/TaxProfile/Checker/EditTaxTypeChecker';

import Pricing from './pages/Pricing/Pricing';
import AddPricingFixed from './pages/Pricing/PricingFixed/AddPricingFixed';
import AddPricingTxtAmount from './pages/Pricing/PricingTxtAmount/AddPricingTxtAmount';
import AddPricingSlabWiseFixed from './pages/Pricing/PricingSlabWiseFixed/AddPricingSlabWiseFixed';
import AddPricingSlabWiseHybrid from './pages/Pricing/PricingSlabWiseHybrid/AddPricingSlabWiseHybrid';
import AddPricingSlabWisePercentage from './pages/Pricing/PricingSlabWisePercentage/AddPricingSlabWisePercentage';
import AddPricingConditionalFee from './pages/Pricing/PricingConditionalFee/AddPricingConditionalFee';

import EditPricingFixed from './pages/Pricing/PricingFixed/EditPricingFixed';
import EditPricingTxtAmount from './pages/Pricing/PricingTxtAmount/EditPricingTxtAmount';
import EditPricingSlabWiseFixed from './pages/Pricing/PricingSlabWiseFixed/EditPricingSlabWiseFixed';
import EditPricingSlabWiseHybrid from './pages/Pricing/PricingSlabWiseHybrid/EditPricingSlabWiseHybrid';
import EditPricingSlabWisePercentage from './pages/Pricing/PricingSlabWisePercentage/EditPricingSlabWisePercentage';
import EditPricingConditionalFee from './pages/Pricing/PricingConditionalFee/EditPricingConditionalFee';

import ViewPricingFixed from './pages/Pricing/PricingFixed/ViewPricingFixed';
import ViewPricingTxtAmount from './pages/Pricing/PricingTxtAmount/ViewPricingTxtAmount';
import ViewPricingSlabWiseFixed from './pages/Pricing/PricingSlabWiseFixed/ViewPricingSlabWiseFixed';
import ViewPricingSlabWiseHybrid from './pages/Pricing/PricingSlabWiseHybrid/ViewPricingSlabWiseHybrid';
import ViewPricingSlabWisePercentage from './pages/Pricing/PricingSlabWisePercentage/ViewPricingSlabWisePercentge';
import ViewPricingConditionalFee from './pages/Pricing/PricingConditionalFee/ViewPricingConditionalFee';

import EditPricingFixedChecker from './pages/Pricing/Checker/PricingFixedChecker/EditPricingFixedChecker';
import EditPricingTxtAmountChecker from './pages/Pricing/Checker/PricingTxtAmountChecker/EditPricingTxtAmountChecker';
import EditPricingSlabWiseFixedChecker from './pages/Pricing/Checker/PricingSlabWiseFixedChecker/EditPricingSlabWiseFixedChecker';
import EditPricingSlabWiseHybridChecker from './pages/Pricing/Checker/PricingSlabWiseHybridChecker/EditPricingSlabWiseHybridChecker';
import EditPricingSlabWisePercentageChecker from './pages/Pricing/Checker/PricingSlabWisePercentageChecker/EditPricingSlabWisePercentageChecker';
import EditPricingConditionalFeeChecker from './pages/Pricing/Checker/PricingConditionalFeeChecker/EditPricingConditionalFeeChecker';

import ViewPricingFixedChecker from './pages/Pricing/Checker/PricingFixedChecker/ViewPricingFixedChecker';
import ViewPricingTxtAmountChecker from './pages/Pricing/Checker/PricingTxtAmountChecker/ViewPricingTxtAmountChecker';
import ViewPricingSlabWiseFixedChecker from './pages/Pricing/Checker/PricingSlabWiseFixedChecker/ViewPricingSlabWiseFixedChecker';
import ViewPricingSlabWiseHybridChecker from './pages/Pricing/Checker/PricingSlabWiseHybridChecker/ViewPricingSlabWiseHybridChecker';
import ViewPricingSlabWisePercentageChecker from './pages/Pricing/Checker/PricingSlabWisePercentageChecker/ViewPricingSlabWisePercentageChecker';
import ViewPricingConditionalFeeChecker from './pages/Pricing/Checker/PricingConditionalFeeChecker/ViewPricingConditionalFeeChecker';
import ViewTaxTypeChecker from './pages/TaxProfile/Checker/ViewTaxTypeChecker';
import EditRegimeLevelChecker from './pages/TaxProfile/Checker/EditRegimeLevelChecker';
import ViewRegimeLevelChecker from './pages/TaxProfile/Checker/ViewRegimeLevelChecker';
import UseCaseManagement from './pages/UseCaseManagement/UseCaseManagement';
import AdduseCaseManagement from './pages/UseCaseManagement/AddUseCaseManagement';

import EdituseCaseManagement from './pages/UseCaseManagement/EditUseCaseManagement';
import ViewuseCaseManagement from './pages/UseCaseManagement/ViewCaseManagement';

import Commission from './pages/Commission/Commission';
import AddFixedCom from './pages/Commission/FixedCommission/AddFixedCom';
import AddPercentageOfTrxAmount from './pages/Commission/PercentageOfTrxAmount/AddPercentageOfTrxAmount';
import AddSlabWiseFixed from './pages/Commission/SlabWiseFixed/AddSlabWiseFixed';
import AddSlabWisePercentage from './pages/Commission/SlabWisePercentage/AddSlabWisePercentage';
import AddSlabWiseHybridFixPer from './pages/Commission/SlabWiseHybirdFixedPercentage/AddSlabWiseHybirdFixPer';
import EditFixedCom from './pages/Commission/FixedCommission/EditFixedCom';
import ViewFixedCom from './pages/Commission/FixedCommission/ViewFixedCom';
import EditPercentageOfTrxAmount from './pages/Commission/PercentageOfTrxAmount/EditPercentageOfTrxAmount';
import ViewPercentageOfTrxAmount from './pages/Commission/PercentageOfTrxAmount/ViewPercentageOfTrxAmount';
import EditSlabWiseFixed from './pages/Commission/SlabWiseFixed/EditSlabWiseFixed';
import ViewSlabWiseFixed from './pages/Commission/SlabWiseFixed/ViewSlabWiseFixed';
import EditSlabWisePercentage from './pages/Commission/SlabWisePercentage/EditSlabWisePercentage';
import ViewSlabWisePercentage from './pages/Commission/SlabWisePercentage/ViewSlabWisePercentage';
import EditSlabWiseHybridFixPer from './pages/Commission/SlabWiseHybirdFixedPercentage/EditSlabWiseHybirdFixPer';
import ViewSlabWiseHybridFixPer from './pages/Commission/SlabWiseHybirdFixedPercentage/ViewSlabWiseHybirdFixPer';
import EditFixedCommissionChecker from './pages/Commission/Checker/FixedCommissionChecker/EditFixedCommissionChecker';
import ViewFixedCommissionChecker from './pages/Commission/Checker/FixedCommissionChecker/ViewFixedCommissionChecker';
import EditPercentageOfTrxChecker from './pages/Commission/Checker/PercentageOfTrxAmountChecker/EditPercentageOfTrxChecker';
import ViewPercentageOfTrxChecker from './pages/Commission/Checker/PercentageOfTrxAmountChecker/ViewPercentageOfTrxChecker';
import EditSlabWiseFixedChecker from './pages/Commission/Checker/SlabWiseFixedChecker/EditSlabWiseFixedChecker';
import ViewSlabWiseFixedChecker from './pages/Commission/Checker/SlabWiseFixedChecker/ViewSlabWiseFixedChecker';
import EditSlabWisePercentageChecker from './pages/Commission/Checker/SlabWisePercentageChecker/EditSlabWisePercentageChecker';
import ViewSlabWisePercentageChecker from './pages/Commission/Checker/SlabWisePercentageChecker/ViewSlabWisePercentageChecker';
import EditSlabWiseHybridChecker from './pages/Commission/Checker/SlabWiseHybirdChecker/EditSlabWiseHybirdChecker';
import ViewSlabWiseHybridChecker from './pages/Commission/Checker/SlabWiseHybirdChecker/ViewSlabWiseHybirdChecker';

import ManageAccounts from './pages/CX-1/AccountsManagement/AccountDetails/ManageAccounts';
import AccountDetails from './pages/CX-1/AccountsManagement/AccountDetails/AccountDetails';
import AccountDetailsEdit from './pages/CX-1/AccountsManagement/AccountDetails/AccountDetailsEdit';
import ViewCustomerDetails from './pages/CX-1/AccountsManagement/AccountDetails/ViewCustomerDetails';
// import EditZindagiCardDetails from './pages/CX-1/zindagiCards/EditZindagiCardDetails';
// import EditCheckerSignUpLimit from './pages/CMS/Checker/EditCheckerSignUpLimit';

// CX Field Management
import CxFieldMgmt from './pages/CX-1/CxFieldManagement/Pages/MainPage';
import AddCxFieldMgmt from './pages/CX-1/CxFieldManagement/Pages/AddPage';
import EditCxFieldMgmt from './pages/CX-1/CxFieldManagement/Pages/EditPage';
import ViewCxFieldMgmt from './pages/CX-1/CxFieldManagement/Pages/ViewPage';

// CX Role Management
import CxRoleMgmt from './pages/CX-1/CxRoleManagement/Pages/MainPage';
import AddCxRoleMgmt from './pages/CX-1/CxRoleManagement/Pages/AddPage';
import EditCxRoleMgmt from './pages/CX-1/CxRoleManagement/Pages/EditPage';
import ViewCxRoleMgmt from './pages/CX-1/CxRoleManagement/Pages/ViewPage';

import AccountDetailsCheckerEdit from './pages/CX-1/Checker/AccountDetailsCheckerEdit';
import AccountDetailsCheckerView from './pages/CX-1/Checker/AccountDetailsCheckerView';
import CheckBookNoEdit from './pages/CX-1/UpgradeToUltra/UltraDualNationalityNo/CheckBookNo/CheckBookNoEdit';
import CheckBookNoView from './pages/CX-1/UpgradeToUltra/UltraDualNationalityNo/CheckBookNo/CheckBookNoView';
import DualNationalityFields from './pages/CX-1/UpgradeToUltra/Component/DualNationalityFields';
import Channels from './pages/Channels/ChannelManagement/Channels';
import AddNewChannel from './pages/Channels/ChannelManagement/AddNewChannel';
import ProductCatalog from './pages/ProductCatalog/ProductCatalogManagement/ProductCatalog';
import AddNewProductCatalog from './pages/ProductCatalog/ProductCatalogManagement/AddNewProductCatalog';
import ChannelEdit from './pages/Channels/ChannelManagement/ChannelEdit';
import ChannelView from './pages/Channels/ChannelManagement/ChannelVIew';
import ChannelCheckerEdit from './pages/Channels/Checker/ChannelCheckerEdit';
import ChannelCheckerView from './pages/Channels/Checker/ChannelCheckerView';
import ProductCatalogView from './pages/ProductCatalog/ProductCatalogManagement/ProductCatalogView';
import ProductCatalogEdit from './pages/ProductCatalog/ProductCatalogManagement/ProductCatalogEdit';
import ProductCatalogCheckerEdit from './pages/ProductCatalog/Checker/ProductCatalogCheckerEdit';
import ProductCatalogCheckerView from './pages/ProductCatalog/Checker/ProductCatalogCheckerView';
import ForgetPasswordOTP from './pages/forgetpassword/ForgetPasswordOTP';
import UpdatePassword from './pages/forgetpassword/UpdatePassword';
import VerifyOtp from './pages/forgetpassword/VerifyOtp';
// import ViewZindagiCardDetails from './pages/CX-1/zindagiCards/ViewZindagiCardDetails';
// import EditZindagiCardDetailChecker from './pages/CX-1/zindagiCards/checker/EditZindagiCardDetailChecker';
// import ViewZindagiCardDetailChecker from './pages/CX-1/zindagiCards/checker/ViewZindagiCardDetailChecker';
import TransactionConfiguration from './pages/TransactionConfiguration/TransactionConfiguration';
import AddTransactionConfiguration from './pages/TransactionConfiguration/AddTransactionConfiguration';
import EditTransactionConfiguration from './pages/TransactionConfiguration/EditTransactoinConfiguration';
import ViewTransactionConfiguration from './pages/TransactionConfiguration/ViewTransactionConfiguration';

import GeneralLedger from './pages/GeneralLedger/GeneralLedger';
import ViewGeneralLedgerChecker from './pages/GeneralLedger/Checker/ViewGeneralLedgerChecker';
import EditGeneralLedgerChecker from './pages/GeneralLedger/Checker/EditGeneralLedgerChecker';

import EnterpriseServiceBus from './pages/ESB/EnterpriseServiceBus';
import AddEnterpriseServiceBus from './pages/ESB/AddEnterpriseServiceBus';
import EditEnterpriseServiceBus from './pages/ESB/EditEnterpriseServiceBus';
import ViewEnterpriseServiceBus from './pages/ESB/ViewEnterpriseServiceBus';
import EditEnterpriseServiceBusChecker from './pages/ESB/Checker/EditEnterpriseServiceBusChecker';
import ViewEnterpriseServiceBusChecker from './pages/ESB/Checker/ViewEnterpriseServiceBusChecker';
import EditTransactionConfigChecker from './pages/TransactionConfiguration/Checker/EditTransactionConfigChecker';
import ViewTransactionConfigChecker from './pages/TransactionConfiguration/Checker/ViewTransactionConfigChecker';
import AgentOnboarding from './pages/AgentOnboarding/AgentOnboardingManagement/Pages/AgentOnboarding';
import AddNewAgentOnboarding from './pages/AgentOnboarding/AgentOnboardingManagement/Pages/AddNewAgentOnboarding';
import EditAgentOnboarding from './pages/AgentOnboarding/AgentOnboardingManagement/Pages/EditNewAgentOnboarding';
import ViewAgentOnboarding from './pages/AgentOnboarding/AgentOnboardingManagement/Pages/ViewNewAgentOnboarding';

import SalesHierarchy from './pages/SalesHierarchy/CreateRegion/create_region';
import AddCreateReg from './pages/SalesHierarchy/CreateRegion/AddCreateRegion';
import EditCreateReg from './pages/SalesHierarchy/CreateRegion/EditCreateRegion';
import ViewCreateReg from './pages/SalesHierarchy/CreateRegion/ViewCreateRegion';

import CreateSalesRoles from './pages/SalesHierarchy/CreateSaleRoles/CreateMaintainSalesRoles';
import AddCreateSalesRoles from './pages/SalesHierarchy/CreateSaleRoles/AddCreateSalesRoles';
import EditSalesRoles from './pages/SalesHierarchy/CreateSaleRoles/EditSalesRoles';
import ViewSalesRoles from './pages/SalesHierarchy/CreateSaleRoles/ViewSalesRoles';

import SalesForce from './pages/SalesHierarchy/SalesForce/SalesForce';
import AddCreateSalesForce from './pages/SalesHierarchy/SalesForce/AddCreateSalesForce';
import EditSalesForce from './pages/SalesHierarchy/SalesForce/EditSalesForce';
import ViewSalesForce from './pages/SalesHierarchy/SalesForce/ViewSalesForce';

import MaintainSalesHierarchy from './pages/SalesHierarchy/MaintainSalesHierarchy/MaintainSalesHierarchy';
import AddSalesHierarchy from './pages/SalesHierarchy/MaintainSalesHierarchy/AddSalesHierarchy';
import EditSalesHierarchy from './pages/SalesHierarchy/MaintainSalesHierarchy/EditSalesHierarchy';
import ViewSalesHierarchy from './pages/SalesHierarchy/MaintainSalesHierarchy/ViewSalesHierarchy';

import EditSalesRegionChecker from './pages/SalesHierarchy/Checker/SalesRegionChecker/EditSalesRegionChecker';
import ViewSalesRegionChecker from './pages/SalesHierarchy/Checker/SalesRegionChecker/ViewSalesRegionChecker';

import EditSalesRolesChecker from './pages/SalesHierarchy/Checker/SalesRolesChecker/EditSalesRolesChecker';
import ViewSalesRolesChecker from './pages/SalesHierarchy/Checker/SalesRolesChecker/ViewSalesRolesChecker';

import EditSalesForceChecker from './pages/SalesHierarchy/Checker/SalesForceChecker/EditSalesForceChecker';
import ViewSalesForceChecker from './pages/SalesHierarchy/Checker/SalesForceChecker/ViewSalesForceChecker';

import EditSalesHierarchyChecker from './pages/SalesHierarchy/Checker/SalesHierarchyChecker/EditSalesHierarchyChecker';
import ViewSalesHierarchyChecker from './pages/SalesHierarchy/Checker/SalesHierarchyChecker/ViewSalesHierarchyChecker';
import DisbursementUploadPage from './pages/BulkOperations/BulkDisbursement/DisbursementUploadPage';
import DisbursementFileSummary from './pages/BulkOperations/BulkDisbursement/DisbursementFileSummary';
import BulkDisbursementView from './pages/BulkOperations/BulkDisbursement/Components/BulkDisbursementView';
import ScheduledBulkPayment from './pages/BulkOperations/BulkDisbursement/ScheduledBulkPayments';
// import BulkPaymentsReceivingReport from './pages/BulkOperations/BulkDisbursement/BulkPaymentsReceivingReport';
import BulkOperationsReport from './pages/BulkOperations/BulkDisbursement/Components/BulkOperationsReport';
import Checkeragentonboardingedit from './pages/AgentOnboarding/Checkers/Pages/checkeragentonboardingedit';
import Checkeragentonboardingview from './pages/AgentOnboarding/Checkers/Pages/checkerviewagentonboarding';
import CampaignManagement from './pages/CampaignManagement/CampaignManagement';
import AddCampaignManagement from './pages/CampaignManagement/AddCampaignManagement';
import EditCampignManagementChecker from './pages/CampaignManagement/Checker/EditCampaginManagementChecker';
import ViewCampignManagementChecker from './pages/CampaignManagement/Checker/ViewCampaginManagementChecker';
import EditCampaignManagement from './pages/CampaignManagement/EditCampaignManagement';
import ViewCampaignManagement from './pages/CampaignManagement/ViewCampaignManagement';

// Chart Of Accounts
import Organization from './pages/ChartOfAccounts/Pages/Organization';
import AddOrganization from './pages/ChartOfAccounts/Components/Organization/AddOrganization';
import Location from './pages/ChartOfAccounts/Pages/Location';
import AddLocation from './pages/ChartOfAccounts/Components/Location/AddLocation';
import EditLocation from './pages/ChartOfAccounts/Components/Location/EditLocation';
import CostCenter from './pages/ChartOfAccounts/Pages/CostCenter';
import AddCostCenter from './pages/ChartOfAccounts/Components/CostCenter/AddCostCenter';
import EditCostCenter from './pages/ChartOfAccounts/Components/CostCenter/EditCostCenter';
import Category from './pages/ChartOfAccounts/Pages/Category';
import AddCategory from './pages/ChartOfAccounts/Components/Category/AddCategory';
import EditCategory from './pages/ChartOfAccounts/Components/Category/EditCategory';
import SubCategoryCoa from './pages/ChartOfAccounts/Pages/SubCategory';
import AddSubCategoryCoa from './pages/ChartOfAccounts/Components/SubCategory/AddSubCategory';
import EditSubCategoryCoa from './pages/ChartOfAccounts/Components/SubCategory/EditSubCategory';
import Currency from './pages/ChartOfAccounts/Pages/Currency';
import AddCurrency from './pages/ChartOfAccounts/Components/Currency/AddCurrency';
import EditCurrency from './pages/ChartOfAccounts/Components/Currency/EditCurrency';
import MrpCode from './pages/ChartOfAccounts/Pages/MrpCode';
import AddMprCode from './pages/ChartOfAccounts/Components/Mrp/AddMrpCode';
import EditMprCode from './pages/ChartOfAccounts/Components/Mrp/EditMrp';
import ViewCOA from './pages/ChartOfAccounts/Pages/ViewCOA';
import AddChartOfAccounts from './pages/ChartOfAccounts/Components/ChartOfAccounts/AddChartOfAccounts';
import EditChartOfAccounts from './pages/ChartOfAccounts/Components/ChartOfAccounts/EditChartOfAccounts';
// Commission Hierarchy
import AddCommissionHierarchy from './pages/CommissionHierarchy/Pages/AddCommissionHierarchy';
import EditCommissionHierarchy from './pages/CommissionHierarchy/Pages/EditCommissionHierarchy';
import ViewCommissionHierarchy from './pages/CommissionHierarchy/Pages/ViewCommissionHierarchy';
import CheckerEditCommissionHierarchy from './pages/CommissionHierarchy/Pages/CheckerEditCommissionHierarchy';
import CheckerViewCommissionHierarchy from './pages/CommissionHierarchy/Pages/CheckerViewCommissionHierarchy';
// t24 account link
import T24accountLink from './pages/Configurations/T24AccountLink/Pages/T24accountlink';
import AddT24accountLink from './pages/Configurations/T24AccountLink/Pages/AddT24AccountLink';
import EditT24accountLink from './pages/Configurations/T24AccountLink/Pages/EditT24AccountLink';
import ViewT24accountLink from './pages/Configurations/T24AccountLink/Pages/ViewT24AccountLink';
import CheckerEditT24accountLink from './pages/Configurations/T24AccountLink/Pages/CheckerEditT24AccountLink';
import CheckerViewT24accountLink from './pages/Configurations/T24AccountLink/Pages/CheckerViewT24AccountLink';

// import BlacklistMarking from './pages/BulkOperations/BlacklistMarking/BlacklistMarking';
import BlacklistMarkingUpload from './pages/BulkOperations/BlacklistMarking/BlacklistMarkingUpload/BlacklistMarkingUpload';
import BlacklistMarkingUploadFile from './pages/BulkOperations/BlacklistMarking/BlacklistMarkingUpload/BlacklistMarkingUploadFile';
import BlacklistMarkingBulkSummary from './pages/BulkOperations/BlacklistMarking/BlacklistMarkingUpload/BlacklistMarkingBulkSummary';
import BulkBlacklistedCnicExternal from './pages/BulkOperations/BlacklistMarking/BulkBlacklistCnicExternal';
// import EditDisbursementUploadChecker from './pages/BulkOperations/Checker/DisbursementUploadChecker/EditDisbursementUploadChecker';
// import ViewDisbursementUploadChecker from './pages/BulkOperations/Checker/DisbursementUploadChecker/ViewDisbursementUploadChecker';
// UltraAccountVerification
import UltraAccountVerification from './pages/AccountVerification/UltraAccountVerification/Pages/UltraAccountVerification';
import EditUav from './pages/AccountVerification/UltraAccountVerification/Pages/EditUav';
import ViewUav from './pages/AccountVerification/UltraAccountVerification/Pages/ViewUav';
import CheckerEditUav from './pages/AccountVerification/UltraAccountVerification/Pages/CheckerEditUav';
import CheckerViewUav from './pages/AccountVerification/UltraAccountVerification/Pages/CheckerViewUav';

import FilerNonFilerUpdate from './pages/BulkOperations/BulkFilerUpload/FilerNonFilerUpdate/FilerNonFilerUpdate';
import UploadFilerNonFilerBulk from './pages/BulkOperations/BulkFilerUpload/FilerNonFilerUpdate/UploadFilerNonFilerBulk';
import BulkAccountStatusChange from './pages/BulkOperations/BulkFilerUpload/BulkAccountStatusChange/BulkAccountStatusChange';
import BulkAccountStatusUpload from './pages/BulkOperations/BulkFilerUpload/BulkAccountStatusChange/BulkAccountStatusUpload';
import BulkAccountStatusDownload from './pages/BulkOperations/BulkFilerUpload/BulkAccountStatusChange/BulkAccountStatusDownload';
import BulkAccountStatusChangeSummary from './pages/BulkOperations/BulkFilerUpload/BulkAccountStatusChange/ViewBulkAccountStatusChangeSummary';
import BulkAccountClosureSummary from './pages/BulkOperations/BulkFilerUpload/BulkAccountClosure/ViewBulkAccountClosureSummary';
import CustomerAcNatureMarkingBulkUpload from './pages/BulkOperations/BulkFilerUpload/CustomerAcNatureBulkMarkingUpload/CustomerAcNatureMarkingBulkUpload';
import CustomerAcNatureSummary from './pages/BulkOperations/BulkFilerUpload/CustomerAcNatureBulkMarkingUpload/ViewCustomerAcNature';
import CustomerAcUploadFile from './pages/BulkOperations/BulkFilerUpload/CustomerAcNatureBulkMarkingUpload/CustomerAcUploadFile';
import EditCustomerAcNatureChecker from './pages/BulkOperations/Checker/CustomerAcNatureBulkMarkingUploadChecker/EditCustomerAcNatureChecker';
import EditBulkAccountClosureChecker from './pages/BulkOperations/Checker/BulkAccountClosureChecker/EditBulkAccountClosureChecker';
// import EditDissbursementUploadFileSummaryChecker from './pages/BulkOperations/Checker/DissbursementUploadFileSummaryChecker/EditDissbursementUploadFileSummaryChecker';
import EditScheduledBulkPaymentsChecker from './pages/BulkOperations/Checker/ScheduledBulkPaymentsChecker/EditScheduledBulkPaymentsChecker';

import BulkAcOpening from './pages/BulkOperations/BulkFilerUpload/BulkAcOpening/BulkAcOpening';
import TransactionReversal from './pages/BulkOperations/BulkFilerUpload/BulkTransactionReversal/TransactionReversal';
import SegmentUpdate from './pages/BulkOperations/BulkFilerUpload/BulkSegmentUpdate/SegmentUpdate';
import UploadAcOpening from './pages/BulkOperations/BulkFilerUpload/BulkAcOpening/Components/UploadAcOpening';

import UploadTransactionReversal from './pages/BulkOperations/BulkFilerUpload/BulkTransactionReversal/Components/UploadTransactionReversal';
import UploadSegment from './pages/BulkOperations/BulkFilerUpload/BulkSegmentUpdate/Components/UploadSegmentUpdates';

import EmailUpdate from './pages/BulkOperations/BulkFilerUpload/BulkEmailUpdate/EmailUpdate';

import UploadEmailUpdate from './pages/BulkOperations/BulkFilerUpload/BulkEmailUpdate/Components/UploadEmailUpdate';

import UploadCnicUpdate from './pages/BulkOperations/BulkFilerUpload/BulkCnicUpdate/Components/UploadCnicUpdate';
import CnicUpdate from './pages/BulkOperations/BulkFilerUpload/BulkCnicUpdate/CnicUpdate';

import BulkAccountClosure from './pages/BulkOperations/BulkFilerUpload/BulkAccountClosure/BulkAccountClosure';
import BulkAccountClosureUpload from './pages/BulkOperations/BulkFilerUpload/BulkAccountClosure/BulkaccountClosureUpload';
// import DissbursementUploadFileSummaryChecker from './pages/BulkOperations/Checker/DissbursementUploadFileSummaryChecker/EditDissbursementUploadFileSummaryChecker';
import EditBlacklistMarkingUploadChecker from './pages/BulkOperations/Checker/BlacklistMarkingBulkUploadChecker/EditBlacklistMarkingBulkUploadChecker';

import ViewBulkAccountClosureSummary from './pages/BulkOperations/BulkFilerUpload/BulkAccountClosure/ViewBulkAccountClosureSummary';
import { useIdleTimer } from 'react-idle-timer';

import AgentAccountMaintenanceEdit from './pages/CX-1/AgentAccountsManagement/AgentAccountMaintenanceEdit';
import AgentAccountMaintenanceView from './pages/CX-1/AgentAccountsManagement/AgentAccountMaintenanceView';
import Aggregators from './pages/LendingManagement/Aggregators/Aggregators';
import AddAggregator from './pages/LendingManagement/Aggregators/AddAggregators';
import EditAggregator from './pages/LendingManagement/Aggregators/EditAggregators';
import ViewAggregator from './pages/LendingManagement/Aggregators/ViewAggregators';
import LendingManagement from './pages/LendingManagement/LendingManagement';
import CustomerRiskProfile from './pages/CustomerRiskProfile/CustomerRiskProfile';
import AddCustomerRiskProfile from './pages/CustomerRiskProfile/AddCustomerRiskProfile';

import AgentAccountManagementCheckerEdit from './pages/CX-1/AgentAccountsManagement/Checker/Pages/AgentAccountsManagementCheckerEdit';
import AgentAccountManagementCheckerView from './pages/CX-1/AgentAccountsManagement/Checker/Pages/AgentAccountsManagementCheckerView';
import LoanAccountManagement from './pages/LendingManagement/LoanAccount/LoanAccountManagement';
import ViewLoanAccount from './pages/LendingManagement/LoanAccount/ViewLoanAccount';
import ViewLoanBook from './pages/LendingManagement/LoanAccount/ViewLoanBook';
import ViewActiveLoan from './pages/LendingManagement/LoanAccount/ViewActiveLoan';
import CreditScoringEngineManagement from './pages/LendingManagement/CreditScoringEngine/CreditScoringEngineManagement';
import AddCreditScoringEngine from './pages/LendingManagement/CreditScoringEngine/AddCreditScoringEngine';
import EditCreditScoringEngine from './pages/LendingManagement/CreditScoringEngine/EditCreditScoringEngine';
import ViewCreditScoringEngine from './pages/LendingManagement/CreditScoringEngine/ViewCreditScoringEngine';
import EditCustomerRiskProfile from './pages/CustomerRiskProfile/EditCustomerRiskProfile';
import EditAggregatorChecker from './pages/LendingManagement/Checker/Aggregator/EditAggregatorChecker';
import ViewAggregatorChecker from './pages/LendingManagement/Checker/Aggregator/ViewAggregatorChecker';
import Reporting from './pages/LendingManagement/Reporting/Reporting';
import ViewReporting from './pages/LendingManagement/Reporting/ViewReporting';
import CustomerLoanProfile from './pages/LendingManagement/CustomerLoanProfile/CustomerLoanProfile';
import EditCustomerLoanProfile from './pages/LendingManagement/CustomerLoanProfile/EditCustomerLoanProfile';
import ViewCustomerLoanProfile from './pages/LendingManagement/CustomerLoanProfile/ViewCustomerLoanProfile';
import EditOrganization from './pages/ChartOfAccounts/Components/Organization/EditOrganization';
import ViewLoanAccountChecker from './pages/LendingManagement/Checker/LoanAccount/ViewLoanAccountChecker';
import ViewActiveLoanchecker from './pages/LendingManagement/Checker/LoanAccount/ViewActiveLoanChecker';
// import EditCreditScoringEngineChecker from './pages/CampaignManagement/Checker/CreateCampaginChecker/EditCreateCampaignChecker';
import EditCreditScoringEngineChecker from './pages/LendingManagement/Checker/CreditScoringEngine/EditCreditScoringEngineChecker';
import ViewCreditScoringEngineChecker from './pages/LendingManagement/Checker/CreditScoringEngine/ViewCreditScoringEngineChecker';
import EditCustomerLoanProfileChecker from './pages/LendingManagement/Checker/CustomerLoanProfile/EditCustomerLoanProfileChecker';
import ViewCustomerLoanProfileChecker from './pages/LendingManagement/Checker/CustomerLoanProfile/ViewCustomerLoanProfileChecker';
import AddLendingManagement from './pages/LendingManagement/Product/AddLendingManagement';
import EditLendingManagement from './pages/LendingManagement/Product/EditLendingManagement';
import ViewLendingManagement from './pages/LendingManagement/Product/ViewLendingManagement';
import EditLendingManagementChecker from './pages/LendingManagement/Checker/Product/EditLendingManagementChecker';
import ViewLendingManagementChecker from './pages/LendingManagement/Checker/Product/ViewLendingManagementChecker';
import ViewFilerNonFilerPreview from './pages/BulkOperations/BulkFilerUpload/FilerNonFilerUpdate/ViewFilerNonFilerPreview';
import ViewAcOpeningPreview from './pages/BulkOperations/BulkFilerUpload/BulkAcOpening/Components/ViewAcOpeningPreview';
import ViewTransactionReversal from './pages/BulkOperations/BulkFilerUpload/BulkTransactionReversal/Components/ViewTransactionReversal';
import ViewValidateSegment from './pages/BulkOperations/BulkFilerUpload/BulkSegmentUpdate/Components/ViewValidateSegment';
import ViewEmailUpdate from './pages/BulkOperations/BulkFilerUpload/BulkEmailUpdate/Components/ViewEmailUpdate';
import ViewCnicUpdate from './pages/BulkOperations/BulkFilerUpload/BulkCnicUpdate/Components/ViewCnicUpdate';
import ViewBulkAccountStatusChange from './pages/BulkOperations/BulkFilerUpload/BulkAccountStatusChange/ViewBulkAccountStatusChangeSummary';
import ViewCustomerAcNature from './pages/BulkOperations/BulkFilerUpload/CustomerAcNatureBulkMarkingUpload/ViewCustomerAcNature';
import ViewCustomerAcNatureChecker from './pages/BulkOperations/Checker/CustomerAcNatureBulkMarkingUploadChecker/ViewCustomerAcNatureChecker';
import ViewBulkAccountClosureChecker from './pages/BulkOperations/Checker/BulkAccountClosureChecker/ViewBulkAccountClosureChecker';
import ViewScheduledBulkPaymentsChecker from './pages/BulkOperations/Checker/ScheduledBulkPaymentsChecker/ViewScheduledBulkPaymentsChecker';
import ViewBlacklistMarkingUploadChecker from './pages/BulkOperations/Checker/BlacklistMarkingBulkUploadChecker/ViewBlacklistMarkingBulkUpload';
import EditBulkFilerNonFilerUpdateChecker from './pages/BulkOperations/Checker/BulkFilerNonFilerUpdateChecker/EditBulkFilerNonFilerUpdateChecker';
import ViewBulkFilerNonFilerUpdateChecker from './pages/BulkOperations/Checker/BulkFilerNonFilerUpdateChecker/ViewBullkFilerUpdateChecker';
import EditBulkAcOpeningChecker from './pages/BulkOperations/Checker/BulkAcOpeningChecker/EditBulkAcOpeningChecker';
import ViewBulkAcOpeningChecker from './pages/BulkOperations/Checker/BulkAcOpeningChecker/ViewBulkAcOpeningChecker';
import EditBulkTransactionReversalChecker from './pages/BulkOperations/Checker/BulkTransactionReversalChecker/EditBulkTransactionReversalChecker';
import ViewBulkTransactionReversalChecker from './pages/BulkOperations/Checker/BulkTransactionReversalChecker/ViewBulkTransactionReversalChecker';
import EditBulkSegmentUpdateChecker from './pages/BulkOperations/Checker/BulkSegmentUpdateChecker/EditBulkSegmentUpdateChecker';
import ViewBulkSegmentUpdateChecker from './pages/BulkOperations/Checker/BulkSegmentUpdateChecker/ViewBulkSegmentUpdateChecker';
import EditBulkEmailUpdateChecker from './pages/BulkOperations/Checker/BulkEmailUpdateChecker/EditBulkEmailUpdateChecker';
import ViewBulkEmailUpdateChecker from './pages/BulkOperations/Checker/BulkEmailUpdateChecker/ViewBulkEmailUpdateChecker';
import EditBulkCnicUpdateChecker from './pages/BulkOperations/Checker/BulkCnicUpdateChecker/EditBulkCnicUpdateChecker';
import ViewBulkCnicUpdateChecker from './pages/BulkOperations/Checker/BulkCnicUpdateChecker/ViewBulkCnicUpdateChecker';
import EditBulkAccountStatusChangeChecker from './pages/BulkOperations/Checker/BulkAccountStatusChangeChecker/EditBulkAccountStatusChangeChecker';
import ViewBulkAccountStatusChangeChecker from './pages/BulkOperations/Checker/BulkAccountStatusChangeChecker/ViewBulkAccountStatusChangeChecker';
import EditCustomerRiskProfileChecker from './pages/CustomerRiskProfile/Checker/EditCustomerRiskProfileChecker';
import RuleEngine from './pages/RuleEngineS/RuleEngineS/RuleEngine';
import AddRuleEngine from './pages/RuleEngineS/RuleEngineS/AddRuleEngine';
import EditRuleEngine from './pages/RuleEngineS/RuleEngineS/EditRuleEngine';
import EditCheckerRuleEngine from './pages/RuleEngineS/Checker/EditRuleChecker';
import ViewCheckerRuleEngine from './pages/RuleEngineS/Checker/ViewRuleChecker';
import ViewRuleEngine from './pages/RuleEngineS/RuleEngineS/RuleEngineView';
import DomainConfigurations from './pages/RuleEngineS/DomainConfiguration/domainConfiguration';
import AddRuleDomains from './pages/RuleEngineS/DomainConfiguration/AddDomain';
import EditRuleDomainsChecker from './pages/RuleEngineS/Checker/EditDomainChecker';
import ViewRuleDomainsChecker from './pages/RuleEngineS/Checker/ViewDomainChecker';
import EditRuleDomains from './pages/RuleEngineS/DomainConfiguration/EditDomain';
import ViewRuleDomains from './pages/RuleEngineS/DomainConfiguration/ViewDomain';

import ViewCustomerRiskProfileChecker from './pages/CustomerRiskProfile/Checker/ViewCustomerRiskProfileChecker';
import CardPricingProfile from './pages/CardManagement/CardPricingProfile/CardPricingProfile';
import AddCardPricingProfile from './pages/CardManagement/CardPricingProfile/AddCardPricingProfile';
import ViewCustomerRiskProfile from './pages/CustomerRiskProfile/ViewCustomerRiskProfile';
import EditCardPricingProfile from './pages/CardManagement/CardPricingProfile/EditCardPricingProfile';
import ViewCardPricingProfile from './pages/CardManagement/CardPricingProfile/ViewCardPricingProfile';
import CardInstallmentPlan from './pages/CardManagement/CardInstallmentPlan/CardInstallmentPlan';
import AddCardInstallmentPlan from './pages/CardManagement/CardInstallmentPlan/AddCardInstallmentPlan';
import EditCardInstallmentPlan from './pages/CardManagement/CardInstallmentPlan/EditCardInstallmentPlan';
import ViewCardInstallmentPlan from './pages/CardManagement/CardInstallmentPlan/ViewCardInstallmentPlan';
import EditCardPricingProfileChecker from './pages/CardManagement/Checker/CardPricingProfileChecker/EditCardPricingProfileChecker';
import EditCardInstallmentPlanChecker from './pages/CardManagement/Checker/CardInstallmentPlanChecker/EditCardInstallmentPlanChecker';
import ViewCardPricingProfileChecker from './pages/CardManagement/Checker/CardPricingProfileChecker/ViewCardPricingProfileChecker';
import ViewCardInstallmentPlanChecker from './pages/CardManagement/Checker/CardInstallmentPlanChecker/ViewCardInstallmentPlanChecker';
import Reports from './pages/Reporting/Reports';
import CRPModel from './pages/CustomerRiskProfile/CRPModel/CRPModel';
import EditBulkDisbursement from './pages/BulkOperations/BulkDisbursement/Checker/EditBulkDisbursement';
import ViewBulkDisbursement from './pages/BulkOperations/BulkDisbursement/Checker/ViewBulkDisbursement';
import AddCRPModel from './pages/CustomerRiskProfile/CRPModel/AddCRPModel';
import EditCRPModel from './pages/CustomerRiskProfile/CRPModel/EditCRPModel';
import ViewCRPModel from './pages/CustomerRiskProfile/CRPModel/ViewCRPModel';
import EditCRPModelChecker from './pages/CustomerRiskProfile/CRPModel/Checker/EditCRPModelChecker';
import ViewCRPModelChecker from './pages/CustomerRiskProfile/CRPModel/Checker/ViewCRPModelChecker';
import RiskDashboard from './pages/RiskDashboard/RiskDashboard';
import RegistrationSection from './pages/RiskDashboard/Pages/RegistrationSection';
import RetailerCustomerTransactionSection from './pages/RiskDashboard/Pages/RetailerCustomerTransactionSection';
import RiskAndBusinessSection from './pages/RiskDashboard/Pages/RiskAndBusinessSection';
import DeviceSection from './pages/RiskDashboard/Pages/DeviceSection';
import ViewOrganization from './pages/ChartOfAccounts/Components/Organization/ViewOrganization';
import ViewCategory from './pages/ChartOfAccounts/Components/Category/ViewCategory';
import ViewCostCenter from './pages/ChartOfAccounts/Components/CostCenter/ViewCostCenter';
import ViewLocation from './pages/ChartOfAccounts/Components/Location/ViewLocation';
import ViewCurrency from './pages/ChartOfAccounts/Components/Currency/ViewCurrency';
import ViewSubCategoryCoa from './pages/ChartOfAccounts/Components/SubCategory/ViewSubCategory';
import ViewMprCode from './pages/ChartOfAccounts/Components/Mrp/ViewMrpCode';
import EditCheckerCostCenter from './pages/ChartOfAccounts/Checkers/CostCenter/EditCheckerCostCenter';
import ViewCheckerCostCenter from './pages/ChartOfAccounts/Checkers/CostCenter/ViewCheckerCostCenter';
import EditCheckerCategory from './pages/ChartOfAccounts/Checkers/Category/EditCheckerCategory';
import ViewCheckerCategory from './pages/ChartOfAccounts/Checkers/Category/ViewCheckerCategory';
import EditCheckerCurrency from './pages/ChartOfAccounts/Checkers/Currency/EditCheckerCurrency';
import ViewCheckerCurrency from './pages/ChartOfAccounts/Checkers/Currency/ViewCheckerCurrency';
import AddaggregatorConfiguration from './pages/LendingManagement/AggregatorConfiguration/AddaggregatorConfiguration';
import EditaggregatorConfiguration from './pages/LendingManagement/AggregatorConfiguration/EditaggregatorConfiguration';
import ViewAggregatorConfiguration from './pages/LendingManagement/AggregatorConfiguration/ViewAggregatorConfiguration';
import Aggregatorconfigurations from './pages/LendingManagement/AggregatorConfiguration/Aggregatorconfigurations';
import EditCheckerMprCode from './pages/ChartOfAccounts/Checkers/Mrp/EditCheckerMrp';
import ViewCheckerMprCode from './pages/ChartOfAccounts/Checkers/Mrp/ViewCheckerMrp';
import EditCheckerOrganization from './pages/ChartOfAccounts/Checkers/Organization/EditCheckerOrganization';
import ViewCheckerOrganization from './pages/ChartOfAccounts/Checkers/Organization/ViewCheckerOrganization';
import EditaggregatorConfigChecker from './pages/LendingManagement/Checker/AggregatorConfigurations/EditaggregatorConfigChecker';
import ViewAggregatorConfigurationChecker from './pages/LendingManagement/Checker/AggregatorConfigurations/ViewAggregatorConfigChecker';
import DmoMerchants from './pages/DmoMerchant/DmoMerchants';
import ViewDmoMerchant from './pages/DmoMerchant/ViewDmoMerchant';
import EditDmoMerchant from './pages/DmoMerchant/EditDmoMerchant';
import EditCheckerLocation from './pages/ChartOfAccounts/Checkers/Location/EditCheckerLocation';
import ViewCheckerLocation from './pages/ChartOfAccounts/Checkers/Location/ViewCheckerLocation';
import EditCheckerSubCategoryCoa from './pages/ChartOfAccounts/Checkers/SubCategory/EditCheckerSubCategory';
import ViewCheckerSubCategoryCoa from './pages/ChartOfAccounts/Checkers/SubCategory/ViewCheckerSubCategory';
import EditCheckerChartofAccounts from './pages/ChartOfAccounts/Checkers/ChartOfAccounts/EditCheckerChartofAccounts';
import ViewCheckerChartOfAccounts from './pages/ChartOfAccounts/Checkers/ChartOfAccounts/ViewCheckerChartOfAccounts';
import DmoMerchantEditChecker from './pages/DmoMerchant/Checker/DmoMerchantEditChecker';
import DmoMerchantViewChecker from './pages/DmoMerchant/Checker/DmoMerchantViewChecker';
import RiskDashboardCheckerView from './pages/RiskDashboard/Checker/RiskDashboardCheckerView';
import RiskDashboardCheckerEdit from './pages/RiskDashboard/Checker/RiskDashboardCheckerEdit';
import DebitCardManagement from './pages/DebitCard/DebitCardManagement';
import EditDebitCard from './pages/DebitCard/EditDebitCard';
import ViewDebitCard from './pages/DebitCard/ViewDebitCard';
import EditDebitCardChecker from './pages/DebitCard/Checker/EditDebitCardChecker';
import ViewDebitCardChecker from './pages/DebitCard/Checker/viewDebitCardChecker';

// cms files

import SignUpLimits from './pages/CMS/RuleSets/SignUpLimits/SignUpLimits';
import AddSignUpLimit from './pages/CMS/RuleSets/SignUpLimits/AddSignUpLimit';
import EditSignUpLimit from './pages/CMS/RuleSets/SignUpLimits/EditSignUpLimit';
import ViewSignUpLimit from './pages/CMS/RuleSets/SignUpLimits/ViewSignUpLimit';
// import EditCheckerSignUpLimit from './pages/CMS/Checker/EditCheckerSignUpLimit';
import ViewCheckerSignUpLimit from './pages/CMS/Checker/ViewCheckerSignUpLimit';
import EditCheckerSignUpLimit from './pages/CMS/Checker/EditCheckerSignUpLimit';
import OtpScreen from './pages/CMS/Configuration/Otp/Otp';
// import AddOtpLimit from './pages/CMS/Configuration/Otp/AddOtp';
import AddOtp from './pages/CMS/Configuration/Otp/AddOtp';
import EditOtp from './pages/CMS/Configuration/Otp/EditOtp';
import ViewOtp from './pages/CMS/Configuration/Otp/ViewOtp';
import ViewOtpChecker from './pages/CMS/Configuration/CheckerOtp/ViewOtpChecker';
import EditOtpChecker from './pages/CMS/Configuration/CheckerOtp/EditOtpChecker';
import NadraScreen from './pages/CMS/Configuration/Nadra/Nadra';
import EditNadra from './pages/CMS/Configuration/Nadra/EditNadra';
import PmdScreen from './pages/CMS/Configuration/PMD_/Pages/MainPage';
import AddPmd from './pages/CMS/Configuration/PMD_/Pages/AddPage';
import EditPmd from './pages/CMS/Configuration/PMD_/Pages/EditPage';
import ViewPmd from './pages/CMS/Configuration/PMD_/Pages/ViewPage';
import EditPmdChecker from './pages/CMS/Configuration/PMD_/Pages/CheckerEditPage';
import ViewPmdChecker from './pages/CMS/Configuration/PMD_/Pages/CheckerViewPage';
import Product from './pages/CMS/Product/Product';
import AddNewProduct from './pages/CMS/Product/AddNewProduct';
import EditProduct from './pages/CMS/Product/EditProduct';
import ViewProduct from './pages/CMS/Product/ViewProduct';
import MainCategory from './pages/CMS/AllCategory/MainCategory/MainCategory';
import AddMainCategory from './pages/CMS/AllCategory/MainCategory/AddMainCategory';
import EditMainCategory from './pages/CMS/AllCategory/MainCategory/EditMainCategory';
import ViewMainCategory from './pages/CMS/AllCategory/MainCategory/ViewMainCategory';
import SubCategory from './pages/CMS/AllCategory/SubCategory/SubCategory';
import AddSubCategory from './pages/CMS/AllCategory/SubCategory/AddSubCategory';
import EditSubCategory from './pages/CMS/AllCategory/SubCategory/EditSubCategory';
import ViewSubCategory from './pages/CMS/AllCategory/SubCategory/ViewSubCategory';
import MenuIcon from './pages/CMS/AllCategory/Menu&Icons/MenuIcon';
import AddMenuIcon from './pages/CMS/AllCategory/Menu&Icons/AddMenuIcon';
import EditMenuIcon from './pages/CMS/AllCategory/Menu&Icons/EditMenuIcon';
import ViewMenuIcon from './pages/CMS/AllCategory/Menu&Icons/ViewMenuIcon';
import LoginSet from './pages/CMS/RuleSets/LoginSet/LoginSet';
import AddLoginSet from './pages/CMS/RuleSets/LoginSet/AddLoginSet';
import EditLoginSet from './pages/CMS/RuleSets/LoginSet/EditLoginSet';
import ViewLoginSet from './pages/CMS/RuleSets/LoginSet/ViewLoginSet';
import EditProductChecker from './pages/CMS/Product/Checker/EditProductChecker';
import ViewProductChecker from './pages/CMS/Product/Checker/ViewProductChecker';
import EditMainCategoryChecker from './pages/CMS/AllCategory/MainCategory/Checker/EditMainCategoryChecker';
import ViewMainCategoryChecker from './pages/CMS/AllCategory/MainCategory/Checker/ViewMainCategoryChecker';
import EditSubCategoryChecker from './pages/CMS/AllCategory/SubCategory/Checker/EditSubCategoryChecker';
import ViewSubCategoryChecker from './pages/CMS/AllCategory/SubCategory/Checker/ViewSubCategoryChecker';
import EditMenuIconChecker from './pages/CMS/AllCategory/Menu&Icons/Checker/EditMenuIconChecker';
import ViewMenuIconChecker from './pages/CMS/AllCategory/Menu&Icons/Checker/ViewMenuIconChecker';
import EditLoginSetChecker from './pages/CMS/RuleSets/LoginSet/Checker/EditLoginSetChecker';
import ViewLoginSetChecker from './pages/CMS/RuleSets/LoginSet/Checker/ViewLoginSetChecker';
import AddBanner from './pages/CMS/Banners/AddBanner';
import EditBanner from './pages/CMS/Banners/EditBanner';
import ViewBanner from './pages/CMS/Banners/ViewBanner';
import Banners from './pages/CMS/Banners/Banners';
import Notifications from './pages/CMS/PushNotifications/Notifications';
import AddNotifications from './pages/CMS/PushNotifications/AddNotifications';
import EditNotifications from './pages/CMS/PushNotifications/EditNotifications';
import ViewNotifications from './pages/CMS/PushNotifications/ViewNotifications';
import AddTicker from './pages/CMS/Ticker/AddTicker';
import EditTicker from './pages/CMS/Ticker/EditTicker';
import ViewTicker from './pages/CMS/Ticker/ViewTicker';
import Sms from './pages/CMS/Sms/Sms';
import EditSms from './pages/CMS/Sms/EditSms';
import AddSms from './pages/CMS/Sms/AddSms';
import ViewSms from './pages/CMS/Sms/ViewSms';
import AddInformationSplash from './pages/CMS/InformationSplash/AddInformationSplash';
import ViewInformationSplash from './pages/CMS/InformationSplash/ViewInformationSplash';
import EditInformationSplash from './pages/CMS/InformationSplash/EditInformationSplash';
import AddPromotion from './pages/CMS/Promotions/AddPromotion';
import AddRedirectSplash from './pages/CMS/RedirectSplash/AddRedirectSplash';
import AddInputSplash from './pages/CMS/InputSplash/AddInputSplash';
import ViewRedirectSplash from './pages/CMS/RedirectSplash/ViewRedirectSplash';
import EditRedirectSplash from './pages/CMS/RedirectSplash/EditRedirectSplash';
// import UpdateInputSplash from './pages/CMS/InputSplash/EditInputSplash';
import ViewInputSplash from './pages/CMS/InputSplash/ViewInputSplash';
import EditInputSplash from './pages/CMS/InputSplash/EditInputSplash';
import Error from './pages/CMS/Error/Error';
import EditError from './pages/CMS/Error/EditError';
import ViewError from './pages/CMS/Error/ViewError';
import EditPromotion from './pages/CMS/Promotions/EditPromotion';
import ViewPromotion from './pages/CMS/Promotions/ViewPromotion';
import ViewBannerChecker from './pages/CMS/Banners/Checker/ViewBannerChecker';
import EditBannerChecker from './pages/CMS/Banners/Checker/EditBannerChecker';
import ViewRedirectSplashChecker from './pages/CMS/RedirectSplash/ViewRedirectSplashChecker';
import EditRedirectSplashChecker from './pages/CMS/RedirectSplash/EditRedirectSplashChecker';
import EditInputSplashChecker from './pages/CMS/InputSplash/EditInuputSplashChecker';
import ViewInputSplashChecker from './pages/CMS/InputSplash/ViewInputSplashChecker';
import EditInformationSplashChecker from './pages/CMS/InformationSplash/EditInformationSplashChecker';
import ViewInformationSplashChecker from './pages/CMS/InformationSplash/ViewInformationSplashChecker';
import EditTickerChecker from './pages/CMS/Ticker/EditTickerChecker';
import ViewTickerChecker from './pages/CMS/Ticker/ViewTickerChecker';
import EditPromotionChecker from './pages/CMS/Promotions/EditPromotionChecker';
import ViewPromotionChecker from './pages/CMS/Promotions/ViewPromotionChecker';
import Biller from './pages/BillerManagement/OfflineBiller/Biller';
import EditBiller from './pages/BillerManagement/OfflineBiller/EditBiller';
import ViewBiller from './pages/BillerManagement/OfflineBiller/ViewBiller';
import AddBiller from './pages/BillerManagement/OfflineBiller/AddBiller';
import ParserHead from './pages/BillerManagement/Parser/ParserHead';
import UploadParserHead from './pages/BillerManagement/Parser/UploadParserHead';
import SecondSubCategory from './pages/CMS/AllCategory/SecondSubCategory/SecondSubCategory';
import AddSecondSubCategory from './pages/CMS/AllCategory/SecondSubCategory/AddSecondSubCategory';
import EditSecondSubCategory from './pages/CMS/AllCategory/SecondSubCategory/EditSecondSubCategory';
import ViewSecondSubCategory from './pages/CMS/AllCategory/SecondSubCategory/ViewSecondSubCategory';
import ViewSecondSubCategoryChecker from './pages/CMS/AllCategory/SecondSubCategory/Checker/ViewSecondSubCategoryChecker';
import EditSecondSubCategoryChecker from './pages/CMS/AllCategory/SecondSubCategory/Checker/EditSecondSubCategoryChecker';
import DisableSms from './pages/CMS/Disable/Pages/MainPage';
import AddDisableSms from './pages/CMS/Disable/Pages/AddPage';
import EditDisableSms from './pages/CMS/Disable/Pages/EditPage';
import ViewDisableSms from './pages/CMS/Disable/Pages/ViewPage';
import EditDisableSmsChecker from './pages/CMS/Disable/Pages/CheckerEditPage';
import ViewDisableSmsChecker from './pages/CMS/Disable/Pages/CheckerViewPage';
import Bank from './pages/CMS/Bank/Bank';
import AddBank from './pages/CMS/Bank/AddBank';
import EditBank from './pages/CMS/Bank/EditBank';
import ViewBank from './pages/CMS/Bank/ViewBank';
import Company from './pages/CMS/Company/Company';
import AddCompany from './pages/CMS/Company/AddCompany';
import EditCompany from './pages/CMS/Company/EditCompany';
import ViewCompany from './pages/CMS/Company/ViewCompany';
import ManageMobileNumbers from './pages/CMS/ManageMobileNumbers/ManageMobileNumbers';
import AddManageMobileNumbers from './pages/CMS/ManageMobileNumbers/AddManageMobileNumbers';
import EditManageMobileNumbers from './pages/CMS/ManageMobileNumbers/EditManageMobileNumbers';
import ViewManageMobileNumbers from './pages/CMS/ManageMobileNumbers/ViewManageMobileNumbers';
import EditBankChecker from './pages/CMS/Bank/Checker/EditBankChecker';
import ViewBankChecker from './pages/CMS/Bank/Checker/ViewBankChecker';
import EditCompanyChecker from './pages/CMS/Company/Checker/EditCompanyChecker';
import ViewCompanyChecker from './pages/CMS/Company/Checker/ViewCompanyChecker';
import EditManageMobileNumbersChecker from './pages/CMS/ManageMobileNumbers/Checkers/EditManageMobileNumbersChecker';
import ViewManageMobileNumbersChecker from './pages/CMS/ManageMobileNumbers/Checkers/ViewManageMobileNumbers';
import AddMobileBundleCategories from './pages/CMS/MobileBundleCategories/AddMobileBundleCategories';
import MobileBundleCategories from './pages/CMS/MobileBundleCategories/MobileBundleCategories';
import EditMobileBundleCategories from './pages/CMS/MobileBundleCategories/EditMobileBundleCategories';
import ViewMobileBundleCategories from './pages/CMS/MobileBundleCategories/ViewMobileBundleCategories';
import ViewMobileBundleCategoriesChecker from './pages/CMS/MobileBundleCategories/Checker/ViewMobileBundleCategoriesChecker';
import EditMobileBundleCategoriesChecker from './pages/CMS/MobileBundleCategories/Checker/EditMobileBundleCategoriesChecker';
import UploadMobileBundle from './pages/CMS/UploadMobileBundle/MobileBundle';
import ManageUploadFile from './pages/CMS/ManageUploadFile/ManageUploadFile';
import AddManageUpload from './pages/CMS/ManageUploadFile/AddManageUpload';
import EditManageUpload from './pages/CMS/ManageUploadFile/EditManageUpload';
import ViewManageUpload from './pages/CMS/ManageUploadFile/ViewManageUpload';
// import Voucher from './pages/CMS/Voucher/Voucher';
// import AddVoucher from './pages/CMS/Voucher/AddVoucher';
// import EditVoucher from './pages/CMS/Voucher/EditVoucher';
// import ViewVoucher from './pages/CMS/Voucher/ViewVoucher';
// import EditVoucherChecker from './pages/CMS/Voucher/Checker/EditVoucherChecker';
// import ViewVoucherChecker from './pages/CMS/Voucher/Checker/ViewVoucherChecker';
import Parser from './pages/CMS/Parser/Parser';
import AddParser from './pages/CMS/Parser/AddParser';
import EditParser from './pages/CMS/Parser/EditParser';
import ViewParser from './pages/CMS/Parser/ViewParser';
import EditParserChecker from './pages/CMS/Parser/Checker/EditParserChecker';
import ViewParserChecker from './pages/CMS/Parser/Checker/ViewParserChecker';
// import { Theme } from '@fullcalendar/core';
import AddTheme from './pages/CMS/Theme/AddTheme';
import EditTheme from './pages/CMS/Theme/EditTheme';
import ViewTheme from './pages/CMS/Theme/ViewTheme';
import EditThemeChecker from './pages/CMS/Theme/Checker/EditThemeChecker';
import ViewThemeChecker from './pages/CMS/Theme/Checker/ViewThemeChecker';
import Themee from './pages/CMS/Theme/Themee';
import ViewManageUploadChecker from './pages/CMS/ManageUploadFile/Checker/ViewManageUploadChecker';
import EditManageUploadChecker from './pages/CMS/ManageUploadFile/Checker/EditManageUploadChecker';
import ViewChartOfAccounts from './pages/ChartOfAccounts/Components/ChartOfAccounts/ViewChartOfAccounts';
import SwitchConfiguration from './pages/TransactionalSwitch/TransactionalSwitchManagement/SwitchConfiguration';
import AddSwitchConfiguration from './pages/TransactionalSwitch/TransactionalSwitchManagement/AddSwitchConfiguration';
import EditViewSwitchConfiguration from './pages/TransactionalSwitch/TransactionalSwitchManagement/EditSwitchConfiguration';
import SwitchConfigurationCheckerEdit from './pages/TransactionalSwitch/Checker/SwitchConfigurationCheckerEdit';
import SwitchConfigurationCheckerView from './pages/TransactionalSwitch/Checker/SwitchConfigurationCheckerView';
import ViewSwitchConfiguration from './pages/TransactionalSwitch/TransactionalSwitchManagement/ViewSwitchConfiguration';
import EditSmsChecker from './pages/CMS/Sms/Checker/EditSmsChecker';
import ViewSmsChecker from './pages/CMS/Sms/Checker/ViewSmsChecker';
import EditNotificationsChecker from './pages/CMS/PushNotifications/Checker/EditNotificationsChecker';
import ViewNotificationsChecker from './pages/CMS/PushNotifications/Checker/ViewNotificationsChecker';
import EditErrorChecker from './pages/CMS/Error/Checker/EditErrorChecker';
import ViewErrorChecker from './pages/CMS/Error/Checker/ViewErrorChecker';
import AddNadra from './pages/CMS/Configuration/Nadra/AddNadra';
import ViewNadra from './pages/CMS/Configuration/Nadra/ViewNadra';
import EditNadraChecker from './pages/CMS/Configuration/Nadra/Checker/EditNadraChecker';
import ViewNadraChecker from './pages/CMS/Configuration/Nadra/Checker/ViewNadraChecker';
import SecretQuestions from './pages/CMS/Configuration/Secret/Pages/MainPage';
import AddSecretQestion from './pages/CMS/Configuration/Secret/Pages/AddPage';
import EditSecretQuestion from './pages/CMS/Configuration/Secret/Pages/EditPage';
import ViewSecretQestion from './pages/CMS/Configuration/Secret/Pages/ViewPage';
import EditSecretQuestionsChecker from './pages/CMS/Configuration/Secret/Pages/CheckerEditPage';
import ViewSecretQuestionsChecker from './pages/CMS/Configuration/Secret/Pages/CheckerViewPage';
import EditBannerCheckerMain from './pages/CMS/Banners/Checker/EditBannerCheckerMain';
import ViewBannerCheckerMain from './pages/CMS/Banners/Checker/ViewBannerCheckerMain';
import ViewTransactions from './pages/CX-1/AccountsManagement/Transactions/ViewTransactions';
// import ViewAccountStatement from './pages/CX-1/AccountsManagement/Transactions/ViewAccountStatement';
import AddDynamicRule from './pages/DynamicRule/Pages/AddPage';
import DynamicRule from './pages/DynamicRule/Pages/MainSearchScreen';
import EditDynamicRule from './pages/DynamicRule/Pages/EditPage';
import ViewDynamicRule from './pages/DynamicRule/Pages/ViewPage';
import DynamicRuleCheckerEdit from './pages/DynamicRule/Checker/DynamicRuleCheckerEdit';
import DynamicRuleCheckerView from './pages/DynamicRule/Checker/DynamicRuleCheckerView';
import AddMobileBundleUpload from './pages/CMS/UploadMobileBundle/AddMobileBundleUpload';
import EditMobileBundle from './pages/CMS/UploadMobileBundle/EditMobileBundle';
import EditMobileBundleChecker from './pages/CMS/UploadMobileBundle/Checker/EditMobileBundleChecker';
import ViewMobileBundleChecker from './pages/CMS/UploadMobileBundle/Checker/ViewMobileBundleChecker';
// import NotificationComponent from './components/Notification';
import BaseFactor from './pages/Zmiles/BaseFactor/BaseFactor';
import PointFactor from './pages/Zmiles/PointFactor/PointFactor';
import Tier from './pages/Zmiles/Tier/Tier';
import TierRange from './pages/Zmiles/tierRange/TierRange';
import AgentMateMainCategory from './pages/AgentMateCMS/MainCategory/AgentMateMainCategory';
import AddAgentMateMainCategory from './pages/AgentMateCMS/MainCategory/AddAgentMateMainCategory';
import EditAgentMateMainCategory from './pages/AgentMateCMS/MainCategory/EditAgentMateMainCategory';
import ViewAgentMateMainCategory from './pages/AgentMateCMS/MainCategory/ViewAgentMateMainCategory';
import EditAgentMateMainCategoryChecker from './pages/AgentMateCMS/MainCategory/Checker/EditAgentMateMainCategoryChecker';
import ViewAgentMateMainCategoryChecker from './pages/AgentMateCMS/MainCategory/Checker/ViewAgentMateMainCategoryChecker';
import AgentMateSubCategory from './pages/AgentMateCMS/SubCategory/AgentMateSubCategory';
import AddAgentMateSubCategory from './pages/AgentMateCMS/SubCategory/AddAgentMateSubCategory';
import EditAgentMateSubCategory from './pages/AgentMateCMS/SubCategory/EditAgentMateSubCategory';
import ViewAgentMateSubCategory from './pages/AgentMateCMS/SubCategory/ViewAgentMateSubCategory';
import EditAgentMateSubCategoryChecker from './pages/AgentMateCMS/SubCategory/Checker/EditAgentMateSubCategoryChecker';
import ViewAgentMateSubCategoryChecker from './pages/AgentMateCMS/SubCategory/Checker/ViewAgentMateSubCategoryChecker';
import AgentMateBank from './pages/AgentMateCMS/Bank/AgentMateBank';
import AddAgentMateBank from './pages/AgentMateCMS/Bank/AddAgentMateBank';
import EditAgentMateBank from './pages/AgentMateCMS/Bank/EditAgentMateBank';
import ViewAgentMateBank from './pages/AgentMateCMS/Bank/ViewAgentMateBank';
import EditAgentMateBankChecker from './pages/AgentMateCMS/Bank/Checker/EditAgentMateBankChecker';
import ViewAgentMateBankChecker from './pages/AgentMateCMS/Bank/Checker/ViewAgentMateBankChecker';
import AgentMateCompany from './pages/AgentMateCMS/Company/AgentMateCompany';
import AddAgentMateCompany from './pages/AgentMateCMS/Company/AddAgentMateCompany';
import EditAgentMateCompany from './pages/AgentMateCMS/Company/EditAgentMateCompany';
import ViewAgentMateCompany from './pages/AgentMateCMS/Company/ViewAgentMateCompany';
import EditAgentMateCompanyChecker from './pages/AgentMateCMS/Company/Checker/EditAgentMateCompanyChecker';
import ViewAgentMateCompanyChecker from './pages/AgentMateCMS/Company/Checker/ViewAgentMateCompanyChecker';
import AgentMateProduct from './pages/AgentMateCMS/Product/AgentMateProduct';
import AddAgentMateProduct from './pages/AgentMateCMS/Product/AddAgentMateProduct';
import EditAgentMateProduct from './pages/AgentMateCMS/Product/EditAgentMateProduct';
import ViewAgentMateProduct from './pages/AgentMateCMS/Product/ViewAgentMateProduct';
import EditAgentMateProductChecker from './pages/AgentMateCMS/Product/Checker/EditAgentMateProductChecker';
import ViewAgentMateProductChecker from './pages/AgentMateCMS/Product/Checker/ViewAgentMateProductChecker';
import BaseFactorChecker from './pages/Zmiles/Checker/BaseFactor/BaseFactorChecker';
import PointFactorChecker from './pages/Zmiles/Checker/PointFactor/PointFactorChecker';
import TierChecker from './pages/Zmiles/Checker/Tier/TierChecker';
import TierRangeChecker from './pages/Zmiles/Checker/TierRange/TierRangeChecker';
import ViewBaseFactorChecker from './pages/Zmiles/Checker/BaseFactor/ViewBaseFactorChecker';
import ViewPointFactorChecker from './pages/Zmiles/Checker/PointFactor/ViewPointFactorChecker';
import ViewTierRangeChecker from './pages/Zmiles/Checker/TierRange/ViewTierRangeChecker';
import ViewTierChecker from './pages/Zmiles/Checker/Tier/ViewTierChecker';
import CheckerVirtualCardEdit from './pages/CX-1/zindagiCards/Checker/CheckerVirtualCardEdit';
import CheckerVirtualCardView from './pages/CX-1/zindagiCards/Checker/CheckerVirtualCardView';
import VirtualCardEditDetails from './pages/CX-1/zindagiCards/Maker/VirtualCardEditDetails';
import EditSettleLoanChecker from './pages/LendingManagement/Checker/SettleLoan/EditSettleLoanChecker';
import ViewSettleLoanChecker from './pages/LendingManagement/Checker/SettleLoan/ViewSettleLoanChecker';
import SAF from './pages/TransactionalSwitch/SAF/SAF';
import Sonic from './pages/CX-1/AccountsManagement/sonic/Sonic';
import PointRedemption from './pages/Zmiles/PointRedemption/PointRedemption';
import PointRedemptionChecker from './pages/Zmiles/Checker/PointRedemption/PointRedemptionChecker';
import ViewPointRedemptionChecker from './pages/Zmiles/Checker/PointRedemption/ViewPointRedemptionChecker';
import LoanOutstandingReport from './pages/LendingManagement/OutstandingReport';
import DeviceFinancing from './pages/LendingManagement/DeviceFinancing';
import ViewDeviceFinancing from './pages/LendingManagement/DeviceFinancing/ViewDeviceFinancing';
import AddDeviceFinancing from './pages/LendingManagement/DeviceFinancing/AddDeviceFinancing';
import EditLendingDeviceFinancingChecker from './pages/LendingManagement/Checker/DeviceLending/EditLendingDeviceFinancingChecker';
import ViewLendingDeviceFinancingChecker from './pages/LendingManagement/Checker/DeviceLending/ViewLendingDeviceFinancingChecker';
import ClawBackUploadPage from './pages/BulkOperations/BulkDisbursement/ClawBackUploadPage';
import ManageTransactions from './pages/CX-1/AccountsManagement/Transactions/ManageTransactions';

const App = (props) => {
    const [userMenu, setUserMenu] = useState(JSON.parse(sessionStorage.getItem('menu')));
    const [path, setPath] = useState(window?.location?.href?.split('/#')?.pop());
    // const [foundMenu, setFoundMenu] = useState(null);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const copyTooltipRef = useRef();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    require('dotenv').config();

    PrimeReact.ripple = true;
    LoadingOverlay.propTypes = undefined;

    const isLoading = useSelector((state) => state?.utilitySlice?.isLoading);
    const auth = useSelector((state) => state?.authenticationSlice?.token);
    const isNewUser = sessionStorage.getItem('isNewUser');

    // Response Dialog Data
    const showModal = useSelector((state) => state?.showMessageSlice?.showModal);
    const message = useSelector((state) => state?.showMessageSlice?.message);
    const isError = useSelector((state) => state?.showMessageSlice?.isError);
    const url = useSelector((state) => state?.showMessageSlice?.url);

    // const menu = [
    //     {
    //         label: 'User Management',
    //         icon: 'pi pi-align-left',
    //         items: [
    //             { label: 'Module', icon: 'pi pi-circle', to: '/module' },
    //             // { label: 'Roles', icon: 'pi pi-circle', to: '/roles' },
    //             { label: 'Menu', icon: 'pi pi-circle', to: '/menu' },
    //             // { label: 'Role Rights', icon: 'pi pi-circle', to: '/rolerights' },
    //             // {
    //             //     label: 'Users',
    //             //     icon: 'pi pi-circle',
    //             //     to: '/getallusers'
    //             // },
    //             {
    //                 label: 'User Group',
    //                 icon: 'pi pi-circle',
    //                 to: '/usergroup'
    //             },
    //             {
    //                 label: 'User Management',
    //                 icon: 'pi pi-circle',
    //                 to: '/usermanagement'
    //             }
    //         ]
    //     },
    //     {
    //         label: 'Administration',
    //         icon: 'pi pi-align-left',
    //         items: [
    //             {
    //                 label: 'Authorization',
    //                 icon: 'pi pi-align-left',
    //                 items: [
    //                     {
    //                         label: 'Request Checker',
    //                         icon: 'pi pi-circle',
    //                         to: '/requestchecker'
    //                     }
    //                 ]
    //             }
    //         ]
    //     },

    //     {
    //         label: 'Configurations',
    //         icon: 'pi pi-align-left',
    //         items: [
    //             { label: 'Validators', icon: 'pi pi-circle', to: '/validators' },
    //             { label: 'Kyc Sets', icon: 'pi pi-circle', to: '/kycsets' },
    //             { label: 'KYC Attributes', icon: 'pi pi-circle', to: '/kycattributes' }
    //         ]
    //     },
    //     {
    //         label: 'Transaction Wise Limit',
    //         icon: 'pi pi-align-left',
    //         to: '/transactionwiselimit'
    //     },
    //     {
    //         label: 'Tax Profile',
    //         icon: 'pi pi-align-left',
    //         to: '/taxprofilemaintenance'
    //     },
    //     {
    //         label: 'Type Of Account',
    //         icon: 'pi pi-align-left',
    //         to: '/typeofaccount'
    //     },
    //     {
    //         label: 'Segment',
    //         icon: 'pi pi-align-left',
    //         to: '/segment'
    //     },
    //     {
    //         label: 'Commission',
    //         icon: 'pi pi-align-left',
    //         to: '/commission'
    //     },
    //     {
    //         label: 'Pricing',
    //         icon: 'pi pi-align-left',
    //         to: '/pricing'
    //     },
    //     {
    //         label: 'CX-1',
    //         icon: 'pi pi-align-left',
    //         items: [
    //             {
    //                 label: 'Accounts Management',
    //                 icon: 'pi pi-circle',
    //                 to: '/accountsmanagement'
    //             }
    //         ]
    //     },
    // {
    //     label: 'Use Case Management',
    //     icon: 'pi pi-align-left',
    //     items: [

    //         {
    //             label: 'Use case',
    //             icon: 'pi pi-circle',
    //             to: '/usecaseManagement'
    //         },
    //         // {
    //         //     label: 'Checker',
    //         //     icon: 'pi pi-circle',
    //         //     to: '/usecasechecker'
    //         // }
    //     ]
    // }
    // ];

    const menu = JSON.parse(sessionStorage.getItem('menu'));

    const routes = [
        { parent: 'Dashboard', label: '' },
        { label: 'Module' },
        { label: 'Menu' },
        { label: 'Roles' },
        { label: 'Role Rights' },
        { label: 'Get All Users' },
        { label: 'Manage Customers' },
        { label: 'Validators' },
        { label: 'Add Validators' },
        { label: 'KYC Sets' },
        { label: 'Add KYC Sets' },
        { label: 'KYC Attributes' },
        { label: 'Add Edit KYC Attributes' },
        { label: 'Transaction Limit Validator' },
        { label: 'Fee' },
        { label: 'Tax Exemption' },
        { label: 'Add Edit Tax Exemption' },
        { label: 'Tax Regime' },
        { label: 'Add Edit Tax Regime' },
        { label: 'Tax Rate' },
        { label: 'Add Edit Tax Rate' },
        { label: 'Regime level' },
        { label: 'Edit Regime level Checker' },
        { label: 'View Regime level Checker' },
        { label: 'Tax Type' },
        { label: 'Edit Tax Type Checker' },
        { label: 'View Tax Type Checker' },
        { label: 'Edit Tax Profile Checker' },
        { label: 'View Tax Profile Checker' },
        { label: 'Fee Configuration' },
        { label: 'Add Fee' },
        { label: 'Threshold Fee Configuration' },
        { label: 'Add Threshold Fee Configuration' },
        { label: 'Fee Share Configuration' },
        { label: 'Add Fee Share Configuration' },
        { label: 'Agent Commission Share' },
        { label: 'User Group' },
        { label: 'Add User Group' },
        { label: 'View User Group' },
        { label: 'Edit User Group' },
        { label: 'User Management' },
        { label: 'Add User Management' },
        { label: 'Edit User Management' },
        { label: 'View User Management' },
        { label: 'Tax Profile' },
        { label: 'Tax Profile Maintenance' },
        { label: 'Add Tax Profile' },
        { label: 'Edit Tax Profile' },
        { label: 'View Tax Profile' },
        { label: 'Type Of Account' },
        { label: 'Add Type Of Account' },
        { label: 'Edit Type Of Account' },
        { label: 'View Type Of Account' },
        { label: 'Edit Type Of Account Checker' },
        { label: 'View Type Of Account Checker' },
        { label: 'Request Checker' },
        { label: 'Edit Request Checker' },
        { label: 'View Request Checker' },
        { label: 'Transaction Wise Limit' },
        { label: 'Add Transaction Wise Limit' },
        { label: 'Edit Transaction Wise Limit' },
        { label: 'View Transaction Wise Limit' },
        { label: 'Edit Transaction Wise Limit Checker' },
        { label: 'View Transaction Wise Limit Checker' },
        { label: 'Transactions' },
        { label: 'Add Transactions' },
        { label: 'Edit Transactions' },
        { label: 'View Transaction Configuration' },
        { label: 'Edit Transactions Checker' },
        { label: 'View Transactions Checker' },
        { label: 'Edit Usergroup Checker' },
        { label: 'View Usergroup Checker' },
        { label: 'Edit Kyc Attributes Checker' },
        { label: 'View Kyc Attributes Checker' },
        { label: 'Edit Kyc Sets Checker' },
        { label: 'View Kyc Sets Checker' },
        { label: 'Edit User Checker' },
        { label: 'View User Checker' },
        { label: 'Edit Validator Checker' },
        { label: 'View Validator Checker' },
        { label: 'Segment' },
        { label: 'Add Segment' },
        { label: 'Edit Segment' },
        { label: 'View Segment' },
        { label: 'Edit Segment Checker' },
        { label: 'view checker' },
        { label: 'edit checker' },
        { label: 'View Use Case' },
        { label: 'Add Use Case' },
        { label: 'Edit Use Case' },
        { label: 'Use Case Management' },

        { label: 'View Segment Checker' },

        { label: 'Commission' },
        { label: 'Add Fixed Commission' },

        { label: 'Add Percentage of Transaction Amount' },

        { label: 'Add Slab Wise Fixed' },
        { label: 'Add Slab Wise Percentage' },
        { label: 'Add Slab Wise Hybird Fixed and Percentage' },
        { label: 'Edit Fixed Commission' },
        { label: 'View Fixed Commission' },

        { label: 'Edit Percentage Of Transaction Amount' },
        { label: 'View Percentage Of Transaction Amount' },

        { label: 'Edit Slab Wise Fixed' },
        { label: 'View Slab Wise Fixed' },
        { label: 'Edit Slab Wise Percentage' },
        { label: 'View Slab Wise Percentage' },
        { label: 'Edit Slab Wise Hybrid Fixed and Percentage' },
        { label: 'View Slab Wise Hybrid Fixed and Percentage' },
        { label: 'Edit Commission' },
        { label: 'View Commission' },
        { label: 'Edit Fixed Com Checker' },
        { label: 'View Fixed Com Checker' },

        { label: 'Edit Percentage of Transaction Amount Checker' },
        { label: 'View Percentage of Transaction Amount Checker' },

        { label: 'Edit Slab Wise Fixed Checker' },
        { label: 'View Slab Wise Fixed Checker' },
        { label: 'Edit Slab wise Percentage Checker' },
        { label: 'View Slab wise Percentage Checker' },
        { label: 'Edit Slab Wise Hybirid Checker' },
        { label: 'View Slab Wise Hybrid Checker' },

        { label: 'Pricing' },
        { label: 'Add Pricing Fixed' },
        { label: 'Add Pricing Percentage of Transaction Amount' },
        { label: 'Add Pricing Slab Wise Fixed' },
        { label: 'Add Pricing Slab Wise Percentage' },
        { label: 'Add Pricing Conditional Fee' },
        { label: 'Add Pricing Slab Wise Hybrid' },

        { label: 'Edit Pricing Fixed' },
        { label: 'Edit Pricing Percentage of Transaction Amount' },
        { label: 'Edit Pricing Slab Wise Fixed' },
        { label: 'Edit Pricing Slab Wise Percentage' },
        { label: 'Edit Pricing Conditional Fee' },
        { label: 'Edit Pricing Slab Wise Hybrid' },

        { label: 'View Pricing Fixed' },
        { label: 'View Pricing Percentage of Transaction Amount' },
        { label: 'View Pricing Slab Wise Fixed' },
        { label: 'View Pricing Slab Wise Percentage' },
        { label: 'View Pricing Conditional Fee' },
        { label: 'View Pricing Slab Wise Hybrid' },

        { label: 'Edit Pricing Fixed Checker' },
        { label: 'Edit Pricing Percentage of Transaction Amount Checker' },
        { label: 'Edit Pricing Slab Wise Fixed Checker' },
        { label: 'Edit Pricing Slab Wise Percentage Checker' },
        { label: 'Edit Pricing Conditional Fee Checker' },
        { label: 'Edit Pricing Slab Wise Hybrid Checker' },

        { label: 'View Pricing Fixed Checker' },
        { label: 'View Pricing Percentage of Transaction Amount Checker' },
        { label: 'View Pricing Slab Wise Fixed Checker' },
        { label: 'View Pricing Slab Wise Percentage Checker' },
        { label: 'View Pricing Conditional Fee Checker' },
        { label: 'View Pricing Slab Wise Hybrid Checker' },
        { label: 'Accounts Management' },
        { label: 'Edit Virtual Card Details' },
        { label: 'View Virtual Card Details' },
        { label: 'Edit Checker Virtual Card' },
        { label: 'View Checker Virtual Card' },

        { label: 'Edit Slab Wise Hybrid Checker' },
        { label: 'View Slab Wise Hybrid Checker' },

        // CX Field Management
        { label: 'CX Field Management' },
        { label: 'Add CX Field Management' },
        { label: 'Edit CX Field Management' },
        { label: 'View CX Field Management' },
        { label: 'View Transactions' },
        { label: 'Other Portals' },

        // CX Role Management
        { label: 'CX Role Management' },
        { label: 'Add CX Role Management' },
        { label: 'Edit CX Role Management' },
        { label: 'View CX Role Management' },

        { label: 'Channel' },
        { label: 'Add Channel' },
        { label: 'Edit Channel' },
        { label: 'View Channel' },
        { label: 'Channel Checker Edit' },
        { label: 'Channel Checker View' },

        { label: 'Product Catalog' },
        { label: 'Add Product Catalog' },
        { label: 'Edit Product Catalog' },
        { label: 'View Product Catalog' },
        { label: 'Edit Product Catalog Checker' },
        { label: 'View Product Catalog Checker' },

        { label: 'Account Onboarding' },
        { label: 'Add Account Onboarding' },
        { label: 'Edit Account Onboarding' },
        { label: 'View Account Onboarding' },
        { label: 'Account Onboarding Checker Edit' },
        { label: 'Account Onboarding Checker View' },

        { label: 'General Ledger' },
        { label: 'Edit General Ledger Checker' },
        { label: 'View General Ledger Checker' },

        { label: 'ESB' },
        { label: 'Add ESB' },
        { label: 'Edit ESB' },
        { label: 'View ESB' },
        { label: 'Edit ESB Checker' },
        { label: 'View ESB Checker' },
        { label: 'Create Region' },
        { label: 'Add Create Region' },
        { label: 'Edit Create Region' },
        { label: 'View Create Region' },
        { label: 'Edit Sales Region Checker' },
        { label: 'View Sales Region Checker' },
        { label: 'Create Sales Roles' },
        { label: 'Add Sales Roles' },
        { label: 'Edit Sales Roles' },
        { label: 'View Sales Roles' },
        { label: 'Edit Sales Roles Checker' },
        { label: 'View Sales Roles Checker' },
        { label: 'Create Sales Force' },
        { label: 'Add Sales Force' },
        { label: 'Edit Sales Force' },
        { label: 'View Sales Force' },
        { label: 'Edit Sales Force Checker' },
        { label: 'View Sales Force Checker' },
        { label: 'Create Sales Hierarchy' },
        { label: 'Add Sales Hierarchy' },
        { label: 'Edit Sales Hierarchy' },
        { label: 'View Sales Hierarchy' },
        { label: 'Edit Sales Hierarchy Checker' },
        { label: 'View Sales Hierarchy Checker' },

        // Campaign Management
        { label: 'Campaign Management' },
        { label: 'Add Campaign Management' },
        { label: 'Edit Campaign Management' },
        { label: 'View Campaign Management' },
        { label: 'Edit Campaign Checker' },
        { label: 'View Campaign Checker' },
        //
        { label: 'SignUp Limits' },
        { label: 'Add SignUp Limits' },
        { label: 'Edit SignUp Limits' },
        { label: 'View SignUp Limits' },
        { label: 'Edit Checker SignUp Limits' },
        { label: 'View Checker SignUp Limits' },
        //
        { label: 'Otp' },
        { label: 'AddOtp' },
        { label: 'Edit Otp Checker' },
        { label: 'View Otp Checker' },
        { label: 'Edit Otp' },
        { label: 'view Otp' },

        // pmd
        { label: 'PMD' },
        { label: 'Add PMD' },
        { label: 'Edit PMD' },
        { label: 'View PMD' },
        { label: ' Edit PMD Checker' },
        { label: 'View PMD Checker' },
        // secret questions
        { label: 'Secret Questions' },
        { label: 'Add Secret Question' },
        { label: 'Edit Secret Question' },
        { label: 'View Secret Question' },
        { label: ' Edit Secret Questions Checker' },
        { label: ' View Secret Questions Checker' },
        //disable sms
        { label: 'Disable SMS' },
        { label: 'Add Disable SMS' },
        { label: 'Edit Disable SMS' },
        { label: 'View Disable SMS' },
        { label: 'Edit Disable SMS Checker' },
        { label: 'View Disable SMS Checker' },

        { label: 'Organization' },
        { label: 'Add Organization' },
        { label: 'Edit Organization' },
        { label: 'View Organization' },
        { label: 'Location' },
        { label: 'Add Location' },
        { label: 'Edit Location' },
        { label: 'View Location' },
        { label: 'Cost Center' },
        { label: 'Add Cost Center' },
        { label: 'Edit Cost Center' },
        { label: 'View Cost Center' },
        { label: 'Category' },
        { label: 'Add Category' },
        { label: 'Edit Category' },
        { label: 'View Category' },
        { label: 'Sub Category COA' },
        { label: 'Add Sub Category COA' },
        { label: 'Edit Sub Category COA' },
        { label: 'View Sub Category COA' },
        { label: 'Currency' },
        { label: 'Add Currency' },
        { label: 'Edit Currency' },
        { label: 'View Currency' },
        { label: 'MRP Code' },
        { label: 'Add MRP Code' },
        { label: 'Edit MRP Code' },
        { label: 'View MRP Code' },
        { label: 'Chart of Accounts' },
        { label: 'Add Chart of Accounts' },
        { label: 'Edit Chart of Accounts' },
        { label: 'View Chart of Accounts' },
        { label: 'Edit Checker Cost Center' },
        { label: 'View Checker Cost Center' },
        { label: 'Edit Checker Category' },
        { label: 'View Checker Category' },
        { label: 'Edit Checker Currency' },
        { label: 'View Checker Currency' },

        { label: 'Edit Checker MrpCode' },
        { label: 'View Checker MrpCode' },
        { label: 'Edit Checker SubCategory' },
        { label: 'View Checker SubCategory' },

        { label: 'Edit Checker Organization' },
        { label: 'View Checker Organization' },

        { label: 'Edit Checker Chart of Accounts' },
        { label: 'View Checker Chart of Accounts' },

        { label: 'Add Commission Hierarchy' },
        { label: 'Edit Commission Hierarchy' },
        { label: 'View Commission Hierarchy' },
        { label: 'Checker Edit Commission Hierarchy' },
        { label: 'Checker View Commission Hierarchy' },
        { label: 'T24 Account Link' },
        { label: 'Add T24 Account Link' },
        { label: 'Edit T24 Account Link' },
        { label: 'View T24 Account Link' },
        { label: 'Checker Edit T24 Account Link' },
        { label: 'Checker View T24 Account Link' },
        { label: 'Edit MPR Code' },

        { label: 'Add Commission Hierarchy' },
        { label: 'Edit Commission Hierarchy' },
        { label: 'View Commission Hierarchy' },
        { label: 'View Account Details' },
        { label: 'Edit Account Details' },
        { label: 'View Customer Details' },
        { label: 'View Transaction' },
        { label: 'View Account Statement' },
        { label: 'Edit Checker Account Details' },
        { label: 'View Checker Account Details' },

        { label: 'Aggregator' },
        { label: 'Add Aggregator' },
        { label: 'Edit Aggregator' },
        { label: 'View Aggregator' },
        { label: 'Edit Aggregator Checker' },
        { label: 'View Aggregator Checker' },
        // bulk operations
        { label: 'Disbursement Upload' },
        { label: 'Blacklist Marking  Upload File' },

        { label: 'Disbursement File ' },
        { label: 'Scheduled Bulk Payment' },
        { label: 'Bulk Payments Receiving Report' },
        { label: 'Blacklist Marking' },
        { label: 'Blacklist Marking  Upload' },

        { label: 'Bulk Blacklisted CNIC External' },
        { label: 'Bulk Filer Upload' },
        { label: ' Filer Non Filer Update' },

        { label: 'Bulk Ac Opening' },
        { label: 'Upload AC Opening' },
        { label: 'Preview Ac Opening' },

        { label: ' Transaction Reversal' },
        { label: ' Segment Update' },
        { label: ' Email Update' },

        { label: 'CNIC Update' },

        { label: 'Bulk Account Status Change' },
        { label: 'Bulk Account Closure' },
        { label: 'Customer Ac Nature Marking Bulk Upload' },
        { label: 'Rules Engine' },
        { label: 'Add Rule Engine' },
        { label: 'Edit Rule Engine' },
        { label: 'View Rule Engine' },
        { label: 'Edit Checker Rule Engine' },
        { label: 'View Checker Rule Engine' },
        { label: 'Rule Field' },
        { label: 'Add Rule Field' },
        { label: 'Edit Rule Field' },
        { label: 'View Rule Field' },
        { label: 'Checker Edit Rule Field' },
        { label: 'Checker View Rule Field' },
        { label: 'Edit Domain' },
        { label: 'Add Domain' },
        { label: 'View Domain' },
        { label: 'View Checker Rule Domain' },
        { label: 'Edit Checker Rule Domain' },
        { label: 'Domain Configuration' },
        // Customer Risk Profile
        { label: 'Customer Risk Profile' },
        { label: 'Add Customer Risk Profile' },
        { label: 'Edit Customer Risk Profile' },
        { label: 'View Customer Risk Profile' },

        // Customer Risk Profile Checker
        { label: 'Risk Profile Edit' },
        { label: 'Risk Profile View' },

        // CRP Model
        { label: 'CRP Model' },
        { label: 'Add CRP Model' },
        { label: 'Edit CRP Model' },
        { label: 'View CRP Model' },

        //Account Verification
        { label: 'Wallet Account Approval' },
        { label: 'Edit Wallet Account Approval' },
        { label: 'View Wallet Account Approval' },
        { label: 'Edit Checker Wallet Account Approval' },
        { label: 'View Checker Wallet Account Approval' },
        //card pricing profile
        { label: ' Card pricing profile' },
        { label: ' Add card pricing profile' },
        { label: '  Edit card pricing profile' },
        { label: '  View card pricing profile' },
        { label: '  Edit card pricing profile checker' },
        { label: '  View card pricing profile checker' },

        // card installment plan
        { label: 'Card Installment Plan' },
        { label: 'Add Card Installment Plan' },
        { label: 'Edit Card Installment Plan' },
        { label: 'View Card Installment Plan' },
        { label: 'Edit Card Installment Plan Checker' },
        { label: 'View Card Installment Plan Checker' },

        //Switch Configuration
        { label: 'Switch Configuration' },
        { label: 'Add Switch Configuration' },
        { label: 'Edit Switch Configuration' },
        { label: 'View Switch Configuration' },
        { label: 'Edit Switch Configuration Checker' },
        { label: 'View Switch Configuration Checker' },
        { label: 'SAF' },

        //DMO Merchant
        { label: 'DMO Merchant' },
        { label: 'Edit DMO Merchant' },
        { label: 'Add DMO Merchant' },
        { label: 'View DMO Merchant' },

        //DMO Merchant Checker
        { label: 'Edit Merchant' },
        { label: 'View Merchant' },

        //Lending Management
        { label: 'Lending Management' },
        { label: 'Add Lending Management' },
        { label: 'Edit Lending Management' },
        { label: 'View Lending Management' },
        { label: 'Edit Lending Management Checker' },
        { label: 'View Lending Management Checker' },
        { label: 'Credit Scoring Engine Management' },
        { label: 'Add Credit Scoring Engine ' },
        { label: 'Edit Credit Scoring Engine ' },
        { label: 'View Credit Scoring Engine ' },
        { label: 'Edit Credit Scoring Engine  Checker' },
        { label: 'View Credit Scoring Engine  Checker' },
        { label: 'Aggregator Configurations' },
        { label: 'Add Aggregator Configuration' },
        { label: 'Edit Aggregator Configuration' },
        { label: 'View Aggregator Configuration' },
        { label: 'Edit Aggregator Configuration Checker' },
        { label: 'View Aggregator Configuration Checker' },
        { label: 'Aggregator' },
        { label: 'Add Aggregator' },
        { label: 'Edit Aggregator' },
        { label: 'View Aggregator' },
        { label: 'Edit Aggregator Checker' },
        { label: 'View Aggregator Checker' },
        { label: 'Loan Account Management' },
        { label: 'View Loan Book' },
        { label: 'Loan Outstanding Report' },
        { label: 'Device Financing' },
        { label: 'Add Device Financing' },
        { label: 'View Device Financing' },
        // card Request
        { label: 'Debit Card Management' },
        { label: 'Edit Card Pricing Profile Checker' },
        { label: 'View Card Pricing Profile Checker' },

        { label: 'Edit Debit Card' },
        { label: 'View Debit Card' },
        { label: 'Edit Debit Card Checker' },
        { label: 'View Debit Card Checker' },
        //Login Limits
        { label: 'Login Set' },
        { label: 'Add Login Set' },
        { label: 'Edit Login Set' },
        { label: 'Edit Login Set Checker' },
        { label: ' View Login Set Checker' },
        //Upload Mobile Bundle
        { label: 'Upload Mobile Bundle' },
        { label: 'Add  Mobile Bundle Upload' },
        { label: ' View Mobile Bundle' },
        { label: ' Edit Mobile Bundle Checker' },
        { label: ' View Mobile Bundle Checker' },
        //Manage upload File
        { label: 'Manage Upload File' },
        { label: ' Add Manage Upload File' },
        { label: ' Edit Manage Upload File' },

        { label: ' Edit Manage Upload File Checker' },
        { label: ' View Manage Upload File Checker' },

        //Mobile Bundle Categories

        { label: ' Mobile Bundle Categories' },
        { label: ' Add Mobile Bundle Categories' },
        { label: ' Edit Mobile Bundle Categories' },
        { label: 'View Mobile Bundle Categories' },
        { label: 'Edit Mobile Bundle Categories Checker' },
        { label: 'View Mobile Bundle Categories Checker' },
        // 2nd sub category
        { label: ' Second Sub Category' },
        { label: 'Add Second Sub Category' },
        { label: 'Edit Second Sub Category' },
        { label: 'View Second Sub Category' },
        { label: 'Edit Second Sub Category Checker' },
        { label: 'View Second Sub Category Checker' },
        //Login Limits
        { label: ' Login Set' },
        { label: ' Add Login Set' },
        { label: ' Edit Login Set' },
        { label: 'View Login Set' },
        { label: 'Edit Login Set Checker' },
        { label: 'View Login Set Checker' },
        //Main Category
        { label: ' Main Category' },
        { label: ' Add Main Category' },
        { label: 'Edit Main Category' },
        { label: 'View Main Category' },
        { label: 'Edit Main Category Checker' },
        { label: 'View Main Category Checker' },
        // Manage Mobile Numbers
        { label: ' Manage Mobile Numbers' },
        { label: 'Add Manage Mobile Numbers' },
        { label: 'Edit Manage Mobile Numbers' },
        { label: 'View Manage Mobile Numbers' },
        { label: 'Edit Manage Mobile Numbers Checker' },
        { label: 'View Manage Mobile Numbers Checker' },
        //Nadra
        { label: ' Nadra' },
        { label: ' Add Nadra' },
        { label: 'Edit Nadra' },
        { label: 'View Nadra' },
        { label: ' View Nadra Checker' },
        { label: ' Edit Nadra Checker' },

        //push notification

        { label: ' Notifications' },
        { label: ' Add Notifications' },
        { label: ' Edit Notifications' },
        { label: ' View Notifications' },
        { label: ' Edit Notifications Checker' },
        { label: ' View Notifications Checker' },
        // sms

        { label: 'Sms' },
        { label: 'Add Sms' },
        { label: 'Edit Sms' },
        { label: 'View Sms' },
        { label: 'Edit Sms Checker' },
        { label: 'View Sms Checker' },
        // Sub Category
        { label: 'Sub Category' },
        { label: 'Add Sub Category' },
        { label: 'Edit Sub Category' },
        { label: 'View Sub Category' },
        { label: 'Edit Sub Category Checker' },
        { label: 'View Sub Category Checker' },
        // Theme
        { label: 'Theme' },
        { label: 'Add Theme' },
        { label: 'Edit Theme' },
        { label: 'View Theme' },
        { label: 'Edit Theme Checker' },
        { label: 'View Theme Checker' },
        //Bank
        { label: 'Bank' },
        { label: 'Add New Bank' },
        { label: 'Edit Bank' },
        { label: 'View Bank' },
        { label: 'Edit Bank Checker' },
        { label: 'View Bank Checker' },
        // Company
        { label: 'Company' },
        { label: 'Add New Company' },
        { label: 'Edit Company' },
        { label: 'View Company' },
        { label: 'Edit Company Checker' },
        { label: 'View Company Checker' },
        //Parser
        { label: 'Parser' },
        { label: 'Add Parser' },
        { label: 'Edit Parser' },
        { label: 'View Parser' },
        { label: 'Edit Parser Checker' },
        { label: 'View Parser Checker' },

        // product
        { label: 'Product' },
        { label: 'Add New Product' },
        { label: 'Edit Product' },
        { label: 'View Product' },
        { label: 'Edit Product Checker' },
        { label: 'View Product Checker' },
        //Banners
        { label: 'Banners' },
        { label: 'Add Banner' },
        { label: 'Edit Banner ' },
        { label: 'View Banner ' },

        { label: 'Edit Banner Checker' },
        { label: 'View Banner Checker' },
        //account mantainance

        { label: 'Edit Agent Account Maintenance' },
        { label: 'View Agent Account Maintenance' },
        // Promotion
        { label: ' Add Promotion' },
        { label: 'Edit Promotion' },
        { label: 'View Promotion' },
        { label: 'Edit Promotion Checker' },
        { label: 'View Promotion Checker' },
        // Redirect Splash
        { label: ' Add Redirect Splash' },
        { label: 'Edit Redirect Splash' },
        { label: 'View Redirect Splash' },
        { label: 'Edit Redirect Splash Checker' },
        { label: 'View Redirect Splash Checker' },
        // input splash
        { label: ' Add Input Splash' },
        { label: 'Edit Input Splash' },
        { label: 'View Input Splash' },
        { label: 'Edit Input Splash Checker' },
        { label: 'View Input Splash Checker' },
        // information splash
        { label: ' Add Information Splash' },
        { label: 'Edit Information Splash' },
        { label: 'View Information Splash' },
        { label: 'Edit Information Splash Checker' },
        { label: 'View Information Splash Checker' },
        // New Ticker
        { label: ' Add Ticker' },
        { label: 'Edit Ticker' },
        { label: 'View Ticker' },
        { label: 'Edit Ticker Checker' },
        { label: 'View Ticker Checker' },
        // error message
        { label: 'Error Messages' },
        { label: 'Edit Error ' },
        { label: 'Edit Error Checker' },
        { label: 'View Error Checker' },
        { label: 'View Error' },
        //Agent Mate Main Category
        { label: 'Agent Mate Main Category' },
        { label: ' Add Agent Mate Main Category' },
        { label: 'Edit Agent Mate Main Category' },
        { label: 'View Agent Mate Main Category' },
        { label: 'Edit Agent Mate Main Category Checker' },
        { label: 'View Agent Mate Main Category Checker' },
        //Agent Mate Sub Category
        { label: 'Agent Mate Sub Category' },
        { label: 'Add Agent Mate Sub Category' },
        { label: 'Edit Agent Mate Sub Category' },
        { label: 'View Agent Mate Sub Category' },
        { label: 'Edit Agent Mate Sub Category Checker' },
        { label: 'View Agent Mate Sub Category Checker' },
        //Agent Mate Product
        { label: 'Agent Mate Product' },
        { label: 'Add Agent Mate Product' },
        { label: 'Edit Agent Mate Product' },
        { label: 'View Agent Mate Product' },
        { label: 'Edit Agent Mate Product Checker' },
        { label: 'View Agent Mate Product Checker' },
        //Agent Mate Company
        { label: 'Agent Mate Company' },
        { label: 'Add Agent Mate Company' },
        { label: 'Edit Agent Mate Company' },
        { label: 'View Agent Mate Company' },
        { label: 'Edit Agent Mate Company Checker' },
        { label: 'View Agent Mate Company Checker' },
        //Agent Mate Bank
        { label: 'Agent Mate Bank' },
        { label: 'Add Agent Mate Bank' },
        { label: 'Edit Agent Mate Bank' },
        { label: 'View Agent Mate Bank' },
        { label: 'Edit Agent Mate Bank Checker' },
        { label: 'View Agent Mate Bank Checker' },

        // zmiles

        { label: 'Base Factor' },
        { label: 'Point Factor' },
        { label: 'Edit Zmiles Point Factor' },
        { label: 'View Zmiles Point Factor' },

        { label: 'Tier' },
        { label: 'Tier Range' },
        { label: 'Edit Zmiles Tier' },
        { label: 'View Zmiles Tier' },

        { label: 'Point Redemption' },
        { label: 'Add Point Redemption' },
        { label: 'Edit Point Redemption' },
        { label: 'View Point Redemption' },
        { label: 'Edit Zmiles Voucher' },
        // customer risk dashboard
        { label: 'Risk Dashboard' },
        { label: 'Device Section' },
        { label: 'Registration Section' },
        { label: 'Retailer and Customer Transaction Section' },
        { label: 'Risk and Business Section' }
    ];

    let rightMenuClick;

    let configClick;
    let menuClick;
    let searchClick = false;
    let topbarItemClick;

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true);
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active) => {
        setRightMenuActive(active);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    const handleLogout = () => {
        // if (sessionStorage.getItem('sessionTimeOut')) {
        navigate('/');
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
        toast.warn('Logged out because of inactivity');
        // }
    };

    useIdleTimer({
        timeout: 600000,
        onIdle: handleLogout,
        debounce: 500
    });

    const handleShowModal = () => {
        dispatch(showMessageAction({ isShowModal: false, message: '', isError }));
    };

    const loadUrl = (url) => {
        navigate(url);
    };

    const dialogProps = {
        showModal,
        message,
        isError,
        handleShowModal,
        loadUrl,
        url
    };

    useEffect(() => {
        setPath(window.location.href?.split('/#')?.pop());
    }, [window.location.href]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (path !== '/') {
            const findingMainMenu = userMenu?.map((mainItem) => mainItem?.items?.map((subItem) => (subItem?.to?.toLowerCase() === path?.toLowerCase() ? subItem : null))?.filter(Boolean))?.flat();
            const finding = findingMainMenu?.length > 0 || path?.toLowerCase()?.includes('add') || path?.toLowerCase()?.includes('edit') || path?.toLowerCase()?.includes('view') ? 'done' : '';
            if (finding) {
                // setFoundMenu(finding);
                if (finding !== 'done') {
                    navigate('/');
                }
            } else if (auth) {
                navigate('/');
            }
        }
    }, [path, userMenu]); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {

    //     if (foundMenu === 'done') {
    //     } else if (auth && path !== '/') {
    //         navigate('/');
    //     }
    // }, [foundMenu]); // eslint-disable-line react-hooks/exhaustive-deps

    // const changeSchemeColor = (color) => props.onColorSchemeChange(`${color}`);
    // changeSchemeColor('light');

    useEffect(() => {
        if (window.top !== window.self) {
            window.top.location = window.self.location;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <LoadingOverlay active={isLoading} spinner text="Please Wait ...">
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                <ShowMessageDialog {...dialogProps} />
                {/* <NotificationComponent /> */}
                <ToastContainer />
                {isNewUser === 'Y' ? (
                    <Routes>
                        <Route exact path="/" element={<Login />} />
                        <Route exact path="/updatepassword" element={<UpdatePassword />} />
                        <Route exact path="/VerifyOtp" element={<VerifyOtp />} />
                        <Route exact path="*" element={<Login />} />
                    </Routes>
                ) : (!auth || auth === 'undefined' || auth === undefined) && isNewUser !== 'N' ? (
                    <Routes>
                        <Route exact path="/" element={<Login />} />
                        <Route exact path="/VerifyOtp" element={<VerifyOtp />} />
                        <Route exact path="/forgetpassword" element={<ForgetPasswordOTP />} />
                        <Route exact path="/updatepassword" element={<UpdatePassword />} />
                        <Route exact path="*" element={<Login />} />
                    </Routes>
                ) : (
                    <div className="layout-main">
                        <AppTopbar
                            items={menu}
                            menuMode={menuMode}
                            colorScheme={props.colorScheme}
                            menuActive={menuActive}
                            topbarMenuActive={topbarMenuActive}
                            activeInlineProfile={activeInlineProfile}
                            onTopbarItemClick={onTopbarItemClick}
                            onMenuButtonClick={onMenuButtonClick}
                            onSidebarMouseOver={onSidebarMouseOver}
                            onSidebarMouseLeave={onSidebarMouseLeave}
                            onToggleMenu={onToggleMenu}
                            onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                            onMenuClick={onMenuClick}
                            onMenuItemClick={onMenuItemClick}
                            onRootMenuItemClick={onRootMenuItemClick}
                            resetActiveIndex={resetActiveIndex}
                        />

                        <AppMenu
                            model={menu}
                            onRootMenuItemClick={onRootMenuItemClick}
                            onMenuItemClick={onMenuItemClick}
                            onToggleMenu={onToggleMenu}
                            onMenuClick={onMenuClick}
                            menuMode={menuMode}
                            colorScheme={props.colorScheme}
                            menuActive={menuActive}
                            sidebarActive={sidebarActive}
                            sidebarStatic={sidebarStatic}
                            pinActive={pinActive}
                            onSidebarMouseLeave={onSidebarMouseLeave}
                            onSidebarMouseOver={onSidebarMouseOver}
                            activeInlineProfile={activeInlineProfile}
                            onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                            resetActiveIndex={resetActiveIndex}
                        />

                        <AppBreadcrumb
                            routes={routes}
                            onMenuButtonClick={onMenuButtonClick}
                            menuMode={menuMode}
                            onRightMenuButtonClick={onRightMenuButtonClick}
                            onInputClick={onInputClick}
                            searchActive={searchActive}
                            breadcrumbClick={breadcrumbClick}
                        />

                        <div className="layout-main-content">
                            <Routes>
                                <Route path="/" exact="true" element={<Dashboard />} />
                                <Route exact path="/AddSecretQuestion" element={<AddSecretQestion />} />
                                <Route exact path="/AddSignUpLimits" element={<AddSignUpLimit />} />
                                <Route exact path="/BlacklistMarkingUpload" element={<BlacklistMarkingUpload />} />
                                <Route exact path="/BulkAccountClosure" element={<BulkAccountClosure />} />
                                <Route exact path="/BulkAccountClosureSummary" element={<BulkAccountClosureSummary />} />
                                <Route exact path="/BulkAccountStatusChange" element={<BulkAccountStatusChange />} />
                                <Route exact path="/BulkAccountStatusChangeSummary" element={<BulkAccountStatusChangeSummary />} />
                                <Route exact path="/BulkAccountStatusDownload" element={<BulkAccountStatusDownload />} />
                                <Route exact path="/BulkBlacklistedCnicExternal" element={<BulkBlacklistedCnicExternal />} />
                                <Route exact path="/BulkOperationsReport" element={<BulkOperationsReport />} />
                                <Route exact path="/CnicUpdate" element={<CnicUpdate />} />
                                <Route exact path="/CustomerAcNatureMarkingBulkUpload" element={<CustomerAcNatureMarkingBulkUpload />} />
                                <Route exact path="/CustomerAcNatureSummary" element={<CustomerAcNatureSummary />} />
                                <Route exact path="/EditBlacklistMarkingUploadChecker" element={<EditBlacklistMarkingUploadChecker />} />
                                <Route exact path="/EditBulkAcOpeningChecker" element={<EditBulkAcOpeningChecker />} />
                                <Route exact path="/EditBulkAccountClosureChecker" element={<EditBulkAccountClosureChecker />} />
                                <Route exact path="/EditBulkAccountStatusChangeChecker" element={<EditBulkAccountStatusChangeChecker />} />
                                <Route exact path="/EditBulkCnicUpdateChecker" element={<EditBulkCnicUpdateChecker />} />
                                <Route exact path="/EditBulkEmailUpdateChecker" element={<EditBulkEmailUpdateChecker />} />
                                <Route exact path="/EditBulkFilerNonFilerUpdateChecker" element={<EditBulkFilerNonFilerUpdateChecker />} />
                                <Route exact path="/EditBulkSegmentUpdateChecker" element={<EditBulkSegmentUpdateChecker />} />
                                <Route exact path="/EditBulkTransactionReversalChecker" element={<EditBulkTransactionReversalChecker />} />
                                <Route exact path="/EditCheckerSignUpLimits" element={<EditCheckerSignUpLimit />} />
                                <Route exact path="/EditCustomerAcNatureChecker" element={<EditCustomerAcNatureChecker />} />
                                <Route exact path="/EditDisableSmsChecker" element={<EditDisableSmsChecker />} />
                                <Route exact path="/EditOtpChecker" element={<EditOtpChecker />} />
                                <Route exact path="/EditPmdChecker" element={<EditPmdChecker />} />
                                <Route exact path="/EditScheduledBulkPaymentsChecker" element={<EditScheduledBulkPaymentsChecker />} />
                                <Route exact path="/EditSecretQuestion" element={<EditSecretQuestion />} />
                                <Route exact path="/EditSecretQuestionsChecker" element={<EditSecretQuestionsChecker />} />
                                <Route exact path="/EditSignUpLimits" element={<EditSignUpLimit />} />
                                <Route exact path="/EmailUpdate" element={<EmailUpdate />} />
                                <Route exact path="/FilerNonFilerUpdate" element={<FilerNonFilerUpdate />} />
                                <Route exact path="/Otp" element={<OtpScreen />} />
                                <Route exact path="/Pmd" element={<PmdScreen />} />
                                <Route exact path="/ScheduledBulkPayment" element={<ScheduledBulkPayment />} />
                                <Route exact path="/SecretQuestions" element={<SecretQuestions />} />
                                <Route exact path="/SegmentUpdate" element={<SegmentUpdate />} />
                                <Route exact path="/SignUpLimits" element={<SignUpLimits />} />
                                <Route exact path="/ViewBlacklistMarkingUploadChecker" element={<ViewBlacklistMarkingUploadChecker />} />
                                <Route exact path="/ViewBulkAcOpeningChecker" element={<ViewBulkAcOpeningChecker />} />
                                <Route exact path="/ViewBulkAccountClosureChecker" element={<ViewBulkAccountClosureChecker />} />
                                <Route exact path="/ViewBulkAccountClosureSummary" element={<ViewBulkAccountClosureSummary />} />
                                <Route exact path="/ViewBulkAccountStatusChange" element={<ViewBulkAccountStatusChange />} />
                                <Route exact path="/ViewBulkAccountStatusChangeChecker" element={<ViewBulkAccountStatusChangeChecker />} />
                                <Route exact path="/ViewBulkCnicUpdateChecker" element={<ViewBulkCnicUpdateChecker />} />
                                <Route exact path="/ViewBulkEmailUpdateChecker" element={<ViewBulkEmailUpdateChecker />} />
                                <Route exact path="/ViewBulkFilerNonFilerUpdateChecker" element={<ViewBulkFilerNonFilerUpdateChecker />} />
                                <Route exact path="/ViewBulkSegmentUpdateChecker" element={<ViewBulkSegmentUpdateChecker />} />
                                <Route exact path="/ViewBulkTransactionReversalChecker" element={<ViewBulkTransactionReversalChecker />} />
                                <Route exact path="/ViewCheckerSignUpLimits" element={<ViewCheckerSignUpLimit />} />
                                <Route exact path="/ViewCustomerAcNatureChecker" element={<ViewCustomerAcNatureChecker />} />
                                <Route exact path="/ViewDisableSmsChecker" element={<ViewDisableSmsChecker />} />
                                <Route exact path="/ViewPmdChecker" element={<ViewPmdChecker />} />
                                <Route exact path="/ViewScheduledBulkPaymentsChecker" element={<ViewScheduledBulkPaymentsChecker />} />
                                <Route exact path="/ViewSecretQuestion" element={<ViewSecretQestion />} />
                                <Route exact path="/ViewSecretQuestionsChecker" element={<ViewSecretQuestionsChecker />} />
                                <Route exact path="/ViewSignUpLimits" element={<ViewSignUpLimit />} />
                                <Route exact path="/accountdetails" element={<AccountDetails />} />
                                <Route exact path="/accountonboarding" element={<AgentOnboarding />} />
                                <Route exact path="/accountonboardingcheckeredit" element={<Checkeragentonboardingedit />} />
                                <Route exact path="/accountonboardingcheckerview" element={<Checkeragentonboardingview />} />
                                <Route exact path="/accountsmanagement" element={<ManageAccounts />} />
                                <Route exact path="/addBlacklistMarkingUploadFile" element={<BlacklistMarkingUploadFile />} />
                                <Route exact path="/addBulkAccountClosureUpload" element={<BulkAccountClosureUpload />} />
                                <Route exact path="/addBulkAccountStatusUpload" element={<BulkAccountStatusUpload />} />
                                <Route exact path="/addCustomerAcUploadFile" element={<CustomerAcUploadFile />} />
                                <Route exact path="/addDomain" element={<AddRuleDomains />} />
                                <Route exact path="/addMobileBundleUpload" element={<AddMobileBundleUpload />} />
                                <Route exact path="/addNadra" element={<AddNadra />} />
                                <Route exact path="/addNewBank" element={<AddBank />} />
                                <Route exact path="/addNewcompany" element={<AddCompany />} />
                                <Route exact path="/addOtp" element={<AddOtp />} />
                                <Route exact path="/addPmd" element={<AddPmd />} />
                                <Route exact path="/addRuleEngine" element={<AddRuleEngine />} />
                                <Route exact path="/addSms" element={<AddSms />} />
                                <Route exact path="/addTicker" element={<AddTicker />} />
                                <Route exact path="/addUploadAcOpening" element={<UploadAcOpening />} />
                                <Route exact path="/addUploadCnicUpdate" element={<UploadCnicUpdate />} />
                                <Route exact path="/addUploadEmailUpdate" element={<UploadEmailUpdate />} />
                                <Route exact path="/addUploadFilerNonFilerBulk" element={<UploadFilerNonFilerBulk />} />
                                <Route exact path="/addUploadSegmentUpdate" element={<UploadSegment />} />
                                <Route exact path="/addaccountonboarding" element={<AddNewAgentOnboarding />} />
                                <Route exact path="/addagentmatebank" element={<AddAgentMateBank />} />
                                <Route exact path="/addagentmatecompany" element={<AddAgentMateCompany />} />
                                <Route exact path="/addagentmatemaincategory" element={<AddAgentMateMainCategory />} />
                                <Route exact path="/addagentmateproduct" element={<AddAgentMateProduct />} />
                                <Route exact path="/addagentmatesubcategory" element={<AddAgentMateSubCategory />} />
                                <Route exact path="/addaggregator" element={<AddAggregator />} />
                                <Route exact path="/addaggregatorconfiguration" element={<AddaggregatorConfiguration />} />
                                <Route exact path="/addbanner" element={<AddBanner />} />
                                <Route exact path="/addbiller" element={<AddBiller />} />
                                <Route exact path="/addcampaignmanagement" element={<AddCampaignManagement />} />
                                <Route exact path="/addcardinstallmentplan" element={<AddCardInstallmentPlan />} />
                                <Route exact path="/addcardpricingprofile" element={<AddCardPricingProfile />} />
                                <Route exact path="/addcategory" element={<AddCategory />} />
                                <Route exact path="/addchannel" element={<AddNewChannel />} />
                                <Route exact path="/addchartofaccounts" element={<AddChartOfAccounts />} />
                                <Route exact path="/addcommissionhierarchy" element={<AddCommissionHierarchy />} />
                                <Route exact path="/addcostcenter" element={<AddCostCenter />} />
                                <Route exact path="/addcreateregion" element={<AddCreateReg />} />
                                <Route exact path="/addcreditscoringengine" element={<AddCreditScoringEngine />} />
                                <Route exact path="/addcrpmodel" element={<AddCRPModel />} />
                                <Route exact path="/addcurrency" element={<AddCurrency />} />
                                <Route exact path="/addcustomerriskprofile" element={<AddCustomerRiskProfile />} />
                                <Route exact path="/addcxRolemanagement" element={<AddCxRoleMgmt />} />
                                <Route exact path="/addcxfieldmanagement" element={<AddCxFieldMgmt />} />
                                <Route exact path="/adddisableSms" element={<AddDisableSms />} />
                                <Route exact path="/addesb" element={<AddEnterpriseServiceBus />} />
                                <Route exact path="/addfee" element={<AddEditFees />} />
                                <Route exact path="/addfeeshareconfiguration" element={<AddFeeShareConfiguration />} />
                                <Route exact path="/addfixedcommission" element={<AddFixedCom />} />
                                <Route exact path="/addinformationsplash" element={<AddInformationSplash />} />
                                <Route exact path="/addinputsplash" element={<AddInputSplash />} />
                                <Route exact path="/addlendingmanagement" element={<AddLendingManagement />} />
                                <Route exact path="/addlocation" element={<AddLocation />} />
                                <Route exact path="/addloginset" element={<AddLoginSet />} />
                                <Route exact path="/addmaincategory" element={<AddMainCategory />} />
                                <Route exact path="/addmanagemobilenumbers" element={<AddManageMobileNumbers />} />
                                <Route exact path="/addmanageuploadfile" element={<AddManageUpload />} />
                                <Route exact path="/addmenuicon" element={<AddMenuIcon />} />
                                <Route exact path="/addmobilebundlecategories" element={<AddMobileBundleCategories />} />
                                <Route exact path="/addmrpcode" element={<AddMprCode />} />
                                <Route exact path="/addnewproduct" element={<AddNewProduct />} />
                                <Route exact path="/addnotifications" element={<AddNotifications />} />
                                <Route exact path="/addorganization" element={<AddOrganization />} />
                                <Route exact path="/addparser" element={<AddParser />} />
                                <Route exact path="/addpercentageoftransactionamount" element={<AddPercentageOfTrxAmount />} />
                                <Route exact path="/addpricingconditionalfee" element={<AddPricingConditionalFee />} />
                                <Route exact path="/addpricingfixed" element={<AddPricingFixed />} />
                                <Route exact path="/addpricingpercentageoftransactionamount" element={<AddPricingTxtAmount />} />
                                <Route exact path="/addpricingslabwisefixed" element={<AddPricingSlabWiseFixed />} />
                                <Route exact path="/addpricingslabwisehybrid" element={<AddPricingSlabWiseHybrid />} />
                                <Route exact path="/addpricingslabwisepercentage" element={<AddPricingSlabWisePercentage />} />
                                <Route exact path="/addproductcatalog" element={<AddNewProductCatalog />} />
                                <Route exact path="/addpromotion" element={<AddPromotion />} />
                                <Route exact path="/addredirectsplash" element={<AddRedirectSplash />} />
                                <Route exact path="/addrulefield" element={<AddDynamicRule />} />
                                <Route exact path="/addsalesforce" element={<AddCreateSalesForce />} />
                                <Route exact path="/addsaleshierarchy" element={<AddSalesHierarchy />} />
                                <Route exact path="/addsalesroles" element={<AddCreateSalesRoles />} />
                                <Route exact path="/addsecondsubcategory" element={<AddSecondSubCategory />} />
                                <Route exact path="/addsegment" element={<AddSegment />} />
                                <Route exact path="/addslabwisefixed" element={<AddSlabWiseFixed />} />
                                <Route exact path="/addslabwisehybridfixedandpercentage" element={<AddSlabWiseHybridFixPer />} />
                                <Route exact path="/addslabwisepercentage" element={<AddSlabWisePercentage />} />
                                <Route exact path="/addsubcategory" element={<AddSubCategory />} />
                                <Route exact path="/addsubcategorycoa" element={<AddSubCategoryCoa />} />
                                <Route exact path="/addswitchconfiguration" element={<AddSwitchConfiguration />} />
                                <Route exact path="/addt24accountlink" element={<AddT24accountLink />} />
                                <Route exact path="/addtaxprofile" element={<AddTaxProfile />} />
                                <Route exact path="/addtheme" element={<AddTheme />} />
                                <Route exact path="/addthresholdfeeconfiguration" element={<AddEditThresholdFee />} />
                                <Route exact path="/addtransactionlimitvalidator" element={<AddEditTransactionLimitValidator />} />
                                <Route exact path="/addtransactions" element={<AddTransactionConfiguration />} />
                                <Route exact path="/addtransactionwiselimit" element={<AddtransactionwiseLimit />} />
                                <Route exact path="/addtypeofaccount" element={<AddTypeOfAccount />} />
                                <Route exact path="/adduploadTransactionReversal" element={<UploadTransactionReversal />} />
                                <Route exact path="/addusecase" element={<AdduseCaseManagement />} />
                                <Route exact path="/addusergroup" element={<AddUsergroup />} />
                                <Route exact path="/addusermanagement" element={<AddUserManagement />} />
                                <Route exact path="/addvalidators" element={<AddEditValidators />} />
                                {/* <Route exact path="/addvoucher" element={<AddVoucher />} /> */}
                                <Route exact path="/agentaccountsmanagementcheckeredit" element={<AgentAccountManagementCheckerEdit />} />
                                <Route exact path="/agentaccountsmanagementcheckerview" element={<AgentAccountManagementCheckerView />} />
                                <Route exact path="/otherPortals" element={<Sonic />} />
                                <Route exact path="/agentcommissionshare" element={<AgentCommissionFee />} />
                                <Route exact path="/agentmatebank" element={<AgentMateBank />} />
                                <Route exact path="/agentmatecompany" element={<AgentMateCompany />} />
                                <Route exact path="/agentmatemaincategory" element={<AgentMateMainCategory />} />
                                <Route exact path="/agentmateproduct" element={<AgentMateProduct />} />
                                <Route exact path="/agentmatesubcategory" element={<AgentMateSubCategory />} />
                                <Route exact path="/aggregator" element={<Aggregators />} />
                                <Route exact path="/aggregatorconfigurations" element={<Aggregatorconfigurations />} />
                                <Route exact path="/bank" element={<Bank />} />
                                <Route exact path="/banners" element={<Banners />} />
                                <Route exact path="/basefactor" element={<BaseFactor />} />
                                <Route exact path="/biller" element={<Biller />} />
                                <Route exact path="/bulkAcOpening" element={<BulkAcOpening />} />
                                <Route exact path="/bulkDisbursementView" element={<BulkDisbursementView />} />
                                <Route exact path="/campaignmanagement" element={<CampaignManagement />} />
                                <Route exact path="/cardinstallmentplan" element={<CardInstallmentPlan />} />
                                <Route exact path="/cardpricingprofile" element={<CardPricingProfile />} />
                                <Route exact path="/category" element={<Category />} />
                                <Route exact path="/channel" element={<Channels />} />
                                <Route exact path="/channelcheckeredit" element={<ChannelCheckerEdit />} />
                                <Route exact path="/channelcheckerview" element={<ChannelCheckerView />} />
                                <Route exact path="/chartofaccounts" element={<ViewCOA />} />
                                <Route exact path="/checkbooknoedit" element={<CheckBookNoEdit />} />
                                <Route exact path="/checkbooknoview" element={<CheckBookNoView />} />
                                <Route exact path="/checkereditbulkoperation" element={<EditBulkDisbursement />} />
                                <Route exact path="/checkereditcommissionhierarchy" element={<CheckerEditCommissionHierarchy />} />
                                <Route exact path="/checkereditrulefield" element={<DynamicRuleCheckerEdit />} />
                                <Route exact path="/checkereditt24accountlink" element={<CheckerEditT24accountLink />} />
                                <Route exact path="/checkerviewbulkoperation" element={<ViewBulkDisbursement />} />
                                <Route exact path="/checkerviewcommissionhierarchy" element={<CheckerViewCommissionHierarchy />} />
                                <Route exact path="/checkerviewrulefield" element={<DynamicRuleCheckerView />} />
                                <Route exact path="/checkerviewt24accountlink" element={<CheckerViewT24accountLink />} />
                                <Route exact path="/commission" element={<Commission />} />
                                <Route exact path="/company" element={<Company />} />
                                <Route exact path="/costcenter" element={<CostCenter />} />
                                <Route exact path="/createregion" element={<SalesHierarchy />} />
                                <Route exact path="/createsalesforce" element={<SalesForce />} />
                                <Route exact path="/createsaleshierarchy" element={<MaintainSalesHierarchy />} />
                                <Route exact path="/createsalesroles" element={<CreateSalesRoles />} />
                                <Route exact path="/creditscoringenginemanagement" element={<CreditScoringEngineManagement />} />
                                <Route exact path="/crpmodel" element={<CRPModel />} />
                                <Route exact path="/currency" element={<Currency />} />
                                <Route exact path="/customerloanprofile" element={<CustomerLoanProfile />} />
                                <Route exact path="/customerriskprofile" element={<CustomerRiskProfile />} />
                                <Route exact path="/cxRolemanagement" element={<CxRoleMgmt />} />
                                <Route exact path="/cxfieldmanagement" element={<CxFieldMgmt />} />
                                <Route exact path="/debitcardmanagement" element={<DebitCardManagement />} />
                                <Route exact path="/devicesection" element={<DeviceSection />} />
                                <Route exact path="/disablesms" element={<DisableSms />} />
                                <Route exact path="/disbursementFile" element={<DisbursementFileSummary />} />
                                <Route exact path="/disbursementUpload" element={<DisbursementUploadPage />} />
                                <Route exact path="/clawbackUpload" element={<ClawBackUploadPage />} />
                                <Route exact path="/dmomerchant" element={<DmoMerchants />} />
                                <Route exact path="/domainConfiguration" element={<DomainConfigurations />} />
                                <Route exact path="/dualnationality" element={<DualNationalityFields />} />
                                <Route exact path="/editCheckerBranch" element={<EditCheckerLocation />} />
                                <Route exact path="/editCheckerCategory" element={<EditCheckerCategory />} />
                                <Route exact path="/editCheckerChartOfAccounts" element={<EditCheckerChartofAccounts />} />
                                <Route exact path="/editCheckerCostCenter" element={<EditCheckerCostCenter />} />
                                <Route exact path="/editCheckerCurrency" element={<EditCheckerCurrency />} />
                                <Route exact path="/editCheckerMrpCode" element={<EditCheckerMprCode />} />
                                <Route exact path="/editCheckerOrganization" element={<EditCheckerOrganization />} />
                                <Route exact path="/editCheckerRuleDomain" element={<EditRuleDomainsChecker />} />
                                <Route exact path="/editCheckerRuleEngine" element={<EditCheckerRuleEngine />} />
                                <Route exact path="/editCheckerSubCategory" element={<EditCheckerSubCategoryCoa />} />
                                <Route exact path="/editDomain" element={<EditRuleDomains />} />
                                <Route exact path="/editError" element={<EditError />} />
                                <Route exact path="/editErrorchecker" element={<EditErrorChecker />} />
                                <Route exact path="/viewMobileBundle" element={<EditMobileBundle />} />
                                <Route exact path="/editMobileBundleChecker" element={<EditMobileBundleChecker />} />
                                <Route exact path="/editNadra" element={<EditNadra />} />
                                <Route exact path="/editNadraChecker" element={<EditNadraChecker />} />
                                <Route exact path="/editOtp" element={<EditOtp />} />
                                <Route exact path="/editPmd" element={<EditPmd />} />
                                <Route exact path="/editPricingConditionalFeeChecker" element={<EditPricingConditionalFeeChecker />} />
                                <Route exact path="/editPricingFixedChecker" element={<EditPricingFixedChecker />} />
                                <Route exact path="/editPricingSlabWiseFixedChecker" element={<EditPricingSlabWiseFixedChecker />} />
                                <Route exact path="/editPricingSlabWiseHybridChecker" element={<EditPricingSlabWiseHybridChecker />} />
                                <Route exact path="/editPricingSlabWisePercentageChecker" element={<EditPricingSlabWisePercentageChecker />} />
                                <Route exact path="/editPricingTxtAmountChecker" element={<EditPricingTxtAmountChecker />} />
                                <Route exact path="/editRuleEngine" element={<EditRuleEngine />} />
                                <Route exact path="/editSms" element={<EditSms />} />
                                <Route exact path="/editSmschecker" element={<EditSmsChecker />} />
                                <Route exact path="/editTicker" element={<EditTicker />} />
                                <Route exact path="/editaccountdetails" element={<AccountDetailsEdit />} />
                                <Route exact path="/editaccountonboarding" element={<EditAgentOnboarding />} />
                                <Route exact path="/editagentaccountmaintenance" element={<AgentAccountMaintenanceEdit />} />
                                <Route exact path="/editagentmatebank" element={<EditAgentMateBank />} />
                                <Route exact path="/editagentmatebankchecker" element={<EditAgentMateBankChecker />} />
                                <Route exact path="/editagentmatecompany" element={<EditAgentMateCompany />} />
                                <Route exact path="/editagentmatecompanychecker" element={<EditAgentMateCompanyChecker />} />
                                <Route exact path="/editagentmatemaincategory" element={<EditAgentMateMainCategory />} />
                                <Route exact path="/editagentmatemaincategorychecker" element={<EditAgentMateMainCategoryChecker />} />
                                <Route exact path="/editagentmateproduct" element={<EditAgentMateProduct />} />
                                <Route exact path="/editagentmateproductchecker" element={<EditAgentMateProductChecker />} />
                                <Route exact path="/editagentmatesubcategory" element={<EditAgentMateSubCategory />} />
                                <Route exact path="/editagentmatesubcategorychecker" element={<EditAgentMateSubCategoryChecker />} />
                                <Route exact path="/editaggregator" element={<EditAggregator />} />
                                <Route exact path="/editaggregatorchecker" element={<EditAggregatorChecker />} />
                                <Route exact path="/editaggregatorconfiguration" element={<EditaggregatorConfiguration />} />
                                <Route exact path="/editaggregatorconfigurationchecker" element={<EditaggregatorConfigChecker />} />
                                <Route exact path="/editbank" element={<EditBank />} />
                                <Route exact path="/editbankchecker" element={<EditBankChecker />} />
                                <Route exact path="/editbanner" element={<EditBanner />} />
                                <Route exact path="/editbannerchecker" element={<EditBannerCheckerMain />} />
                                <Route exact path="/editbannercheckerr" element={<EditBannerChecker />} />
                                <Route exact path="/editzmilesBaseFactor" element={<BaseFactorChecker />} />
                                <Route exact path="/editbiller" element={<EditBiller />} />
                                <Route exact path="/editcampaignchecker" element={<EditCampignManagementChecker />} />
                                <Route exact path="/editcampaignmanagement" element={<EditCampaignManagement />} />
                                <Route exact path="/editcardinstallmentplan" element={<EditCardInstallmentPlan />} />
                                <Route exact path="/editcardinstallmentplanchecker" element={<EditCardInstallmentPlanChecker />} />
                                <Route exact path="/editcardpricingprofile" element={<EditCardPricingProfile />} />
                                <Route exact path="/editcardpricingprofilechecker" element={<EditCardPricingProfileChecker />} />
                                <Route exact path="/editcategory" element={<EditCategory />} />
                                <Route exact path="/editchannel" element={<ChannelEdit />} />
                                <Route exact path="/editchartofaccounts" element={<EditChartOfAccounts />} />
                                <Route exact path="/editcheckeraccountdetails" element={<AccountDetailsCheckerEdit />} />
                                <Route exact path="/editcheckervirtualcard" element={<CheckerVirtualCardEdit />} />
                                <Route exact path="/editvirtualcarddetails" element={<VirtualCardEditDetails />} />
                                <Route exact path="/viewvirtualcarddetails" element={<VirtualCardEditDetails />} />
                                <Route exact path="/editcheckerwalletaccountapproval" element={<CheckerEditUav />} />
                                <Route exact path="/editcommissionhierarchy" element={<EditCommissionHierarchy />} />
                                <Route exact path="/editcompany" element={<EditCompany />} />
                                <Route exact path="/editcompanychecker" element={<EditCompanyChecker />} />
                                <Route exact path="/editcostcenter" element={<EditCostCenter />} />
                                <Route exact path="/editcreateregion" element={<EditCreateReg />} />
                                <Route exact path="/editcreditscoringengine" element={<EditCreditScoringEngine />} />
                                <Route exact path="/editcreditscoringenginechecker" element={<EditCreditScoringEngineChecker />} />
                                <Route exact path="/editcrpmodel" element={<EditCRPModel />} />
                                <Route exact path="/editcrpmodelchecker" element={<EditCRPModelChecker />} />
                                <Route exact path="/editcurrency" element={<EditCurrency />} />
                                <Route exact path="/editcustomerloanprofile" element={<EditCustomerLoanProfile />} />
                                <Route exact path="/editcustomerloanprofilechecker" element={<EditCustomerLoanProfileChecker />} />
                                <Route exact path="/editcustomerriskprofile" element={<EditCustomerRiskProfile />} />
                                <Route exact path="/editcxRolemanagement" element={<EditCxRoleMgmt />} />
                                <Route exact path="/editcxfieldmanagement" element={<EditCxFieldMgmt />} />
                                <Route exact path="/editdebitcard" element={<EditDebitCard />} />
                                <Route exact path="/editdebitcardchecker" element={<EditDebitCardChecker />} />
                                <Route exact path="/editdisableSms" element={<EditDisableSms />} />
                                <Route exact path="/editdmomerchant" element={<EditDmoMerchant />} />
                                <Route exact path="/editesb" element={<EditEnterpriseServiceBus />} />
                                <Route exact path="/editesbchecker" element={<EditEnterpriseServiceBusChecker />} />
                                <Route exact path="/editfixedcomchecker" element={<EditFixedCommissionChecker />} />
                                <Route exact path="/editfixedcommission" element={<EditFixedCom />} />
                                <Route exact path="/editgeneralledgerchecker" element={<EditGeneralLedgerChecker />} />
                                <Route exact path="/editinformationsplash" element={<EditInformationSplash />} />
                                <Route exact path="/editinformationsplashchecker" element={<EditInformationSplashChecker />} />
                                <Route exact path="/editinputsplash" element={<EditInputSplash />} />
                                <Route exact path="/editinputsplashchecker" element={<EditInputSplashChecker />} />
                                <Route exact path="/editkycattributeschecker" element={<EditKycAttributesChecker />} />
                                <Route exact path="/editkycsetschecker" element={<EditKycSetsChecker />} />
                                <Route exact path="/editlendingmanagement" element={<EditLendingManagement />} />
                                <Route exact path="/editlendingmanagementchecker" element={<EditLendingManagementChecker />} />
                                <Route exact path="/editlocation" element={<EditLocation />} />
                                <Route exact path="/editloginset" element={<EditLoginSet />} />
                                <Route exact path="/editloginsetchecker" element={<EditLoginSetChecker />} />
                                <Route exact path="/editmaincategory" element={<EditMainCategory />} />
                                <Route exact path="/editmaincategorychecker" element={<EditMainCategoryChecker />} />
                                <Route exact path="/editmanagemobilenumbers" element={<EditManageMobileNumbers />} />
                                <Route exact path="/editmanagemobilenumberschecker" element={<EditManageMobileNumbersChecker />} />
                                <Route exact path="/editmanageuploadfile" element={<EditManageUpload />} />
                                <Route exact path="/editmanageuploadfilechecker" element={<EditManageUploadChecker />} />
                                <Route exact path="/editmenuicon" element={<EditMenuIcon />} />
                                <Route exact path="/editmenuiconchecker" element={<EditMenuIconChecker />} />
                                <Route exact path="/editmerchant" element={<DmoMerchantEditChecker />} />
                                <Route exact path="/editmobilebundlecategories" element={<EditMobileBundleCategories />} />
                                <Route exact path="/editmobilebundlecategorieschecker" element={<EditMobileBundleCategoriesChecker />} />
                                <Route exact path="/editmrpcode" element={<EditMprCode />} />
                                <Route exact path="/editnotifications" element={<EditNotifications />} />
                                <Route exact path="/editnotificationschecker" element={<EditNotificationsChecker />} />
                                <Route exact path="/editorganization" element={<EditOrganization />} />
                                <Route exact path="/editparser" element={<EditParser />} />
                                <Route exact path="/editparserchecker" element={<EditParserChecker />} />
                                <Route exact path="/editpercentageoftransactionamount" element={<EditPercentageOfTrxAmount />} />
                                <Route exact path="/editpercentageoftransactionamountchecker" element={<EditPercentageOfTrxChecker />} />
                                <Route exact path="/editzmilesPointFactor" element={<PointFactorChecker />} />
                                <Route exact path="/editpricingconditionalfee" element={<EditPricingConditionalFee />} />
                                <Route exact path="/editpricingfixed" element={<EditPricingFixed />} />
                                <Route exact path="/editpricingpercentageoftransactionamount" element={<EditPricingTxtAmount />} />
                                <Route exact path="/editpricingslabwisefixed" element={<EditPricingSlabWiseFixed />} />
                                <Route exact path="/editpricingslabwisehybrid" element={<EditPricingSlabWiseHybrid />} />
                                <Route exact path="/editpricingslabwisepercentage" element={<EditPricingSlabWisePercentage />} />
                                <Route exact path="/editproduct" element={<EditProduct />} />
                                <Route exact path="/editproductcatalog" element={<ProductCatalogEdit />} />
                                <Route exact path="/editproductchecker" element={<EditProductChecker />} />
                                <Route exact path="/editpromotion" element={<EditPromotion />} />
                                <Route exact path="/editpromotionchecker" element={<EditPromotionChecker />} />
                                <Route exact path="/editredirectsplash" element={<EditRedirectSplash />} />
                                <Route exact path="/editredirectsplashchecker" element={<EditRedirectSplashChecker />} />
                                <Route exact path="/editregimelevelchecker" element={<EditRegimeLevelChecker />} />
                                <Route exact path="/editrulefield" element={<EditDynamicRule />} />
                                <Route exact path="/editsalesforce" element={<EditSalesForce />} />
                                <Route exact path="/editsalesforcechecker" element={<EditSalesForceChecker />} />
                                <Route exact path="/editsalesheirarchychecker" element={<EditSalesHierarchyChecker />} />
                                <Route exact path="/editsaleshierarchy" element={<EditSalesHierarchy />} />
                                <Route exact path="/editsalesregionchecker" element={<EditSalesRegionChecker />} />
                                <Route exact path="/editsalesroles" element={<EditSalesRoles />} />
                                <Route exact path="/editsalesroleschecker" element={<EditSalesRolesChecker />} />
                                <Route exact path="/editsecondsubcategory" element={<EditSecondSubCategory />} />
                                <Route exact path="/editsecondsubcategorychecker" element={<EditSecondSubCategoryChecker />} />
                                <Route exact path="/editsegment" element={<EditSegments />} />
                                <Route exact path="/editsegmentchecker" element={<EditCheckerSegment />} />
                                <Route exact path="/editslabwisefixed" element={<EditSlabWiseFixed />} />
                                <Route exact path="/editslabwisefixedchecker" element={<EditSlabWiseFixedChecker />} />
                                <Route exact path="/editslabwisehybridchecker" element={<EditSlabWiseHybridChecker />} />
                                <Route exact path="/editslabwisehybridfixedandpercentage" element={<EditSlabWiseHybridFixPer />} />
                                <Route exact path="/editslabwisepercentage" element={<EditSlabWisePercentage />} />
                                <Route exact path="/editslabwisepercentagechecker" element={<EditSlabWisePercentageChecker />} />
                                <Route exact path="/editsubcategory" element={<EditSubCategory />} />
                                <Route exact path="/editsubcategorychecker" element={<EditSubCategoryChecker />} />
                                <Route exact path="/editsubcategorycoa" element={<EditSubCategoryCoa />} />
                                <Route exact path="/editswitchconfiguration" element={<EditViewSwitchConfiguration />} />
                                <Route exact path="/editswitchconfigurationchecker" element={<SwitchConfigurationCheckerEdit />} />
                                <Route exact path="/editt24accountlink" element={<EditT24accountLink />} />
                                <Route exact path="/edittaxprofile" element={<EditTaxProfile />} />
                                <Route exact path="/edittaxprofilechecker" element={<EditTaxProfileChecker />} />
                                <Route exact path="/edittaxtypechecker" element={<EditTaxTypeChecker />} />
                                <Route exact path="/edittheme" element={<EditTheme />} />
                                <Route exact path="/editthemechecker" element={<EditThemeChecker />} />
                                <Route exact path="/edittickerchecker" element={<EditTickerChecker />} />
                                <Route exact path="/editzmilesTier" element={<TierChecker />} />
                                <Route exact path="/editzmilesTierRange" element={<TierRangeChecker />} />
                                <Route exact path="/edittransactions" element={<EditTransactionConfiguration />} />
                                <Route exact path="/edittransactionschecker" element={<EditTransactionConfigChecker />} />
                                <Route exact path="/edittransactionwiselimit" element={<EditTransactionwiseLimit />} />
                                <Route exact path="/edittransactionwiselimitchecker" element={<EditTransactionwiseLimitChecker />} />
                                <Route exact path="/edittypeofaccount" element={<EditTypeOfAccount />} />
                                <Route exact path="/edittypeofaccountchecker" element={<EditTypeOfAccountChecker />} />
                                <Route exact path="/editusecase" element={<EdituseCaseManagement />} />
                                <Route exact path="/edituserchecker" element={<EditUserChecker />} />
                                <Route exact path="/editusergroup" element={<EditUsergroup />} />
                                <Route exact path="/editusergroupchecker" element={<EditUsergroupChecker />} />
                                <Route exact path="/editusermanagement" element={<EditUserManagement />} />
                                <Route exact path="/editvalidatorchecker" element={<EditValidatorChecker />} />
                                {/* <Route exact path="/editvoucher/:id" element={<EditVoucher />} /> */}
                                {/* <Route exact path="/editvoucherchecker" element={<EditVoucherChecker />} /> */}
                                <Route exact path="/editwalletaccountapproval" element={<EditUav />} />
                                <Route exact path="/errormessages" element={<Error />} />
                                <Route exact path="/esb" element={<EnterpriseServiceBus />} />
                                <Route exact path="/feeconfiguration" element={<Fees />} />
                                <Route exact path="/feeshareconfiguration" element={<FeeShareConfiguration />} />
                                <Route exact path="/generalledger" element={<GeneralLedger />} />
                                <Route exact path="/getallusers" element={<Users />} />
                                <Route exact path="/kycattributes" element={<KycAttributes />} />
                                <Route exact path="/kycsets" element={<KycSets />} />
                                <Route exact path="/lendingmanagement" element={<LendingManagement />} />
                                <Route exact path="/loanaccountmanagement" element={<LoanAccountManagement />} />
                                <Route exact path="/loanoutstandingreport" element={<LoanOutstandingReport />} />
                                <Route exact path="/devicefinancing" element={<DeviceFinancing />} />
                                <Route exact path="/adddevicefinancing" element={<AddDeviceFinancing />} />
                                <Route exact path="/viewdevicefinancing" element={<ViewDeviceFinancing />} />
                                <Route exact path="/editdevicefinancingchecker" element={<EditLendingDeviceFinancingChecker />} />
                                <Route exact path="/viewdevicefinancingchecker" element={<ViewLendingDeviceFinancingChecker />} />

                                <Route exact path="/location" element={<Location />} />
                                <Route exact path="/loginset" element={<LoginSet />} />
                                <Route exact path="/maincategory" element={<MainCategory />} />
                                <Route exact path="/managecustomers" element={<ManageCustomers />} />
                                <Route exact path="/managemobilenumbers" element={<ManageMobileNumbers />} />
                                <Route exact path="/manageuploadfile" element={<ManageUploadFile />} />
                                <Route exact path="/menu" element={<Menu />} />
                                <Route exact path="/menuicon" element={<MenuIcon />} />
                                <Route exact path="/mobilebundlecategories" element={<MobileBundleCategories />} />
                                <Route exact path="/module" element={<Module />} />
                                <Route exact path="/mrpcode" element={<MrpCode />} />
                                <Route exact path="/nadra" element={<NadraScreen />} />
                                <Route exact path="/notifications" element={<Notifications />} />
                                <Route exact path="/organization" element={<Organization />} />
                                <Route exact path="/parser" element={<Parser />} />
                                <Route exact path="/parserhead" element={<ParserHead />} />
                                <Route exact path="/pointfactor" element={<PointFactor />} />
                                <Route exact path="/pointredemption" element={<PointRedemption />} />
                                <Route exact path="/pricing" element={<Pricing />} />
                                <Route exact path="/product" element={<Product />} />
                                <Route exact path="/productcatalog" element={<ProductCatalog />} />
                                <Route exact path="/productcatalogcheckeredit" element={<ProductCatalogCheckerEdit />} />
                                <Route exact path="/productcatalogcheckerview" element={<ProductCatalogCheckerView />} />
                                <Route exact path="/registrationsection" element={<RegistrationSection />} />
                                <Route exact path="/reporting" element={<Reporting />} />
                                <Route exact path="/reports" element={<Reports />} />
                                <Route exact path="/requestchecker" element={<RequestChecker />} />
                                <Route exact path="/retailerandcustomertransactionsection" element={<RetailerCustomerTransactionSection />} />
                                <Route exact path="/riskandbusinesssection" element={<RiskAndBusinessSection />} />
                                <Route exact path="/riskdashboard" element={<RiskDashboard />} />
                                <Route exact path="/riskdashboardcheckeredit" element={<RiskDashboardCheckerEdit />} />
                                <Route exact path="/riskdashboardcheckerview" element={<RiskDashboardCheckerView />} />
                                <Route exact path="/riskprofileedit" element={<EditCustomerRiskProfileChecker />} />
                                <Route exact path="/riskprofileview" element={<ViewCustomerRiskProfileChecker />} />
                                <Route exact path="/rolerights" element={<RoleRights />} />
                                <Route exact path="/roles" element={<Roles />} />
                                <Route exact path="/rulefield" element={<DynamicRule />} />
                                <Route exact path="/rulesEngine" element={<RuleEngine />} />
                                <Route exact path="/secondsubcategory" element={<SecondSubCategory />} />
                                <Route exact path="/segment" element={<Segments />} />
                                <Route exact path="/sms" element={<Sms />} />
                                <Route exact path="/subcategory" element={<SubCategory />} />
                                <Route exact path="/subcategorycoa" element={<SubCategoryCoa />} />
                                <Route exact path="/switchconfiguration" element={<SwitchConfiguration />} />
                                <Route exact path="/saf" element={<SAF />} />
                                <Route exact path="/t24accountlink" element={<T24accountLink />} />
                                <Route exact path="/taxprofilemaintenance" element={<TaxProfile />} />
                                <Route exact path="/theme" element={<Themee />} />
                                <Route exact path="/tier" element={<Tier />} />
                                <Route exact path="/tierrange" element={<TierRange />} />
                                <Route exact path="/transactionReversal" element={<TransactionReversal />} />
                                <Route exact path="/transactionlimitvalidator" element={<TransactionLimitValidator />} />
                                <Route exact path="/transactions" element={<TransactionConfiguration />} />
                                <Route exact path="/transactionwiselimit" element={<TransactionWiseLimit />} />
                                <Route exact path="/typeofaccount" element={<TypeOfAccount />} />
                                <Route exact path="/uploadmobilebundle" element={<UploadMobileBundle />} />
                                <Route exact path="/uploadparserhead" element={<UploadParserHead />} />
                                <Route exact path="/usecaseManagement" element={<UseCaseManagement />} />
                                <Route exact path="/usergroup" element={<UserGroup />} />
                                <Route exact path="/usermanagement" element={<UserManagement />} />
                                <Route exact path="/validators" element={<Validators />} />
                                <Route exact path="/viewBlacklistMarkingBulkSummary" element={<BlacklistMarkingBulkSummary />} />
                                <Route exact path="/viewCheckerBranch" element={<ViewCheckerLocation />} />
                                <Route exact path="/viewCheckerCategory" element={<ViewCheckerCategory />} />
                                <Route exact path="/viewCheckerChartOfAccounts" element={<ViewCheckerChartOfAccounts />} />
                                <Route exact path="/viewCheckerCostCenter" element={<ViewCheckerCostCenter />} />
                                <Route exact path="/viewCheckerCurrency" element={<ViewCheckerCurrency />} />
                                <Route exact path="/viewCheckerMrpCode" element={<ViewCheckerMprCode />} />
                                <Route exact path="/viewCheckerOrganization" element={<ViewCheckerOrganization />} />
                                <Route exact path="/viewCheckerRuleDomain" element={<ViewRuleDomainsChecker />} />
                                <Route exact path="/viewCheckerRuleEngine" element={<ViewCheckerRuleEngine />} />
                                <Route exact path="/viewCheckerSubCategory" element={<ViewCheckerSubCategoryCoa />} />
                                <Route exact path="/viewCnicUpdate" element={<ViewCnicUpdate />} />
                                <Route exact path="/viewCustomerAcNature" element={<ViewCustomerAcNature />} />
                                <Route exact path="/viewDomain" element={<ViewRuleDomains />} />
                                <Route exact path="/viewEmailUpdate" element={<ViewEmailUpdate />} />
                                <Route exact path="/viewError" element={<ViewError />} />
                                <Route exact path="/viewErrorchecker" element={<ViewErrorChecker />} />
                                <Route exact path="/viewMobileBundleChecker" element={<ViewMobileBundleChecker />} />
                                <Route exact path="/viewNadra" element={<ViewNadra />} />
                                <Route exact path="/viewNadraChecker" element={<ViewNadraChecker />} />
                                <Route exact path="/viewOtp" element={<ViewOtp />} />
                                <Route exact path="/viewOtpChecker" element={<ViewOtpChecker />} />
                                <Route exact path="/viewPmd" element={<ViewPmd />} />
                                <Route exact path="/viewPreviewAcOpening" element={<ViewAcOpeningPreview />} />
                                <Route exact path="/viewPreviewFilerNonFilerBulk" element={<ViewFilerNonFilerPreview />} />
                                <Route exact path="/viewPricingConditionalFeeChecker" element={<ViewPricingConditionalFeeChecker />} />
                                <Route exact path="/viewPricingFixedChecker" element={<ViewPricingFixedChecker />} />
                                <Route exact path="/viewPricingSlabWiseFixedChecker" element={<ViewPricingSlabWiseFixedChecker />} />
                                <Route exact path="/viewPricingSlabWiseHybridChecker" element={<ViewPricingSlabWiseHybridChecker />} />
                                <Route exact path="/viewPricingSlabWisePercentageChecker" element={<ViewPricingSlabWisePercentageChecker />} />
                                <Route exact path="/viewPricingTxtAmountChecker" element={<ViewPricingTxtAmountChecker />} />
                                <Route exact path="/viewRuleEngine" element={<ViewRuleEngine />} />
                                <Route exact path="/viewSms" element={<ViewSms />} />
                                <Route exact path="/viewSmschecker" element={<ViewSmsChecker />} />
                                <Route exact path="/viewTicker" element={<ViewTicker />} />
                                <Route exact path="/viewTransactionReversal" element={<ViewTransactionReversal />} />
                                <Route exact path="/viewValidateSegment" element={<ViewValidateSegment />} />
                                <Route exact path="/viewaccountdetails" element={<AccountDetails />} />
                                {/* ManageTransactions */}
                                <Route exact path="/viewaccounttransactiondetails" element={<ManageTransactions />} />
                                <Route exact path="/viewaccountonboarding" element={<ViewAgentOnboarding />} />
                                {/* <Route exact path="/viewaccountstatement" element={<ViewAccountStatement />} /> */}
                                <Route exact path="/viewactiveloan" element={<ViewActiveLoan />} />
                                <Route exact path="/viewactiveloanchecker" element={<ViewActiveLoanchecker />} />
                                <Route exact path="/viewagentaccountmaintenance" element={<AgentAccountMaintenanceView />} />
                                <Route exact path="/viewagentmatebank" element={<ViewAgentMateBank />} />
                                <Route exact path="/viewagentmatebankchecker" element={<ViewAgentMateBankChecker />} />
                                <Route exact path="/viewagentmatecompany" element={<ViewAgentMateCompany />} />
                                <Route exact path="/viewagentmatecompanychecker" element={<ViewAgentMateCompanyChecker />} />
                                <Route exact path="/viewagentmatemaincategory" element={<ViewAgentMateMainCategory />} />
                                <Route exact path="/viewagentmatemaincategorychecker" element={<ViewAgentMateMainCategoryChecker />} />
                                <Route exact path="/viewagentmateproduct" element={<ViewAgentMateProduct />} />
                                <Route exact path="/viewagentmateproductchecker" element={<ViewAgentMateProductChecker />} />
                                <Route exact path="/viewagentmatesubcategory" element={<ViewAgentMateSubCategory />} />
                                <Route exact path="/viewagentmatesubcategorychecker" element={<ViewAgentMateSubCategoryChecker />} />
                                <Route exact path="/viewaggregator" element={<ViewAggregator />} />
                                <Route exact path="/viewaggregatorchecker" element={<ViewAggregatorChecker />} />
                                <Route exact path="/viewaggregatorconfiguration" element={<ViewAggregatorConfiguration />} />
                                <Route exact path="/viewaggregatorconfigurationchecker" element={<ViewAggregatorConfigurationChecker />} />
                                <Route exact path="/viewbank" element={<ViewBank />} />
                                <Route exact path="/viewbankchecker" element={<ViewBankChecker />} />
                                <Route exact path="/viewbanner" element={<ViewBanner />} />
                                <Route exact path="/viewbannerchecker" element={<ViewBannerCheckerMain />} />
                                <Route exact path="/viewbannercheckerr" element={<ViewBannerChecker />} />
                                <Route exact path="/viewzmilesBaseFactor" element={<ViewBaseFactorChecker />} />
                                <Route exact path="/viewbiller" element={<ViewBiller />} />
                                <Route exact path="/viewcampaignchecker" element={<ViewCampignManagementChecker />} />
                                <Route exact path="/viewcampaignmanagement" element={<ViewCampaignManagement />} />
                                <Route exact path="/viewcardinstallmentplan" element={<ViewCardInstallmentPlan />} />
                                <Route exact path="/viewcardinstallmentplanchecker" element={<ViewCardInstallmentPlanChecker />} />
                                <Route exact path="/viewcardpricingprofile" element={<ViewCardPricingProfile />} />
                                <Route exact path="/viewcardpricingprofilechecker" element={<ViewCardPricingProfileChecker />} />
                                <Route exact path="/viewcategory" element={<ViewCategory />} />
                                <Route exact path="/viewchannel" element={<ChannelView />} />
                                <Route exact path="/viewchartofaccounts" element={<ViewChartOfAccounts />} />
                                <Route exact path="/viewcheckeraccountdetails" element={<AccountDetailsCheckerView />} />
                                <Route exact path="/viewcheckervirtualcard" element={<CheckerVirtualCardView />} />
                                <Route exact path="/viewcheckerwalletaccountapproval" element={<CheckerViewUav />} />
                                <Route exact path="/viewcommissionhierarchy" element={<ViewCommissionHierarchy />} />
                                <Route exact path="/viewcompany" element={<ViewCompany />} />
                                <Route exact path="/viewcompanychecker" element={<ViewCompanyChecker />} />
                                <Route exact path="/viewcostcenter" element={<ViewCostCenter />} />
                                <Route exact path="/viewcreateregion" element={<ViewCreateReg />} />
                                <Route exact path="/viewcreditscoringengine" element={<ViewCreditScoringEngine />} />
                                <Route exact path="/viewcreditscoringenginechecker" element={<ViewCreditScoringEngineChecker />} />
                                <Route exact path="/viewcrpmodel" element={<ViewCRPModel />} />
                                <Route exact path="/viewcrpmodelchecker" element={<ViewCRPModelChecker />} />
                                <Route exact path="/viewcurrency" element={<ViewCurrency />} />
                                <Route exact path="/viewcustomerdetails" element={<ViewCustomerDetails />} />
                                <Route exact path="/viewcustomerloanprofile" element={<ViewCustomerLoanProfile />} />
                                <Route exact path="/viewcustomerloanprofilechecker" element={<ViewCustomerLoanProfileChecker />} />
                                <Route exact path="/viewcustomerriskprofile" element={<ViewCustomerRiskProfile />} />
                                <Route exact path="/viewcxRolemanagement" element={<ViewCxRoleMgmt />} />
                                <Route exact path="/viewcxfieldmanagement" element={<ViewCxFieldMgmt />} />
                                <Route exact path="/viewdebitcard" element={<ViewDebitCard />} />
                                <Route exact path="/viewdebitcardchecker" element={<ViewDebitCardChecker />} />
                                <Route exact path="/viewdisableSms" element={<ViewDisableSms />} />
                                <Route exact path="/viewdmomerchant" element={<ViewDmoMerchant />} />
                                <Route exact path="/viewesb" element={<ViewEnterpriseServiceBus />} />
                                <Route exact path="/viewesbchecker" element={<ViewEnterpriseServiceBusChecker />} />
                                <Route exact path="/viewfixedcomchecker" element={<ViewFixedCommissionChecker />} />
                                <Route exact path="/viewfixedcommission" element={<ViewFixedCom />} />
                                <Route exact path="/viewgeneralledgerchecker" element={<ViewGeneralLedgerChecker />} />
                                <Route exact path="/viewinformationsplash" element={<ViewInformationSplash />} />
                                <Route exact path="/viewinformationsplashchecker" element={<ViewInformationSplashChecker />} />
                                <Route exact path="/viewinputsplash" element={<ViewInputSplash />} />
                                <Route exact path="/viewinputsplashchecker" element={<ViewInputSplashChecker />} />
                                <Route exact path="/viewkycattributeschecker" element={<ViewKycAttributesChecker />} />
                                <Route exact path="/viewkycsetschecker" element={<ViewKycSetsChecker />} />
                                <Route exact path="/viewlendingmanagement" element={<ViewLendingManagement />} />
                                <Route exact path="/viewlendingmanagementchecker" element={<ViewLendingManagementChecker />} />
                                <Route exact path="/viewloanaccount" element={<ViewLoanAccount />} />
                                <Route exact path="/viewloanaccountchecker" element={<ViewLoanAccountChecker />} />
                                <Route exact path="/viewloanbook" element={<ViewLoanBook />} />
                                <Route exact path="/viewlocation" element={<ViewLocation />} />
                                <Route exact path="/viewloginset" element={<ViewLoginSet />} />
                                <Route exact path="/viewloginsetchecker" element={<ViewLoginSetChecker />} />
                                <Route exact path="/viewmaincategory" element={<ViewMainCategory />} />
                                <Route exact path="/viewmaincategorychecker" element={<ViewMainCategoryChecker />} />
                                <Route exact path="/viewmanagecustomers" element={<ViewManageCustomers />} />
                                <Route exact path="/viewmanagemobilenumbers" element={<ViewManageMobileNumbers />} />
                                <Route exact path="/viewmanagemobilenumberschecker" element={<ViewManageMobileNumbersChecker />} />
                                <Route exact path="/viewmanageuploadfile" element={<ViewManageUpload />} />
                                <Route exact path="/viewmanageuploadfilechecker" element={<ViewManageUploadChecker />} />
                                <Route exact path="/viewmenuicon" element={<ViewMenuIcon />} />
                                <Route exact path="/viewmenuiconchecker" element={<ViewMenuIconChecker />} />
                                <Route exact path="/viewmerchant" element={<DmoMerchantViewChecker />} />
                                <Route exact path="/viewmobilebundlecategories" element={<ViewMobileBundleCategories />} />
                                <Route exact path="/viewmobilebundlecategorieschecker" element={<ViewMobileBundleCategoriesChecker />} />
                                <Route exact path="/viewmrpcode" element={<ViewMprCode />} />
                                <Route exact path="/viewnotifications" element={<ViewNotifications />} />
                                <Route exact path="/viewnotificationschecker" element={<ViewNotificationsChecker />} />
                                <Route exact path="/vieworganization" element={<ViewOrganization />} />
                                <Route exact path="/viewparser" element={<ViewParser />} />
                                <Route exact path="/viewparserchecker" element={<ViewParserChecker />} />
                                <Route exact path="/viewpercentageoftransactionamount" element={<ViewPercentageOfTrxAmount />} />
                                <Route exact path="/viewpercentageoftransactionamountchecker" element={<ViewPercentageOfTrxChecker />} />
                                <Route exact path="/viewzmilesPointFactor" element={<ViewPointFactorChecker />} />
                                <Route exact path="/viewpricingconditionalfee" element={<ViewPricingConditionalFee />} />
                                <Route exact path="/viewpricingfixed" element={<ViewPricingFixed />} />
                                <Route exact path="/viewpricingpercentageoftransactionamount" element={<ViewPricingTxtAmount />} />
                                <Route exact path="/viewpricingslabwisefixed" element={<ViewPricingSlabWiseFixed />} />
                                <Route exact path="/viewpricingslabwisehybrid" element={<ViewPricingSlabWiseHybrid />} />
                                <Route exact path="/viewpricingslabwisepercentage" element={<ViewPricingSlabWisePercentage />} />
                                <Route exact path="/viewproduct" element={<ViewProduct />} />
                                <Route exact path="/viewproductcatalog" element={<ProductCatalogView />} />
                                <Route exact path="/viewproductchecker" element={<ViewProductChecker />} />
                                <Route exact path="/viewpromotion" element={<ViewPromotion />} />
                                <Route exact path="/viewpromotionchecker" element={<ViewPromotionChecker />} />
                                <Route exact path="/viewredirectsplash" element={<ViewRedirectSplash />} />
                                <Route exact path="/viewredirectsplashchecker" element={<ViewRedirectSplashChecker />} />
                                <Route exact path="/viewregimelevelchecker" element={<ViewRegimeLevelChecker />} />
                                <Route exact path="/viewreporting" element={<ViewReporting />} />
                                <Route exact path="/viewrulefield" element={<ViewDynamicRule />} />
                                <Route exact path="/viewsalesforce" element={<ViewSalesForce />} />
                                <Route exact path="/viewsalesforcechecker" element={<ViewSalesForceChecker />} />
                                <Route exact path="/viewsalesheirarchychecker" element={<ViewSalesHierarchyChecker />} />
                                <Route exact path="/viewsaleshierarchy" element={<ViewSalesHierarchy />} />
                                <Route exact path="/viewsalesregionchecker" element={<ViewSalesRegionChecker />} />
                                <Route exact path="/viewsalesroles" element={<ViewSalesRoles />} />
                                <Route exact path="/viewsalesroleschecker" element={<ViewSalesRolesChecker />} />
                                <Route exact path="/viewsecondsubcategory" element={<ViewSecondSubCategory />} />
                                <Route exact path="/viewsecondsubcategorychecker" element={<ViewSecondSubCategoryChecker />} />
                                <Route exact path="/viewsegment" element={<ViewSegment />} />
                                <Route exact path="/viewsegmentchecker" element={<ViewCheckerSegment />} />
                                <Route exact path="/viewslabwisefixed" element={<ViewSlabWiseFixed />} />
                                <Route exact path="/viewslabwisefixedchecker" element={<ViewSlabWiseFixedChecker />} />
                                <Route exact path="/viewslabwisehybridchecker" element={<ViewSlabWiseHybridChecker />} />
                                <Route exact path="/viewslabwisehybridfixedandpercentage" element={<ViewSlabWiseHybridFixPer />} />
                                <Route exact path="/viewslabwisepercentage" element={<ViewSlabWisePercentage />} />
                                <Route exact path="/viewslabwisepercentagechecker" element={<ViewSlabWisePercentageChecker />} />
                                <Route exact path="/viewsubcategory" element={<ViewSubCategory />} />
                                <Route exact path="/viewsubcategorychecker" element={<ViewSubCategoryChecker />} />
                                <Route exact path="/viewsubcategorycoa" element={<ViewSubCategoryCoa />} />
                                <Route exact path="/viewswitchconfiguration" element={<ViewSwitchConfiguration />} />
                                <Route exact path="/viewswitchconfigurationchecker" element={<SwitchConfigurationCheckerView />} />
                                <Route exact path="/viewt24accountlink" element={<ViewT24accountLink />} />
                                <Route exact path="/viewtaxprofile" element={<ViewTaxProfile />} />
                                <Route exact path="/viewtaxprofilechecker" element={<ViewTaxProfileChecker />} />
                                <Route exact path="/viewtaxtypechecker" element={<ViewTaxTypeChecker />} />
                                <Route exact path="/viewtheme" element={<ViewTheme />} />
                                <Route exact path="/viewthemechecker" element={<ViewThemeChecker />} />
                                <Route exact path="/viewtickerchecker" element={<ViewTickerChecker />} />
                                <Route exact path="/viewzmilesTier" element={<ViewTierChecker />} />
                                <Route exact path="/viewzmilesTierRange" element={<ViewTierRangeChecker />} />
                                <Route exact path="/viewtransactionconfiguration" element={<ViewTransactionConfiguration />} />
                                <Route exact path="/viewtransactions" element={<ViewTransactions />} />
                                <Route exact path="/viewtransactionschecker" element={<ViewTransactionConfigChecker />} />
                                <Route exact path="/viewtransactionwiselimit" element={<ViewTransactionwiseLimit />} />
                                <Route exact path="/viewtransactionwiselimitchecker" element={<ViewTransactionwiseLimitChecker />} />
                                <Route exact path="/viewtypeofaccount" element={<ViewTypeOfAccount />} />
                                <Route exact path="/viewtypeofaccountchecker" element={<ViewTypeOfAccountChecker />} />
                                <Route exact path="/viewusecase" element={<ViewuseCaseManagement />} />
                                <Route exact path="/viewuserchecker" element={<ViewUserManageChecker />} />
                                <Route exact path="/viewusergroup" element={<ViewUsergroup />} />
                                <Route exact path="/viewusergroupchecker" element={<ViewUsergroupChecker />} />
                                <Route exact path="/viewusermanagement" element={<ViewUserManagement />} />
                                <Route exact path="/viewvalidatorchecker" element={<ViewValidatorChecker />} />
                                {/* <Route exact path="/viewvoucher/:id" element={<ViewVoucher />} /> */}
                                {/* <Route exact path="/viewvoucherchecker" element={<ViewVoucherChecker />} /> */}
                                <Route exact path="/viewwalletaccountapproval" element={<ViewUav />} />
                                {/* <Route exact path="/voucher" element={<Voucher />} /> */}
                                <Route exact path="/walletaccountapproval" element={<UltraAccountVerification />} />
                                <Route exact path="/editsettleloanchecker" element={<EditSettleLoanChecker />} />
                                <Route exact path="/viewsettleloanchecker" element={<ViewSettleLoanChecker />} />
                                <Route exact path="/editzmilesvoucher" element={<PointRedemptionChecker />} />
                                <Route exact path="/viewzmilesvoucher" element={<ViewPointRedemptionChecker />} />
                            </Routes>
                        </div>

                        {/* <AppFooter colorScheme={props.colorScheme} /> */}
                    </div>
                )}
                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />
                <AppConfig
                    configActive={configActive}
                    onConfigButtonClick={onConfigButtonClick}
                    onConfigClick={onConfigClick}
                    menuMode={menuMode}
                    changeMenuMode={onMenuModeChange}
                    colorScheme={props.colorScheme}
                    changeColorScheme={props.onColorSchemeChange}
                    theme={props.theme}
                    changeTheme={props.onMenuThemeChange}
                    componentTheme={props.componentTheme}
                    changeComponentTheme={props.onComponentThemeChange}
                    ripple={ripple}
                    onRippleChange={onRippleChange}
                />
            </LoadingOverlay>
        </div>
    );
};

export default App;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ViewSegment from '../AccountDetailsBasicDialogs/ViewSegment';
import ViewBvs from '../AccountDetailsBasicDialogs/ViewBvs';
import ViewAppVersion from '../AccountDetailsBasicDialogs/ViewAppVersion';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import DialogCloseAccount from '../AccountMaintenanceDialogs/DialogCloseAccount';
import DialogEmailDocToCus from '../AccountMaintenanceDialogs/DialogEmailDocToCus';
import DialogDLink from '../AccountMaintenanceDialogs/DialogDLink';
import DialogCloseAccountMobileNo from '../AccountMaintenanceDialogs/DialogCloseAccountMobileNo';
import DialogViewCustomerDoc from '../AccountMaintenanceDialogs/DialogViewCustomerDoc';
import AccountMaintenanceLogs from '../Components/AccountMaintenanceLogs';
import EditName from '../AccountDetailsBasicDialogs/EditName';
import EditAccountType from '../AccountDetailsBasicDialogs/EditAccountType';
import EditAccountStatus from '../AccountDetailsBasicDialogs/EditAccountStatus';
import EditAddress from '../AccountDetailsBasicDialogs/EditAddress';
import EditEmail from '../AccountDetailsBasicDialogs/EditEmail';
import EditCnicExpiry from '../AccountDetailsBasicDialogs/EditCnicExpiry';
import EditDiscrepantDocs from '../AccountDetailsBasicDialogs/EditDiscrepantDocs';
import EditPinGeneration from '../AccountDetailsBasicDialogs/EditPinGeneration';
import UltraFields from '../UltraAccountDetailsComponents/UltraFields';
import { handlePostFileRequest } from '../../../../services/PostFileTemplate';
import UltraFreeLanceFields from '../UltraFreeLance/UltraFreeLanceFields';
import HraAccountDetails from '../HraAccount/HraAccountDetails';
import HraLimits from '../HraAccount/Components/HraLimits';
import DebitCredit from '../HraAccount/Components/DebitCredit';
import Transaction from '../HraAccount/Components/Transaction';
import EditHraKinName from '../AccountDetailsBasicDialogs/EditHraKinName';
import EditHraCnic from '../AccountDetailsBasicDialogs/EditHraCnic';
import EditHraMobile from '../AccountDetailsBasicDialogs/EditHraMobile';
import EditHraRelationship from '../AccountDetailsBasicDialogs/EditHraRelationship';
import EditHraIntRemittanceLocation from '../AccountDetailsBasicDialogs/EditHraIntRemittanceLocation';
import EditHraRelationshipWithOriginator from '../AccountDetailsBasicDialogs/EditHraRelationshipWithOriginator';
import moment from 'moment';
import EditPetroPocket from '../Components/EditPetroPocket';
import axios from 'axios';
import { baseURL } from '../../../../Config';
import { toast } from 'react-toastify';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import EditBirthPlace from '../AccountDetailsBasicDialogs/EditBirthPlace';
import EditMotherName from '../AccountDetailsBasicDialogs/EditMotherName';
import DialogCustomerKycUpdate from '../AccountMaintenanceDialogs/DialogCustomerKycUpdate';
import DialogAccountDownGrade from '../AccountMaintenanceDialogs/DialogAccountDownGrade';
import { showMessageAction } from '../../../../redux/actions/showMessageAction';
import store from '../../../../redux/store';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const AccountDetailsEdit = () => {
    const tableName = 'TBL_CUSTOMER';

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [cnicFront, setCnicFront] = useState('');
    const [cnicBack, setCnicBack] = useState('');
    const [proofOfProfession, setProofOfProfession] = useState('');
    const [sourceOfIncome, setSourceOfIncome] = useState('');
    const [parentCnicFront, setParentCnicFront] = useState('');
    const [parentCnicBack, setParentCnicBack] = useState('');
    const [bForm, setBForm] = useState('');
    const [whiteListStatus, setWhiteListStatus] = useState('');
    const [isNameBlurRemoved, setIsNameBlurRemoved] = useState();
    const [isBirthPlaceBlurRemoved, setIsBirthPlaceBlurRemoved] = useState();
    const [isMotherNameBlurRemoved, setIsMotherNameBlurRemoved] = useState();
    const [isCnicBlurRemoved, setIsCnicBlurRemoved] = useState();
    const [isCnicExpBlurRemoved, setIsCnicExpBlurRemoved] = useState();
    const [isUpdateReq, setIsUpdateReq] = useState(false);

    const location = useLocation();
    const { rowData, lkpAccountLevelLov: accountTypeData, lkpAccountStatusLov: accountStatus, lkpCityLov: cityList, userTabsFieldData, type } = location.state || {};

    const IsLevel0 = rowData?.accountLevelName && rowData?.accountLevelName.toLowerCase().includes('level 0');
    const IsLevel1 = rowData?.accountLevelName && rowData?.accountLevelName.toLowerCase().includes('level 1');
    const IsMinorAccount = rowData?.accountLevelName && rowData?.accountLevelName.toLowerCase().includes('minor 0');
    const IsMinor1Account = rowData?.accountLevelName && rowData?.accountLevelName.toLowerCase().includes('minor 1');
    const IsUltraAccount = rowData?.accountLevelName && rowData?.accountLevelName.toLowerCase().includes('ultra basic');
    // const IsUltraFreelanceAccount = rowData?.accountLevelName && rowData?.accountLevelName.toLowerCase().includes('ultra freelance');
    const IsUltraFreelanceAccount = rowData?.freelanceAccount && rowData?.freelanceAccount.includes('Y');
    const IsUltraSignatureAccount = rowData?.accountLevelName && rowData?.accountLevelName.toLowerCase().includes('ultra signature');

    const nadraStatus = ['Yes', 'No'];
    const clsStatus = ['Yes', 'No'];
    const hraStatus = ['Yes', 'No'];
    const amlStatus = ['Approved', 'Pending'];
    const bvsStatus = ['Yes', 'No'];
    const relationshipOptions = ['Father', 'Mother', 'Brother', 'Sister', 'Other'];

    const pinGenerationLov = [
        // { label: "Ama", id: 1 },
        { label: 'App', id: 2 }
    ];

    //Editable Dialogs
    const [displayNameEdit, setDisplayNameEdit] = useState(false);
    const [displayBirthPlaceEdit, setDisplayBirthPlaceEdit] = useState(false);
    const [displayMotherNameEdit, setDisplayMotherNameEdit] = useState(false);
    const [displayAccountTypeEdit, setDisplayAccountTypeEdit] = useState(false);
    const [displayAccountStatusEdit, setDisplayAccountStatusEdit] = useState(false);
    const [displayAccountAddressEdit, setDisplayAccountAddressEdit] = useState(false);
    const [displayAccountEmailEdit, setDisplayAccountEmailEdit] = useState(false);
    const [displayAccountCnicExpiryDateEdit, setDisplayAccountCnicExpiryDateEdit] = useState(false);
    const [displayAccountDiscrepantDocsEdit, setDisplayAccountDiscrepantDocsEdit] = useState(false);
    const [displayAccountPinGenerationEdit, setDisplayAccountPinGenerationEdit] = useState(false);
    const [displayHraKinNameEdit, setDisplayHraKinNameEdit] = useState(false);
    const [displayHraCnicEdit, setDisplayHraCnicEdit] = useState(false);
    const [displayHraMobileEdit, setDisplayHraMobileEdit] = useState(false);
    const [displayHraRelationshipEdit, setDisplayHraRelationshipEdit] = useState(false);
    const [displayHraIntRemittanceLocationEdit, setDisplayHraIntRemittanceLocationEdit] = useState(false);
    const [displayHraRelationshipWithOriginatorEdit, setDisplayHraRelationshipWithOriginatorEdit] = useState(false);

    // Viewable Dialogs
    const [displaySegmentView, setDisplaySegmentView] = useState(false);
    const [displayBvsView, setDisplayBvsView] = useState(false);
    const [appVersionView, setAppVersionView] = useState(false);

    // ACCOUNT MAINTAINANCE DIALOGS
    const [displayModalCloseAccount, setDisplayModalCloseAccount] = useState(false);
    const [displayModalEmailDoc, setDisplayModalEmailDoc] = useState(false);
    const [displayModalDLINK, setDisplayModalDLINK] = useState(false);
    const [displayModalCloseAccountMobileNo, setDisplayModalCloseAccountMobileNo] = useState(false);
    const [displayModalViewCustomerDoc, setDisplayModalViewCustomerDoc] = useState(false);
    const [displayModalCustomrKyc, setDisplayModalCustomrKyc] = useState(false);
    const [displayModalAccDownGrade, setDisplayModalAccDownGrade] = useState(false);

    const [customerIdData, setCustomerIdData] = useState('');

    const onHideCloseAccount = () => {
        setDisplayModalCloseAccount(false);
    };

    const handledCloseAccount = (e) => {
        e.preventDefault();
        if (rowData && rowData?.actualBalance > 0) {
            confirmDialog({
                message: `Customer have Rs. ${rowData?.actualBalance} Amount in account. Do you really want to settle the account # ${rowData?.accountNo} having balance Rs. ${rowData?.actualBalance} to Test GL (Id:12345678)`,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    setDisplayModalCloseAccount(true);
                },
                reject: () => {
                    setDisplayModalCloseAccount(false);
                }
            });
        }
    };

    const onHideEmailDoc = () => {
        setDisplayModalEmailDoc(false);
    };

    const handledEmailDoc = (e) => {
        e.preventDefault();
        setDisplayModalEmailDoc(true);
    };

    const onHideDLINK = () => {
        setDisplayModalDLINK(false);
    };

    const handledDLINK = (e) => {
        e.preventDefault();
        setDisplayModalDLINK(true);
    };

    const onHideCloseAccountMobileNo = () => {
        setDisplayModalCloseAccountMobileNo(false);
    };

    const handledCloseAccountMobileNo = (e) => {
        e.preventDefault();
        setDisplayModalCloseAccountMobileNo(true);
    };

    const onHideViewCustomerDoc = () => {
        setDisplayModalViewCustomerDoc(false);
    };
    const onHideCustomerKycUpdate = () => {
        setDisplayModalCustomrKyc(false);
    };
    const onHideAccDownGrade = () => {
        setDisplayModalAccDownGrade(false);
    };

    const handledViewCustomerDoc = (e) => {
        e.preventDefault();
        setDisplayModalViewCustomerDoc(true);
    };

    const handledViewCustomrKyc = (e) => {
        e.preventDefault();
        setDisplayModalCustomrKyc(true);
    };
    const handledViewAccDownGrade = (e) => {
        e.preventDefault();
        setDisplayModalAccDownGrade(true);
    };

    //Validation Schema
    const validationSchema = Yup.object().shape({});

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            fullName: '',
            customerId: '',
            mobileNo: '',
            iban: '',
            accountTypeId: '',
            accountStatusId: '',
            remarks: '',
            fullAddress: '',
            email: '',
            cityId: '',
            nadraVerified: '',
            dob: '',
            cnic: '',
            cnicExpiryDate: '',
            segmentName: '',
            channelName: '',
            accountOpeningDate: '',
            appVersionName: '',
            levelChangeDate: '',
            ultraAcOpeningDate: '',
            discrepantDocs: '',
            clsVerified: '',
            actualBalance: '',
            hra: '',
            motherMaidenName: '',
            birthPlace: '',
            aml: '',
            pinGeneration: '',
            parentCnic: '',
            hraNokName: '',
            hraNokCnic: '',
            hraNokMobileNo: '',
            hraNokRelationshipName: '',
            ntn: '',
            hraOriginatorRelationshipName: ''
        },
        onSubmit: async (data) => {}
    });

    // Map RowData in fields
    const mapKeyValues = () => {
        if (rowData && rowData) {
            formik.setFieldValue('fullName', rowData?.fullName);
            formik.setFieldValue('customerId', rowData?.customerId);
            formik.setFieldValue('mobileNo', rowData?.mobileNo);
            formik.setFieldValue('iban', rowData?.iban);
            formik.setFieldValue('accountTypeId', rowData?.accountLevelId && rowData?.accountLevelId.toString());
            formik.setFieldValue('nadraVerified', rowData?.nadraVerified === 'Y' ? 'Yes' : rowData?.nadraVerified === 'N' ? 'No' : '');
            formik.setFieldValue('accountStatusId', rowData?.accountStatusId && rowData?.accountStatusId.toString());
            // Filter account status name to set in formik values
            formik.setFieldValue('accountStatusName', accountStatus && accountStatus.filter((item) => parseInt(item?.accountStatusId) === rowData?.accountStatusId)[0]?.accountStatusName);
            formik.setFieldValue('remarks', rowData?.remarks);
            formik.setFieldValue('fullAddress', rowData?.fullAddress);
            formik.setFieldValue('email', rowData?.email);
            formik.setFieldValue('cityId', rowData?.cityId && rowData?.cityId.toString());
            formik.setFieldValue('dob', rowData?.dob);
            formik.setFieldValue('cnic', rowData?.cnic);
            formik.setFieldValue('cnicExpiryDate', rowData?.cnicExpiryDate);
            formik.setFieldValue('segmentName', rowData?.segmentName);
            formik.setFieldValue('channelName', rowData?.channelName);
            formik.setFieldValue('accountOpeningDate', rowData?.accountOpeningDate && rowData?.accountOpeningDate.split(' ')[0]);
            formik.setFieldValue('appVersionName', rowData?.appVersionName);
            formik.setFieldValue('levelChangeDate', rowData?.levelChangeDate);
            formik.setFieldValue('ultraAcOpeningDate', rowData?.ultraAcOpeningDate && rowData?.ultraAcOpeningDate.split(' ')[0]);
            formik.setFieldValue('discrepantDocs', rowData?.discrepantDocs);
            formik.setFieldValue('clsVerified', rowData?.clsVerified === 'Y' ? 'Yes' : rowData?.clsVerified === 'N' ? 'No' : '');
            formik.setFieldValue('actualBalance', rowData?.actualBalance);
            formik.setFieldValue('hra', rowData?.hra === 'Y' ? 'Yes' : rowData?.hra === 'N' ? 'No' : '');
            formik.setFieldValue('motherMaidenName', rowData?.motherMaidenName);
            formik.setFieldValue('birthPlace', rowData?.birthPlace);
            formik.setFieldValue('aml', rowData?.aml === 'A' ? amlStatus[0] : rowData?.aml === 'P' ? amlStatus[1] : '');
            formik.setFieldValue('bvs', rowData?.bvs === 'Y' ? 'Yes' : rowData?.bvs === 'N' ? 'No' : '');
            formik.setFieldValue('pinGeneration', rowData?.pinGeneration);
            formik.setFieldValue('parentCnic', rowData?.parentCnic);
            setCustomerIdData(rowData?.customerId);
            formik.setFieldValue('hraNokName', rowData?.hraNokName);
            formik.setFieldValue('hraNokCnic', rowData?.hraNokCnic);
            formik.setFieldValue('hraNokMobileNo', rowData?.hraNokMobileNo);
            formik.setFieldValue('hraNokRelationshipName', rowData?.hraNokRelationshipName);
            formik.setFieldValue('ntn', rowData?.internationalRemittanceLocation);
            formik.setFieldValue('hraOriginatorRelationshipName', rowData?.hraOriginatorRelationshipName);
        } else {
        }
    };

    // const getBirthPlaceData = [{ label: birthPlaceData }];

    useEffect(() => {
        mapKeyValues();
    }, []);

    // Handle Editable Dialogs
    const handleNameEdit = () => {
        setDisplayNameEdit(true);
    };
    const handleBirthPlaceEdit = () => {
        setDisplayBirthPlaceEdit(true);
    };
    const handleMotherNameEdit = () => {
        setDisplayMotherNameEdit(true);
    };
    const handleAccountStatusEdit = () => {
        setDisplayAccountStatusEdit(true);
    };
    const handleAccountAddressEdit = () => {
        setDisplayAccountAddressEdit(true);
    };
    const handleAccountEmailEdit = () => {
        setDisplayAccountEmailEdit(true);
    };
    const handleAccountCnicExpiryDateEdit = () => {
        setDisplayAccountCnicExpiryDateEdit(true);
    };
    const handleAccountDiscrepantDocsEdit = () => {
        setDisplayAccountDiscrepantDocsEdit(true);
    };
    const handleAccountPinGenerationEdit = () => {
        setDisplayAccountPinGenerationEdit(true);
    };
    const handleHraKinNameEdit = () => {
        setDisplayHraKinNameEdit(true);
    };
    const handleHraCnicEdit = () => {
        setDisplayHraCnicEdit(true);
    };
    const handleHraMobileEdit = () => {
        setDisplayHraMobileEdit(true);
    };
    const handleHraRelationshipEdit = () => {
        setDisplayHraRelationshipEdit(true);
    };
    // const handleHraIntRemittanceLocationEdit = () => {
    //     setDisplayHraIntRemittanceLocationEdit(true);
    // };
    // const handleHraRelationshipWithOriginatorEdit = () => {
    //     setDisplayHraRelationshipWithOriginatorEdit(true);
    // };

    // Handle Viewable Dialogs
    const handleViewDialog = () => {
        setDisplaySegmentView(true);
    };
    const handleBvsView = () => {
        setDisplayBvsView(true);
    };
    const handleAppVersionView = () => {
        setAppVersionView(true);
    };

    // Hide dialog boxes on cancel
    const onHide = () => {
        // Hide Editable Dialogs
        setDisplayNameEdit(false);
        setDisplayBirthPlaceEdit(false);
        setDisplayMotherNameEdit(false);
        setDisplayAccountTypeEdit(false);
        setDisplayAccountStatusEdit(false);
        setDisplayAccountAddressEdit(false);
        setDisplayAccountEmailEdit(false);
        setDisplayAccountCnicExpiryDateEdit(false);
        setDisplayAccountDiscrepantDocsEdit(false);
        setDisplayAccountPinGenerationEdit(false);
        setDisplayHraKinNameEdit(false);
        setDisplayHraCnicEdit(false);
        setDisplayHraMobileEdit(false);
        setDisplayHraRelationshipEdit(false);
        setDisplayHraIntRemittanceLocationEdit(false);
        setDisplayHraRelationshipWithOriginatorEdit(false);

        // Hide Viewable Dialogs
        setDisplaySegmentView(false);
        setDisplayBvsView(false);
        setAppVersionView(false);
    };

    // Go back to the previous page
    const goBack = () => {
        navigate('/accountsmanagement', { state: { prevPath: '/editaccountdetails' } });
    };

    // Handle Submit
    const handleSubmit = async (e) => {
        const customerKyc = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    updateCustomerKyc: {
                        // Change check
                        fullName: e[0]?.fieldName === 'fullName' ? e[0]?.fullName : null,
                        birthPlace: e[0]?.fieldName === 'birthPlace' ? e[0]?.birthPlace : null,
                        motherMaidenName: e[0]?.fieldName === 'motherMaidenName' ? e[0]?.motherMaidenName : null,
                        accountStatusId: e[0]?.fieldName === 'accountStatusId' ? parseInt(e[0]?.accountStatusId) : null,
                        remarks: e[0]?.remarks,
                        fullAddress: e[0]?.fieldName === 'fullAddress' ? e[0]?.fullAddress : null,
                        email: e[0]?.fieldName === 'email' ? e[0]?.email : null,
                        cnicIssuanceDate: e[0]?.fieldName === 'cnicIssuanceDate' ? e[0]?.cnicIssuanceDate : null,
                        cnicFront: null,
                        cnicBack: null,
                        proofOfProfession: null,
                        sourceOfIncome: null,
                        parentCnicFront: null,
                        parentCnicBack: null,
                        bForm: null,
                        //---------------------------------------//
                        customerId: formik.values.customerId,
                        mobileNumber: null,
                        accountId: rowData?.accountId,
                        addressId: rowData?.addressId,
                        accountTypeId: parseInt(formik.values.accountTypeId),
                        // cnicExpiryDate: formik.values.cnicExpiryDate,
                        discrepentDocumentId: rowData?.channelId,
                        pinGenerationId: e[0]?.fieldName === 'pinGeneration' ? e[0]?.pinGeneration : null,
                        statusId: null,
                        isActive: null,
                        customerNumber: null,
                        hraNokName: e[0]?.fieldName === 'hraNokName' ? e[0]?.hraNokName : null,
                        hraNokCnic: e[0]?.fieldName === 'hraNokCnic' ? e[0]?.hraNokCnic : null,
                        hraNokMobileNo: e[0]?.fieldName === 'hraNokMobileNo' ? e[0]?.hraNokMobileNo : null,
                        hraNokRelationshipName: e[0]?.fieldName === 'hraNokRelationshipName' ? e[0]?.hraNokRelationshipName : null,
                        countryName: e[0]?.fieldName === 'countryName' ? e[0]?.countryName : null,
                        hraOriginatorRelationshipName: e[0]?.fieldName === 'hraOriginatorRelationshipName' ? e[0]?.hraOriginatorRelationshipName : null,
                        fieldName: e[0]?.fieldName ? e[0]?.fieldName : '',
                        whitelistedDevice: null,
                        segmentId: e[0]?.segmentId ? e[0]?.segmentId : '',
                        lienAmount: null,
                        productId: 0
                    }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        // return
        // if (isAddable) {
        //     customerKyc.data.payLoad.updateCustomerKyc['cnicExpiryDate'] = formik.values.cnicExpiryDate;
        // }
        // return
        await dispatch(handlePostRequest(customerKyc, '/account/v1/customeraccounts/updatecustomerkyc', true, false, 'accountsmanagement'));
        if (isUpdateReq) {
            handleLogSubmit(e);
        }
    };

    const handledLoginPin = async (e) => {
        e.preventDefault();
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    regenerateLoginPinRequest: {
                        customerId: customerIdData,
                        mobileNumber: rowData?.mobileNo
                    }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/updatecustomerkyc', true, false, 'accountsmanagement'));
        if (res?.responseCode === '010000') {
            // navigate("/accountsmanagement")
        }
    };
    const handledDownloadAccountForm = async (e) => {
        e.preventDefault();

        try {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        mobileNumber: rowData?.mobileNo
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const response = await dispatch(handlePostFileRequest(newData, '/account/v1/customeraccount/generatecustomerform', true, true));

            // Create a Blob from the stream data
            const blob = await new Response(response).blob(); // For fetch API

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = `report.pdf`; // Set the desired file name for the download

            // Append the link element to the DOM and trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up the URL and remove the link element
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            // Handle any errors that may occur during the API call
        }
    };
    const handleFullNameChange = (newValue) => {
        formik.setFieldValue('fullName', newValue?.fullName);
        setIsNameBlurRemoved(newValue?.isRemoved);
        if (newValue?.fullName !== formik.values.fullName) {
            setIsUpdateReq(true);
            formik.setFieldValue('updatedFullName', newValue?.fullName);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], fullName: newValue?.fullName }]);
        }
    };
    const handleBirthPlaceChange = (newValue) => {
        formik.setFieldValue('birthPlace', newValue?.birthPlace);
        setIsBirthPlaceBlurRemoved(newValue?.isRemoved);
        if (newValue?.birthPlace !== formik.values.birthPlace) {
            setIsUpdateReq(true);
            formik.setFieldValue('updatedBirthPlace', newValue?.birthPlace);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], birthPlace: newValue?.birthPlace }]);
        }
    };
    const handleMotherNameChange = (newValue) => {
        formik.setFieldValue('motherMaidenName', newValue?.motherMaidenName);
        setIsMotherNameBlurRemoved(newValue?.isRemoved);
        if (newValue?.motherMaidenName !== formik.values.motherMaidenName) {
            setIsUpdateReq(true);
            formik.setFieldValue('updatedMotherName', newValue?.motherMaidenName);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], motherMaidenName: newValue?.motherMaidenName }]);
        }
    };
    const handleAccountTypeChange = (newValue) => {
        formik.setFieldValue('accountTypeId', newValue);
    };
    const handleAccountStatusChange = (newValue) => {
        if (newValue?.accountStatusId !== formik.values.accountStatusId) {
            formik.setFieldValue('accountStatusId', newValue?.accountStatusId);
            formik.setFieldValue('accountStatusName', newValue?.accountStatusName);
            formik.setFieldValue('remarks', newValue?.remarks);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], accountStatusId: newValue?.accountStatusId, remarks: newValue?.remarks }]);
        }
    };
    const handleAccountAddressChange = (newValue) => {
        if (newValue?.fullAddress !== formik.values.fullAddress) {
            formik.setFieldValue('fullAddress', newValue?.fullAddress);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], fullAddress: newValue?.fullAddress }]);
        }
    };
    const handleAccountEmailChange = (newValue) => {
        if (newValue?.email !== formik.values.email) {
            formik.setFieldValue('email', newValue?.email);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], email: newValue?.email }]);
        }
    };
    const handleAccountCnicExpiryDateChange = (newValue) => {
        setIsCnicExpBlurRemoved(true);
        if (newValue?.cnicIssuanceDate !== rowData.cnicIssuanceDate) {
            setIsUpdateReq(true);
            formik.setFieldValue('cnicIssuanceDate', newValue?.cnicIssuanceDate);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], cnicIssuanceDate: newValue?.cnicIssuanceDate }]);
        }
    };
    const handleAccountDiscrepantDocsChange = (newValue) => {
        setCnicFront(newValue?.cnicFrontBase64 && newValue?.cnicFrontBase64);
        setCnicBack(newValue?.cnicBackBase64 && newValue?.cnicBackBase64);
        setProofOfProfession(newValue?.proofOfprofessionBase64 && newValue?.proofOfprofessionBase64);
        setSourceOfIncome(newValue?.sourceOfIncomeBase64 && newValue?.sourceOfIncomeBase64);
        setParentCnicFront(newValue?.parentCnicFrontBase64 && newValue?.parentCnicFrontBase64);
        setParentCnicBack(newValue?.parentCnicBackBase64 && newValue?.parentCnicBackBase64);
        setBForm(newValue?.bFormBase64 && newValue?.bFormBase64);
    };
    const handleAccountPinGenerationChange = (newValue) => {
        if (newValue?.pinGeneration !== formik.values.pinGeneration) {
            formik.setFieldValue('pinGeneration', newValue?.pinGeneration);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], pinGeneration: newValue?.pinGeneration }]);
        }
    };
    const handleWhiteListChange = (newValue) => {
        setWhiteListStatus(newValue?.whitelistedDevice);
        // handleSubmit([{ fieldName: Object.keys(newValue)[0], whitelistedDevice: newValue?.whitelistedDevice }]);
    };
    const handleHraKinNameChange = (newValue) => {
        if (newValue?.hraNokName !== formik.values.hraNokName) {
            formik.setFieldValue('hraNokName', newValue?.hraNokName);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], hraNokName: newValue?.hraNokName }]);
        }
    };
    const handleHraCnicChange = (newValue) => {
        if (newValue?.hraNokCnic !== formik.values.hraNokCnic) {
            formik.setFieldValue('hraNokCnic', newValue?.hraNokCnic);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], hraNokCnic: newValue?.hraNokCnic }]);
        }
    };
    const handleHraMobileChange = (newValue) => {
        if (newValue?.hraNokMobileNo !== formik.values.hraNokMobileNo) {
            formik.setFieldValue('hraNokMobileNo', newValue?.hraNokMobileNo);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], hraNokMobileNo: newValue?.hraNokMobileNo }]);
        }
    };
    const handleHraRelationshipChange = (newValue) => {
        if (newValue?.hraNokRelationshipName !== formik.values.hraNokRelationshipName) {
            formik.setFieldValue('hraNokRelationshipName', newValue?.hraNokRelationshipName);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], hraNokRelationshipName: newValue?.hraNokRelationshipName }]);
        }
    };
    const handleHraIntRemittanceLocationChange = (newValue) => {
        if (newValue?.ntn !== formik.values.ntn) {
            formik.setFieldValue('ntn', newValue?.countryName);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], ntn: newValue?.countryName }]);
        }
    };
    const handleHraRelationshipWithOriginatorChange = (newValue) => {
        if (newValue?.hraOriginatorRelationshipName !== formik.values.hraOriginatorRelationshipName) {
            formik.setFieldValue('hraOriginatorRelationshipName', newValue?.hraOriginatorRelationshipName);
            handleSubmit([{ fieldName: Object.keys(newValue)[0], hraOriginatorRelationshipName: newValue?.hraOriginatorRelationshipName }]);
        }
    };
    const handleSegmentNameChange = (newValue) => {
        if (parseInt(newValue) !== rowData?.segmentId) {
            setIsUpdateReq(true);
            handleSubmit([{ fieldName: 'segmentName', segmentId: parseInt(newValue) }]);
        }
    };

    // Split File Paths
    // const splitCnicFrontFileName = rowData?.cnicFrontPic ? rowData?.cnicFrontPic.split('/').pop() : '';
    // const splitCnicBackFileName = rowData?.cnicBankPic ? rowData?.cnicBankPic.split('/').pop() : '';
    // const splitProofOfIncomePicFileName = rowData?.proofOfIncomePic ? rowData?.proofOfIncomePic.split('/').pop() : '';

    const userDetails = sessionStorage.getItem('userDetails');
    const parsedUserDetails = JSON.parse(userDetails);
    const userId = parsedUserDetails?.userId;
    const userName = parsedUserDetails?.firstName + ' ' + parsedUserDetails?.lastName;

    // HANDLE LOGS SUBMIT
    const handleLogSubmit = async (e) => {
        const isBlur = e?.target?.classList.contains('blur');

        const fieldName = e.target.id;
        const fieldValue = e.target.value;

        const payloadService = {
            reqType: isUpdateReq ? 'Update' : 'View',
            userId: userId,
            userName: userName,
            accountNumber: rowData?.accountNo,
            ...(!isUpdateReq ? { [fieldName]: fieldValue } : null),
            ...(isUpdateReq ? { fullName: formik.values.updatedFullName } : null),
            ...(isUpdateReq ? { birthPlace: formik.values.updatedBirthPlace } : null),
            ...(isUpdateReq ? { motherMaidenName: formik.values.updatedMotherName } : null),
            ...(isUpdateReq ? { CnicExpiryDate: formik.values.updatedCnicExpiryDate } : null)
        };

        if (isBlur || isUpdateReq) {
            try {
                const data = {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    payload: {
                        serviceName: rowData?.customerId,
                        endpoint: 'Cx-1',
                        dateTime: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
                        payloadService: JSON.stringify(payloadService),
                        message: 'CX-1 Logs',
                        loggingLevel: 'INFO',
                        packageName: 'abcservice.ais',
                        loggerID: '(abcservice--0.0.0-8009-3)'
                    },
                    indexName: 'cx'
                };

                // await dispatch(handlePostRequest(data, '/producer/kafka/logs/publish', true, true));
                const response = await axios({
                    method: 'post',
                    url: `${baseURL + '/producer/kafka/logs/publish'}`,
                    data: data,
                    headers: {
                        Authorization: sessionStorage.getItem('token')
                    }
                });
            } catch (error) {
                console.log('error', error);
            }
        }
    };

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData.find((f) => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === 'Y',
            updateAllowed: field?.updateAllowed === 'Y'
        };
    };

    const nameFieldPermissions = checkFieldPermissions('fullName');
    const customerIdFieldPermissions = checkFieldPermissions('customerId');
    const mobileFieldPermissions = checkFieldPermissions('mobileNo');
    const ibanFieldPermissions = checkFieldPermissions('iban');
    const accountNoFieldPermissions = checkFieldPermissions('accountNo');
    const accTypeFieldPermissions = checkFieldPermissions('accountTypeName');
    const nadraVeriFieldPermissions = checkFieldPermissions('nadraVerified');
    const acStatusFieldPermissions = checkFieldPermissions('accountStatusId');
    const accClosureDateFieldPermissions = checkFieldPermissions('accountClosureDate');
    const remarksBlckByFieldPermissions = checkFieldPermissions('remarks');
    const addressFieldPermissions = checkFieldPermissions('fullAddress');
    const emailAddressFieldPermissions = checkFieldPermissions('email');
    const cityFieldPermissions = checkFieldPermissions('cityName');
    const dobFieldPermissions = checkFieldPermissions('dob');
    const cnicFieldPermissions = checkFieldPermissions('cnic');
    const cnicExpFieldPermissions = checkFieldPermissions('cnicExpiryDate');
    const segmentNameFieldPermissions = checkFieldPermissions('segmentName');
    const accOpeningChannelFieldPermissions = checkFieldPermissions('channelName');
    const accOpeningDateFieldPermissions = checkFieldPermissions('accountOpeningDate');
    const accClousreDateFieldPermissions = checkFieldPermissions('accountClosureDate');
    const deviceDetailsFieldPermissions = checkFieldPermissions('deviceDetails');
    const levelChangeDateFieldPermissions = checkFieldPermissions('level1UpdatedOn');
    const ultraAcOpeningDateFieldPermissions = checkFieldPermissions('ultraAcOpeningDate');
    const discrepantDocumentsFieldPermissions = checkFieldPermissions('discrepantDocuments');
    const clsVerifiedFieldPermissions = checkFieldPermissions('clsVerified');
    const pepFieldPermissions = checkFieldPermissions('pep');
    const bvsFieldPermissions = checkFieldPermissions('bvs');
    const actualBalanceFieldPermissions = checkFieldPermissions('actualBalance');
    const hraFieldPermissions = checkFieldPermissions('hra');
    const pinGenerationFieldPermissions = checkFieldPermissions('pinGeneration');
    const motherMaidenNameFieldPermissions = checkFieldPermissions('motherMaidenName');
    const birthPlaceFieldPermissions = checkFieldPermissions('birthPlace');
    const parentCnicFieldPermissions = checkFieldPermissions('parentCnic');
    const genderFieldPermissions = checkFieldPermissions('gender');
    const isFilerFieldPermissions = checkFieldPermissions('isFiler');
    const taxRegimeFieldPermissions = checkFieldPermissions('taxRegime');
    const isBlacklistFieldPermissions = checkFieldPermissions('isBlacklist');
    const pmdFieldPermissions = checkFieldPermissions('pmd');
    const riskLevelFieldPermissions = checkFieldPermissions('riskLevel');
    const riskLevelDateFieldPermissions = checkFieldPermissions('riskLevelDate');
    const nextRiskReviewDateFieldPermissions = checkFieldPermissions('nextRiskReviewDate');
    const hraNokNameFieldPermissions = checkFieldPermissions('hraNokName');
    const hraNokCnicFieldPermissions = checkFieldPermissions('hraNokCnic');
    const hraNokMobileNoFieldPermissions = checkFieldPermissions('hraNokMobileNo');
    const hraNokRelationshipNameFieldPermissions = checkFieldPermissions('hraNokRelationshipName');
    const internationalRemittanceLocationFieldPermissions = checkFieldPermissions('internationalRemittanceLocation');
    const hraOriginatorRelationshipNameFieldPermissions = checkFieldPermissions('hraOriginatorRelationshipName');
    const CloseAccountDisownCNICFieldPermissions = checkFieldPermissions('CloseAccountDisownCNIC');
    const CloseAccountViaMobileNumberFieldPermissions = checkFieldPermissions('CloseAccountViaMobileNumber');
    const EmailDocumentToCustomerFieldPermissions = checkFieldPermissions('EmailDocumentToCustomer');
    const DelinkRaastAMAFieldPermissions = checkFieldPermissions('DelinkRaastAMA');
    const RegenerateLoginPinFieldPermissions = checkFieldPermissions('RegenerateLoginPin');
    const ViewCustomerDocumentsFieldPermissions = checkFieldPermissions('ViewCustomerDocuments');
    const DownloadAccountFormsFieldPermissions = checkFieldPermissions('DownloadAccountForms');
    const channelNamePermissions = checkFieldPermissions('channelName');
    const AccountMaintenanceLogsPermissions = checkFieldPermissions('AccountMaintenanceLogs');
    const HraDetailsPermissions = checkFieldPermissions('HraDetails');
    const AccountMaintenancePermissions = checkFieldPermissions('AccountMaintenance');

    return (
        <>
            <ConfirmDialog style={{ width: '50vw !important' }} />

            {/* EDITABLE FIELDS DIALOGS */}

            {/* Edit Name */}
            <Dialog header="EDIT DETAILS" visible={displayNameEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditName rowData={rowData} isNameBlurRemoved={isNameBlurRemoved} onFullNameChange={handleFullNameChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Birth Place */}
            <Dialog header="EDIT DETAILS" visible={displayBirthPlaceEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditBirthPlace rowData={rowData} isBirthPlaceBlurRemoved={isBirthPlaceBlurRemoved} onBirthPlaceChange={handleBirthPlaceChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>
            {/* Edit Mother Name */}
            <Dialog header="EDIT DETAILS" visible={displayMotherNameEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditMotherName rowData={rowData} isMotherNameBlurRemoved={isMotherNameBlurRemoved} onMotherNameChange={handleMotherNameChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Account Type */}
            <Dialog header="EDIT DETAILS" visible={displayAccountTypeEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditAccountType onAccountTypeChange={handleAccountTypeChange} accountTypeData={accountTypeData} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Account Status */}
            <Dialog header="EDIT DETAILS" visible={displayAccountStatusEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditAccountStatus onAccountStatusChange={handleAccountStatusChange} accountStatus={accountStatus} formikValuees={formik.values} onHide={onHide} userTabsFieldData={userTabsFieldData} />
            </Dialog>

            {/* Edit Account Address */}
            <Dialog header="EDIT DETAILS" visible={displayAccountAddressEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditAddress onAccountAddressChange={handleAccountAddressChange} cityList={cityList} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Email Address */}
            <Dialog header="EDIT DETAILS" visible={displayAccountEmailEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditEmail onAccountEmailChange={handleAccountEmailChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Cnic Expiry Date */}
            <Dialog header="EDIT DETAILS" visible={displayAccountCnicExpiryDateEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditCnicExpiry rowData={rowData} isCnicExpBlurRemoved={isCnicExpBlurRemoved} onAccountCnicExpiryDateChange={handleAccountCnicExpiryDateChange} formikValuees={formik.values} onHide={onHide} userTabsFieldData={userTabsFieldData} />
            </Dialog>

            {/* Edit Discrepant Documents */}
            <Dialog header="EDIT DETAILS" visible={displayAccountDiscrepantDocsEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditDiscrepantDocs
                    rowData={rowData}
                    onAccountDiscrepantDocsChange={handleAccountDiscrepantDocsChange}
                    formikValuees={formik.values}
                    IsMinorAccount={IsMinorAccount}
                    IsMinor1Account={IsMinor1Account}
                    IsUltraSignatureAccount={IsUltraSignatureAccount}
                    onHide={onHide}
                    userTabsFieldData={userTabsFieldData}
                />
            </Dialog>

            {/* Edit Pin Generation */}
            <Dialog header="EDIT DETAILS" visible={displayAccountPinGenerationEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditPinGeneration rowData={rowData} onAccountPinGenerationChange={handleAccountPinGenerationChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Hra Next of Kin Name */}
            <Dialog header="EDIT DETAILS" visible={displayHraKinNameEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditHraKinName rowData={rowData} onHraKinNameChange={handleHraKinNameChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Hra Cnic */}
            <Dialog header="EDIT DETAILS" visible={displayHraCnicEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditHraCnic rowData={rowData} onHraCnicChange={handleHraCnicChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Hra Cnic */}
            <Dialog header="EDIT DETAILS" visible={displayHraMobileEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditHraMobile rowData={rowData} onHraMobileChange={handleHraMobileChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Hra Relationship */}
            <Dialog header="EDIT DETAILS" visible={displayHraRelationshipEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditHraRelationship rowData={rowData} relationshipOptions={relationshipOptions} onHraRelationshipChange={handleHraRelationshipChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Hra International Remittance Location */}
            <Dialog header="EDIT DETAILS" visible={displayHraIntRemittanceLocationEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditHraIntRemittanceLocation rowData={rowData} onHraIntRemittanceLocationChange={handleHraIntRemittanceLocationChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* Edit Hra Relationship with Originator */}
            <Dialog header="EDIT DETAILS" visible={displayHraRelationshipWithOriginatorEdit} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditHraRelationshipWithOriginator rowData={rowData} onHraRelationshipWithOriginatorChange={handleHraRelationshipWithOriginatorChange} formikValuees={formik.values} onHide={onHide} />
            </Dialog>

            {/* VIEWABLE FIELDS DIALOGS */}

            {/* View Segment */}
            <Dialog header="VIEW DETAILS" visible={displaySegmentView} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <ViewSegment rowData={rowData} onHide={onHide} userTabsFieldData={userTabsFieldData} handleSegmentNameChange={handleSegmentNameChange} />
            </Dialog>

            {/* View Bvs */}
            <Dialog header="VIEW DETAILS" visible={displayBvsView} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <ViewBvs rowData={rowData} onHide={onHide} userTabsFieldData={userTabsFieldData} />
            </Dialog>

            {/* View App Version */}
            <Dialog header="VIEW DETAILS" visible={appVersionView} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <ViewAppVersion handleWhiteListChange={handleWhiteListChange} rowData={rowData} onHide={onHide} userTabsFieldData={userTabsFieldData} accountStatusName={formik.values.accountStatusName} formikValuees={formik.values} />
            </Dialog>

            {/* ACCOUNT MAINTENANCE LOGS */}

            {/* Close Account DISOWN CUSTOMER Dialog */}
            <Dialog header="Close Account Disown Customer" blockScroll draggable={false} visible={displayModalCloseAccount} style={{ width: '30vw' }} onHide={() => onHideCloseAccount()}>
                <DialogCloseAccount onHideCloseAccount={onHideCloseAccount} customerCnic={formik.values.cnic} accountNumber={rowData?.accountNo} mobile={formik.values.mobileNo} balance={rowData?.actualBalance} />
            </Dialog>
            {/* Email Document To Customer Dialog */}
            <Dialog header="EMAIL DOCUMENTS TO CUSTOMER" blockScroll draggable={false} visible={displayModalEmailDoc} style={{ width: '30vw' }} onHide={() => onHideEmailDoc()}>
                <DialogEmailDocToCus onHideEmailDoc={onHideEmailDoc} rowData={rowData} customerId={customerIdData} customerEmail={formik.values.email} customerMobile={formik.values.mobileNo} cnicNumber={formik.values.cnic} />
            </Dialog>

            {/* Raast AMMA Current Status Dialog */}
            <Dialog header="Link/Delink RAAST" blockScroll draggable={false} visible={displayModalDLINK} style={{ width: '30vw' }} onHide={() => onHideDLINK()}>
                <DialogDLink onHideDLINK={onHideDLINK} rowData={rowData} />
            </Dialog>

            {/* Close Account Via Mobile Number Dialog */}
            <Dialog header="Close Account Via Mobile Number" blockScroll draggable={false} visible={displayModalCloseAccountMobileNo} style={{ width: '30vw' }} onHide={() => onHideCloseAccountMobileNo()}>
                <DialogCloseAccountMobileNo onHideCloseAccountMobileNo={onHideCloseAccountMobileNo} customerMobile={formik.values.mobileNo} accountNumber={rowData?.accountNo} />
            </Dialog>

            {/* View Customer Document Dialog */}
            <Dialog header="VIEW CUSTOMER DOCUMENT" blockScroll draggable={false} visible={displayModalViewCustomerDoc} style={{ width: '30vw' }} onHide={() => onHideViewCustomerDoc()}>
                <DialogViewCustomerDoc onHideViewCustomerDoc={onHideViewCustomerDoc} customerCnic={formik.values.cnic} customerId={customerIdData} />
            </Dialog>

            {/* Customer KYC Update */}
            <Dialog header="CUSTOMER KYC UPDATE" blockScroll draggable={false} visible={displayModalCustomrKyc} style={{ width: '30vw' }} onHide={() => onHideCustomerKycUpdate()}>
                <DialogCustomerKycUpdate onHideCustomerKycUpdate={onHideCustomerKycUpdate} />
            </Dialog>

            {/* Account Down Grade */}
            <Dialog header="ACCOUNT DOWN GRADE" blockScroll draggable={false} visible={displayModalAccDownGrade} style={{ width: '30vw' }} onHide={() => onHideAccDownGrade()}>
                <DialogAccountDownGrade onHideAccDownGrade={onHideAccDownGrade} />
            </Dialog>

            <div className="card Card__Round">
                {/* FORM */}

                <div>
                    <h6 className="secondary-color">
                        <b>VIEW DETAILS</b>
                    </h6>

                    <Divider />

                    <div className="p-fluid p-formgrid grid mb-3">
                        {nameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Name</label>
                                    <div className="flex align-items-center">
                                        <InputText
                                            id="fullName"
                                            value={formik.values.fullName ?? ''}
                                            onChange={formik.handleChange}
                                            onClick={(e) => {
                                                handleLogSubmit(e);
                                                const inputElement = e.target;
                                                inputElement.classList.remove('blur');
                                                setIsNameBlurRemoved(true);
                                            }}
                                            className={`Input__Round mr-2 ${isNameBlurRemoved ? '' : 'blur'}`}
                                            readOnly
                                        />
                                        {nameFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={() => {
                                                    if (type !== 'ViewPage') {
                                                        if (formik.values.accountStatusName !== 'CLOSED') {
                                                            handleNameEdit();
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {customerIdFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Customer ID</label>
                                    <InputText id="customerId" value={formik.values.customerId ?? ''} onChange={formik.handleChange} className="Input__Round" disabled />
                                </div>
                            </div>
                        )}
                        {mobileFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Mobile No.</label>
                                    <InputText
                                        id="mobileNo"
                                        value={formik.values.mobileNo ?? ''}
                                        onChange={formik.handleChange}
                                        onClick={(e) => {
                                            handleLogSubmit(e);
                                            const inputElement = e.target;
                                            inputElement.classList.remove('blur');
                                        }}
                                        className="Input__Round blur"
                                        readOnly
                                    />
                                </div>
                            </div>
                        )}
                        {ibanFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">IBAN</label>
                                    <InputText id="iban" value={formik.values.iban ?? ''} onChange={formik.handleChange} className="Input__Round" disabled />
                                </div>
                            </div>
                        )}
                        {/* {accountNoFieldPermissions.viewAllowed && ( */}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Account No</label>
                                <InputText id="iban" value={rowData?.accountNo ?? ''} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>
                        {/* )} */}
                        {accTypeFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Account Type</label>
                                    <div className="flex align-items-center">
                                        <Dropdown
                                            id="accountTypeId"
                                            options={accountTypeData}
                                            optionLabel="name"
                                            optionValue="lovId"
                                            value={formik.values.accountTypeId}
                                            onChange={formik.handleChange}
                                            className="Dropdown__Round mr-2 custom-dropdown"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {nadraVeriFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Nadra Verification</label>
                                    <Dropdown id="nadraVerified" options={nadraStatus} value={formik.values.nadraVerified ?? ''} onChange={formik.handleChange} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {acStatusFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">A/C Status</label>
                                    <div className="flex align-items-center">
                                        <Dropdown
                                            id="accountStatusId"
                                            options={accountStatus}
                                            optionLabel="accountStatusName"
                                            optionValue="accountStatusId"
                                            value={formik.values.accountStatusId ?? ''}
                                            onChange={formik.handleChange}
                                            className="Dropdown__Round mr-2 custom-dropdown"
                                            disabled
                                        />
                                        {acStatusFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={() => {
                                                    if (type !== 'ViewPage') {
                                                        if (formik.values.accountStatusName !== 'CLOSED') {
                                                            handleAccountStatusEdit();
                                                        }
                                                    }
                                                }}
                                            >
                                                {/* onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleAccountStatusEdit}> */}
                                            </i>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {accClousreDateFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">A/C Closure Date</label>
                                    <InputText id="accountClosureDate" type="date" value={formik.values.accountClosureDate ?? ''} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        )}
                        {remarksBlckByFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Remarks</label>
                                    <InputText id="remarks" value={formik.values.remarks ?? ''} onChange={formik.handleChange} className="Input__Round" disabled />
                                </div>
                            </div>
                        )}
                        {addressFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Present Address</label>
                                    <div className="flex align-items-center">
                                        <InputText id="fullAddress" value={formik.values.fullAddress ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                        {addressFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleAccountAddressEdit}
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {emailAddressFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Email Address</label>
                                    <div className="flex align-items-center">
                                        <InputText id="email" value={formik.values.email ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                        {emailAddressFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleAccountEmailEdit}
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {cityFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">City</label>
                                    <Dropdown id="cityId" options={cityList} optionLabel="name" optionValue="lovId" value={formik.values.cityId ?? ''} onChange={formik.handleChange} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {dobFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">DOB</label>
                                    <InputText
                                        id="dob"
                                        value={formik.values.dob ?? ''}
                                        onChange={formik.handleChange}
                                        onClick={(e) => {
                                            handleLogSubmit(e);
                                            const inputElement = e.target;
                                            inputElement.classList.remove('blur');
                                        }}
                                        className="Dropdown__Round blur"
                                        readOnly
                                    />
                                </div>
                            </div>
                        )}
                        {cnicFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">CNIC</label>
                                    <InputText
                                        id="cnic"
                                        value={formik.values.cnic ?? ''}
                                        onChange={formik.handleChange}
                                        onClick={(e) => {
                                            handleLogSubmit(e);
                                            const inputElement = e.target;
                                            inputElement.classList.remove('blur');
                                        }}
                                        className="Input__Round blur"
                                        readOnly
                                    />
                                </div>
                            </div>
                        )}
                        {cnicExpFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">CNIC Expiry</label>
                                    <div className="flex align-items-center">
                                        <InputText
                                            id="cnicExpiryDate"
                                            type="date"
                                            value={formik.values.cnicExpiryDate ?? ''}
                                            onChange={formik.handleChange}
                                            onClick={(e) => {
                                                handleLogSubmit(e);
                                                const inputElement = e.target;
                                                inputElement.classList.remove('blur');
                                                setIsCnicExpBlurRemoved(true);
                                            }}
                                            className={`Input__Round mr-2 ${isCnicExpBlurRemoved ? '' : 'blur'}`}
                                            readOnly
                                        />
                                        {cnicExpFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleAccountCnicExpiryDateEdit}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {segmentNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Segment</label>
                                    <div className="flex align-items-center">
                                        <InputText id="segmentName" value={formik.values.segmentName ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                        {/* <i className="field-edit-icon pi pi-pencil" onClick={handleViewDialog}></i> */}
                                        <i
                                            className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                            onClick={() => {
                                                if (type !== 'ViewPage') {
                                                    if (formik.values.accountStatusName !== 'CLOSED') {
                                                        handleViewDialog();
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {accOpeningChannelFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">A/C Opening Channel</label>
                                    <InputText id="channelName" value={formik.values.channelName ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        )}
                        {accOpeningDateFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">A/C Opening Date</label>
                                    <InputText id="accountOpeningDate" type="date" value={formik.values.accountOpeningDate ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        )}
                        {deviceDetailsFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Device Details</label>
                                    <div className="flex align-items-center">
                                        <InputText id="appVersionName" value={formik.values.appVersionName ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                        <i className="field-eye-icon pi pi-eye" onClick={handleAppVersionView}></i>
                                    </div>
                                </div>
                            </div>
                        )}
                        {levelChangeDateFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Level 1 Updated On</label>
                                    <InputText id="levelChangeDate" type="date" value={!formik?.values?.levelChangeDate ? '' : formik?.values?.levelChangeDate.split(' ')[0]} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                </div>
                            </div>
                        )}
                        {ultraAcOpeningDateFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">{`${IsUltraAccount ? 'Ultra Basic A/C Opening Date' : IsUltraSignatureAccount ? 'Ultra Signature A/C Opening Date' : 'Ultra A/C Opening Date'}`}</label>
                                    <InputText id="ultraAcOpeningDate" type="date" value={formik?.values?.ultraAcOpeningDate ?? ''} onChange={formik.handleChange} className="Input__Round" disabled />
                                </div>
                            </div>
                        )}
                        {/* {discrepantDocumentsFieldPermissions.viewAllowed &&
                            (rowData?.accountLevelName && IsLevel0 && rowData?.hra === 'N' && IsUltraFreelanceAccount === false ? (
                                ''
                            ) : rowData?.accountLevelName && IsLevel1 && rowData?.hra === 'N' && IsUltraFreelanceAccount === false ? (
                                ''
                            ) : (
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Discrepant Documents</label>
                                        <div className="flex align-items-center">
                                            <Dropdown
                                                id="discrepantDocs"
                                                placeholder={splitCnicFrontFileName || splitCnicBackFileName || splitProofOfIncomePicFileName ? [`${splitCnicFrontFileName}, ${splitCnicBackFileName}, ${splitProofOfIncomePicFileName}`] : ''}
                                                className="Dropdown__Round mr-2 custom-dropdown"
                                                disabled
                                            />
                                            {discrepantDocumentsFieldPermissions.updateAllowed && (
                                                <i
                                                    className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                    onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleAccountDiscrepantDocsEdit}
                                                ></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))} */}
                        {discrepantDocumentsFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Discrepant Documents</label>
                                    <div className="flex align-items-center">
                                        <Dropdown id="discrepantDocs" placeholder="If any" className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                        {discrepantDocumentsFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleAccountDiscrepantDocsEdit}
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {clsVerifiedFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">CLS Status</label>
                                    <Dropdown id="clsVerified" options={clsStatus} value={formik.values.clsVerified ?? ''} onChange={formik.handleChange} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {pepFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">PEP Status</label>
                                    <Dropdown id="aml" options={amlStatus} value={formik.values.aml ?? ''} onChange={formik.handleChange} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {bvsFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">BVS Details</label>
                                    <div className="flex align-items-center">
                                        <Dropdown id="bvs" options={bvsStatus} value={formik.values.bvs ?? ''} onChange={formik.handleChange} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                        {channelNamePermissions.viewAllowed && <i className="field-eye-icon pi pi-eye" onClick={handleBvsView}></i>}
                                    </div>
                                </div>
                            </div>
                        )}
                        {actualBalanceFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Available Balance</label>
                                    <InputText id="actualBalance" value={formik.values.actualBalance ?? ''} onChange={formik.handleChange} className="Input__Round" disabled />
                                </div>
                            </div>
                        )}
                        {hraFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">HRA</label>
                                    <Dropdown id="hra" options={hraStatus} value={formik.values.hra ?? ''} onChange={formik.handleChange} className="Dropdown__Round custom-dropdown" disabled />
                                </div>
                            </div>
                        )}
                        {pinGenerationFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Pin Generation</label>
                                    <div className="flex align-items-center">
                                        <Dropdown
                                            id="pinGeneration"
                                            options={pinGenerationLov}
                                            optionLabel="label"
                                            optionValue="id"
                                            value={formik.values.pinGeneration ?? ''}
                                            onChange={formik.handleChange}
                                            className="Dropdown__Round mr-2 custom-dropdown"
                                            disabled
                                        />
                                        {pinGenerationFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleAccountPinGenerationEdit}
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* {motherMaidenNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Mother Name</label>
                                    <InputText
                                        id="motherMaidenName"
                                        value={formik.values.motherMaidenName ?? ''}
                                        onChange={formik.handleChange}
                                        onClick={(e) => {
                                            handleLogSubmit(e);
                                            const inputElement = e.target;
                                            inputElement.classList.remove('blur');
                                        }}
                                        className="Input__Round blur"
                                        readOnly
                                    />
                                </div>
                            </div>
                        )} */}
                        {motherMaidenNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Mother Name</label>
                                    <div className="flex align-items-center">
                                        <InputText
                                            id="motherMaidenName"
                                            value={formik.values.motherMaidenName ?? ''}
                                            onChange={formik.handleChange}
                                            onClick={(e) => {
                                                handleLogSubmit(e);
                                                const inputElement = e.target;
                                                inputElement.classList.remove('blur');
                                                setIsMotherNameBlurRemoved(true);
                                            }}
                                            className={`Input__Round mr-2 ${isMotherNameBlurRemoved ? '' : 'blur'}`}
                                            readOnly
                                        />
                                        {motherMaidenNameFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={() => {
                                                    if (type !== 'ViewPage') {
                                                        if (formik.values.accountStatusName !== 'CLOSED') {
                                                            handleMotherNameEdit();
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {birthPlaceFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Birth Place</label>
                                    <div className="flex align-items-center">
                                        <InputText
                                            id="birthPlace"
                                            value={formik.values.birthPlace ?? ''}
                                            onChange={formik.handleChange}
                                            onClick={(e) => {
                                                handleLogSubmit(e);
                                                const inputElement = e.target;
                                                inputElement.classList.remove('blur');
                                                setIsBirthPlaceBlurRemoved(true);
                                            }}
                                            className={`Input__Round mr-2 ${isBirthPlaceBlurRemoved ? '' : 'blur'}`}
                                            readOnly
                                        />
                                        {birthPlaceFieldPermissions.updateAllowed && (
                                            <i
                                                className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                onClick={() => {
                                                    if (type !== 'ViewPage') {
                                                        if (formik.values.accountStatusName !== 'CLOSED') {
                                                            handleBirthPlaceEdit();
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Conditional Render Minor 0 Fields */}
                        {parentCnicFieldPermissions.viewAllowed &&
                            ((rowData?.accountLevelName && IsMinorAccount) || IsMinor1Account ? (
                                <>
                                    <div className="p-field col-12 md:col-4">
                                        <div className="p-field">
                                            <label className="Label__Text">Parent CNIC</label>
                                            <InputText id="parentCnic" value={formik.values.parentCnic ?? ''} onChange={formik.handleChange} className="Input__Round" disabled />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ''
                            ))}
                        {genderFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Gender</label>
                                    <InputText id="birthPlace" value={rowData?.gender ?? ''} className="Input__Round" readOnly />
                                </div>
                            </div>
                        )}
                        {isFilerFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Filer</label>
                                    <InputText id="birthPlace" value={rowData?.isFiler ?? ''} className="Input__Round" readOnly />
                                </div>
                            </div>
                        )}
                        {taxRegimeFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Tax Regime</label>
                                    <InputText id="birthPlace" value={rowData?.taxRegime ?? ''} className="Input__Round" readOnly />
                                </div>
                            </div>
                        )}
                        {isBlacklistFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Blacklist</label>
                                    <InputText id="birthPlace" value={rowData?.isBlacklist ?? ''} className="Input__Round" readOnly />
                                </div>
                            </div>
                        )}
                        {pmdFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">PMD</label>
                                    <InputText id="birthPlace" value={rowData?.pmd === 'N' ? 'NO' : rowData?.pmd === 'Y' ? 'YES' : 'N/A'} className="Input__Round" readOnly />
                                </div>
                            </div>
                        )}
                        {riskLevelFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Risk Level</label>
                                    <InputText id="birthPlace" value={rowData?.riskLevel ?? ''} className="Input__Round" readOnly />
                                </div>
                            </div>
                        )}
                        {riskLevelDateFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Risk Level Date</label>
                                    <InputText id="birthPlace" value={rowData?.riskLevelDate ?? ''} className="Input__Round" readOnly />
                                </div>
                            </div>
                        )}
                        {nextRiskReviewDateFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label className="Label__Text">Next Risk Review Date</label>
                                    <InputText id="birthPlace" value={rowData?.nextRiskReviewDate ?? ''} className="Input__Round" readOnly />
                                </div>
                            </div>
                        )}
                    </div>

                    {rowData?.accountLevelName && IsUltraAccount ? (
                        <div className="hra_head">
                            <h5 className="secondary-color">
                                <b>Ultra Basic</b>
                            </h5>
                        </div>
                    ) : IsUltraSignatureAccount ? (
                        <div className="hra_head">
                            <h5 className="secondary-color">
                                <b>Ultra Signature</b>
                            </h5>
                        </div>
                    ) : (
                        ''
                    )}

                    {/* Conditional Render Ultra Basic Fields */}
                    {rowData?.accountLevelName && IsUltraAccount ? <UltraFields rowData={rowData} userTabsFieldData={userTabsFieldData} /> : ''}

                    {rowData?.accountLevelName && IsUltraFreelanceAccount ? (
                        <div className="hra_head">
                            <h5 className="secondary-color">
                                <b>Ultra Freelance</b>
                            </h5>
                        </div>
                    ) : (
                        ''
                    )}

                    {/* Conditional Render Ultra Freelancer Fields */}
                    {rowData?.accountLevelName && (IsUltraFreelanceAccount || IsUltraSignatureAccount) ? (
                        <UltraFreeLanceFields rowData={rowData} IsUltraFreelanceAccount={IsUltraFreelanceAccount} IsUltraSignatureAccount={IsUltraSignatureAccount} userTabsFieldData={userTabsFieldData} />
                    ) : (
                        ''
                    )}
                </div>

                {/* PETRO POCKET */}

                <EditPetroPocket rowData={rowData} accountStatusLov={accountStatus} formikValuees={formik.values} />

                {/*ACCOUNT MAINTENANCE LOGS */}
                {AccountMaintenanceLogsPermissions.viewAllowed && <AccountMaintenanceLogs rowData={rowData} />}

                {HraDetailsPermissions.viewAllowed &&
                    (rowData && rowData?.hra === 'Y' ? (
                        <>
                            {/* HRA DETAILS */}
                            <HraAccountDetails rowData={rowData} userTabsFieldData={userTabsFieldData} />

                            {/* HRA NEXT OF KIN */}
                            <section>
                                <div className="mt-6">
                                    <h5 className="secondary-color">
                                        <b>NEXT OF KIN DETAILS</b>
                                    </h5>
                                    <Divider />
                                </div>

                                <div className="p-fluid p-formgrid grid">
                                    {hraNokNameFieldPermissions.viewAllowed && (
                                        <div className="p-field col-12 md:col-4">
                                            <div className="p-field">
                                                <label className="Label__Text">Name</label>
                                                <div className="flex align-items-center">
                                                    <InputText id="hraNokName" value={formik.values.hraNokName ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                                    {hraNokNameFieldPermissions.updateAllowed && (
                                                        <i
                                                            className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                            onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleHraKinNameEdit}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {hraNokCnicFieldPermissions.viewAllowed && (
                                        <div className="p-field col-12 md:col-4">
                                            <label className="Label__Text"> CNIC</label>
                                            <div className="flex align-items-center">
                                                <InputText id="hraNokCnic" value={formik.values.hraNokCnic ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                                {hraNokCnicFieldPermissions.updateAllowed && (
                                                    <i
                                                        className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                        onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleHraCnicEdit}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {hraNokMobileNoFieldPermissions.viewAllowed && (
                                        <div className="p-field col-12 md:col-4">
                                            <label className="Label__Text">Mobile Number</label>
                                            <div className="flex align-items-center">
                                                <InputText id="hraNokMobileNo" value={formik.values.hraNokMobileNo ?? ''} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                                {hraNokMobileNoFieldPermissions.viewAllowed && (
                                                    <i
                                                        className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                        onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleHraMobileEdit}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {hraNokRelationshipNameFieldPermissions.viewAllowed && (
                                        <div className="p-field col-12 md:col-4">
                                            <label className="Label__Text">Relationship</label>
                                            <div className="flex align-items-center">
                                                <InputText id="hraNokRelationshipName" value={formik.values.hraNokRelationshipName} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                                {hraNokRelationshipNameFieldPermissions.viewAllowed && (
                                                    <i
                                                        className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'pi-ban' : 'pi-pencil'}`}
                                                        onClick={formik.values.accountStatusName === 'CLOSED' ? '' : handleHraRelationshipEdit}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {internationalRemittanceLocationFieldPermissions.viewAllowed && (
                                        <div className="p-field col-12 md:col-4">
                                            <label className="Label__Text">International Remittance Location</label>
                                            <div className="flex align-items-center">
                                                <InputText id="ntn" value={formik.values.ntn} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                                {/* {internationalRemittanceLocationFieldPermissions.viewAllowed && (
                                                    <i className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? "pi-ban" : "pi-pencil"}`} onClick={formik.values.accountStatusName === 'CLOSED' ? "" : handleHraIntRemittanceLocationEdit} />
                                                )} */}
                                            </div>
                                        </div>
                                    )}
                                    {hraOriginatorRelationshipNameFieldPermissions && (
                                        <div className="p-field col-12 md:col-4">
                                            <label className="Label__Text">Relationship with Originator</label>
                                            <div className="flex align-items-center">
                                                <InputText id="hraOriginatorRelationshipName" value={formik.values.hraOriginatorRelationshipName} onChange={formik.handleChange} className="Input__Round mr-2" disabled />
                                                {/* {hraOriginatorRelationshipNameFieldPermissions && (
                                                    <i className={`field-edit-icon pi ${type === 'ViewPage' ? 'p-disabled' : ''} ${formik.values.accountStatusName === 'CLOSED' ? "pi-ban" : "pi-pencil"}`} onClick={formik.values.accountStatusName === 'CLOSED' ? "" : handleHraRelationshipWithOriginatorEdit} />
                                                )} */}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </section>

                            {/* HRA LIMITS */}
                            <HraLimits rowData={rowData} userTabsFieldData={userTabsFieldData} />

                            {/* DEBIT CREDIT*/}
                            <DebitCredit rowData={rowData} />

                            {/* TRANSACTION*/}
                            <Transaction rowData={rowData} />
                        </>
                    ) : (
                        ''
                    ))}

                {AccountMaintenancePermissions.viewAllowed && (
                    <>
                        <div className="mt-6">
                            <h5 className="secondary-color">
                                <b>ACCOUNT MAINTENANCE</b>
                            </h5>
                            <Divider />
                        </div>

                        {/* Dialog data */}
                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 justify-content-center pt-4 gap-4">
                            {CloseAccountDisownCNICFieldPermissions.viewAllowed && (
                                <div
                                    className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                        formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                    }`}
                                    onClick={formik.values.accountStatusName === 'CLOSED' || !CloseAccountDisownCNICFieldPermissions.updateAllowed ? null : handledCloseAccount}
                                >
                                    <div>Close Account Disown Customer</div>
                                </div>
                            )}
                            {CloseAccountViaMobileNumberFieldPermissions.viewAllowed && (
                                <div
                                    className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                        formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                    }`}
                                    onClick={formik.values.accountStatusName === 'CLOSED' || !CloseAccountViaMobileNumberFieldPermissions.updateAllowed ? null : handledCloseAccountMobileNo}
                                >
                                    <div>Close Account Via Mobile Number</div>
                                </div>
                            )}
                            {EmailDocumentToCustomerFieldPermissions.viewAllowed && (
                                <div
                                    className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                        formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                    }`}
                                    onClick={formik.values.accountStatusName === 'CLOSED' || !EmailDocumentToCustomerFieldPermissions.updateAllowed ? null : handledEmailDoc}
                                >
                                    <div>Email Document to Customer</div>
                                </div>
                            )}
                            {DelinkRaastAMAFieldPermissions.viewAllowed && (
                                <div
                                    className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                        formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                    }`}
                                    onClick={formik.values.accountStatusName === 'CLOSED' || !DelinkRaastAMAFieldPermissions.updateAllowed ? null : handledDLINK}
                                >
                                    <div>Link/Delink RAAST</div>
                                </div>
                            )}
                            {RegenerateLoginPinFieldPermissions.viewAllowed && (
                                <div
                                    className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                        formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                    }`}
                                    onClick={formik.values.accountStatusName === 'CLOSED' || !RegenerateLoginPinFieldPermissions.updateAllowed ? null : handledLoginPin}
                                >
                                    <div>Regenerate Login Pin</div>
                                </div>
                            )}
                            {/* {ViewCustomerDocumentsFieldPermissions.viewAllowed &&
                                (rowData?.accountLevelName && IsLevel0 && rowData?.hra === 'N' && IsUltraFreelanceAccount === false ? (
                                    ''
                                ) : rowData?.accountLevelName && IsLevel1 && rowData?.hra === 'N' && IsUltraFreelanceAccount === false ? (
                                    ''
                                ) : (
                                    <div
                                        className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                            }`}
                                        onClick={formik.values.accountStatusName === 'CLOSED' || !ViewCustomerDocumentsFieldPermissions.updateAllowed ? null : handledViewCustomerDoc}
                                    >
                                        <div>View Customer Documents</div>
                                    </div>
                                ))} */}
                            {ViewCustomerDocumentsFieldPermissions.viewAllowed && (
                                <div
                                    className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                        formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                    }`}
                                    onClick={formik.values.accountStatusName === 'CLOSED' || !ViewCustomerDocumentsFieldPermissions.updateAllowed ? null : handledViewCustomerDoc}
                                >
                                    <div>View Customer Documents</div>
                                </div>
                            )}
                            {DownloadAccountFormsFieldPermissions.viewAllowed && (
                                <div
                                    className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                        formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                    }`}
                                    onClick={formik.values.accountStatusName === 'CLOSED' || !DownloadAccountFormsFieldPermissions.updateAllowed ? null : handledDownloadAccountForm}
                                >
                                    <div>Download Account Forms</div>
                                </div>
                            )}
                            <div
                                className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                    formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                }`}
                                onClick={formik.values.accountStatusName === 'CLOSED' || !DownloadAccountFormsFieldPermissions.updateAllowed ? null : handledViewCustomrKyc}
                            >
                                <div>Customer KYC Update</div>
                            </div>
                            <div
                                className={`${type === 'ViewPage' ? 'Disabled__AccountMaintenance' : ''} ${
                                    formik.values.accountStatusName === 'CLOSED' ? 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons bg_dim' : 'col-span-1 md:col-span-1 lg:col-span-1 edit-ac-buttons'
                                }`}
                                onClick={formik.values.accountStatusName === 'CLOSED' || !DownloadAccountFormsFieldPermissions.updateAllowed ? null : handledViewAccDownGrade}
                            >
                                <div>Account Down Grade</div>
                            </div>
                        </div>
                    </>
                )}
                <div className="Down__Btn mt-6 pt-6">
                    <Button label="Back" iconPos="right" className="Btn__cancel mr-2" onClick={() => navigate(-1)} />
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" onClick={goBack} />
                </div>
            </div>

            {/* Action Authorization History */}
            <AuthorizationHistoryTable refTableId={customerIdData} tableName={tableName} viewable={true} />
        </>
    );
};

export default AccountDetailsEdit;

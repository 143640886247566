import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const LienHistory = (props) => {
    return (
        <>
            <div className="grid pt-5">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No List found."
                            header="Lien History"
                            responsiveLayout="scroll"
                            value={props?.data}
                        >
                            <Column field="amount" header="Amount" />
                            <Column field={(e) => e?.status || 'N/A'} header="Status" />
                            <Column field="createdate" header="Created Date" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LienHistory;

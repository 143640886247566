import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { baseURL } from '../../../../Config';

const EditPetroPocketStatement = ({ rowData, onHide }) => {
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDateError, setFromDateError] = useState('');
    const [toDateError, setToDateError] = useState('');

    const dispatch = useDispatch();

    const handleStatement = async (type) => {
        let valid = true;

        if (!fromDate) {
            setFromDateError('From date is required.');
            valid = false;
        } else {
            setFromDateError('');
        }

        if (!toDate) {
            setToDateError('To date is required.');
            valid = false;
        } else {
            setToDateError('');
        }

        if (!valid) {
            return;
        }

        let body = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    name: rowData?.fullName,
                    email: rowData?.email,
                    cnic: rowData?.cnic,
                    accountNumber: rowData?.petroAccountNo,
                    mobileNumber: rowData?.mobileNo,
                    fromDate: fromDate,
                    toDate: toDate,
                    customerId: rowData?.customerId,
                    type: 'Petro'
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        try {
            if (type === 'send') {
                // Send request
                await dispatch(handlePostRequest(body, '/account/v1/petroaccount/sendpetroaccountstatment', false, false));
            } else {
                // Download statement
                setLoading(true);
                const res = await dispatch(handlePostRequest(body, '/account/v1/petroaccount/downloadpetrostatment', false, false));

                console.log('res', res);

                if (res?.responseCode === '010000') {
                    const path = res?.payLoad?.path.split('/opt/wildfly/standalone/documents/accountStatement/')[1];
                    window.open(`${baseURL}/document/accountStatement/${path}`, '_blank');
                } else {
                    console.error('Error: Invalid response code', res);
                }
            }
        } catch (error) {
            console.error('An error occurred while processing the statement:', error);
        } finally {
            setLoading(false);
            onHide();
        }
    };

    return (
        <div className="Card__Round">
            <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12">
                    <label className="Label__Text">Email</label>
                    <InputText value={rowData?.email} className="Input__Round" disabled />
                </div>
            </div>
            <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12">
                    <label className="Label__Text">From</label>
                    <InputText
                        value={fromDate}
                        onChange={(e) => {
                            setFromDate(e.target.value);
                            if (e.target.value) {
                                setFromDateError('');
                            }
                        }}
                        className="Input__Round"
                        type="date"
                    />
                    {fromDateError && <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{fromDateError}</div>}
                </div>
            </div>
            <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12">
                    <label className="Label__Text">To</label>
                    <InputText
                        value={toDate}
                        onChange={(e) => {
                            setToDate(e.target.value);
                            if (e.target.value) {
                                setToDateError('');
                            }
                        }}
                        className="Input__Round"
                        type="date"
                    />
                    {toDateError && <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{toDateError}</div>}
                </div>
            </div>

            <div className="Down__Btn">
                <Button label="Send" type="submit" className="Btn__Dark" onClick={() => handleStatement('send')} />
                <Button label="Download" className="Btn__Dark" type="button" onClick={() => handleStatement('download')} loading={loading} />
                <Button
                    label="Cancel"
                    onClick={(e) => {
                        e.preventDefault();
                        onHide(false);
                    }}
                    // disabled={loading}
                    className="Btn__Transparent"
                />
            </div>
        </div>
    );
};

export default EditPetroPocketStatement;

import React from 'react';

const AccountMaintenanceLogs = ({ rowData }) => {
    // const [showDialog, setShowDialog] = useState(false)
    // const handleDialog = () => {
    //     setShowDialog(true)
    // }

    // const onHide = () => {
    //     setShowDialog(false)
    // }

    return (
        <>
            {/* <Dialog header="View Details" visible={showDialog} onHide={() => { setShowDialog(false) }} style={{ width: "70vw" }} >
                <AccountMaintenanceLogsDialog onHide={onHide} rowData={rowData} />
            </Dialog> */}
            <div className="card custom-card">
                <div className="flex align-items-center justify-content-between">
                    <h5 className="dim-heading">Account Maintenance Logs</h5>
                    {/* <i className='field-eye-icon pi pi-eye' onClick={handleDialog}></i> */}
                </div>

                <div className="custom-table text-center">
                    <table>
                        <tbody>
                            <tr className="custom-t-head">
                                <th className="dim-heading">Limit</th>
                                <th className="dim-heading">Consumed</th>
                                <th className="dim-heading">Remaining</th>
                            </tr>
                            <tr>
                                <td>Daily Limit</td>
                                <td>{rowData?.dailyDebitConsumed}</td>
                                <td>{rowData?.dailyDebitRemaining}</td>
                            </tr>
                            <tr>
                                <td>Monthly Limit</td>
                                <td>{rowData?.monthlyDebitConsumed}</td>
                                <td>{rowData?.monthlyDebitRemaining}</td>
                            </tr>
                            <tr>
                                <td>Yearly Limit</td>
                                <td>{rowData?.yearlyDebitConsumed}</td>
                                <td>{rowData?.yearlyDebitRemaining}</td>
                            </tr>
                            {rowData && rowData?.freelanceAccount === 'Y' && (
                                <>
                                    <tr>
                                        <td>Freelance Daily Limit</td>
                                        <td>{rowData?.dailyDebitConsumedFl}</td>
                                        <td>{rowData?.dailyDebitRemainingFl}</td>
                                    </tr>
                                    <tr>
                                        <td>Freelance Monthly Limit</td>
                                        <td>{rowData?.monthlyDebitConsumedFl}</td>
                                        <td>{rowData?.monthlyDebitRemainingFl}</td>
                                    </tr>
                                    <tr>
                                        <td>Freelance Yearly Limit</td>
                                        <td>{rowData?.yearlyDebitConsumedFl}</td>
                                        <td>{rowData?.yearlyDebitRemainingFl}</td>
                                    </tr>
                                </>
                            )}
                            {rowData && rowData?.hra === 'Y' && (
                                <>
                                    <tr>
                                        <td>HRA Daily Limit</td>
                                        <td>{rowData?.hraDailyDebitConsumed}</td>
                                        <td>{rowData?.hraDailyDebitRemaining}</td>
                                    </tr>
                                    <tr>
                                        <td>HRA Monthly Limit</td>
                                        <td>{rowData?.hraMonthlyDebitConsumed}</td>
                                        <td>{rowData?.hraMonthlyDebitRemaining}</td>
                                    </tr>
                                    <tr>
                                        <td>HRA Yearly Limit</td>
                                        <td>{rowData?.hraYearlyDebitConsumed}</td>
                                        <td>{rowData?.hraYearlyDebitRemaining}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default AccountMaintenanceLogs;

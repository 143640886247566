import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import eyeIcon from '../../../../../src/assets/images/view_icon_y.png';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { baseURL } from '../../../../Config';
import UltraDualNationalityFields from '../UltraAccountDetailsComponents/UltraDualNationalityFields';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import EditFreelanceStatement from '../AccountDetailsBasicDialogs/EditFreelanceStatement';
import { Button } from 'primereact/button';

const UltraFreeLanceFields = ({ rowData, IsUltraFreelanceAccount, IsUltraSignatureAccount, userTabsFieldData }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [cnicFront, setCnicFront] = useState([]);
    const [cnicBack, setCnicBack] = useState([]);
    // const [viewCustomerDocData, setViewCustomerDocData] = useState([]);

    const dispatch = useDispatch();

    function getCurrentURL() {
        return window.location.href;
    }

    const url = getCurrentURL();
    const isChecker = url.includes('editcheckeraccountdetails') || url.includes('viewcheckeraccountdetails');

    const openModal = () => {
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const splitCnicFrontFileName = rowData?.cnicFrontPic ? rowData?.cnicFrontPic.split('/').pop() : '';
    const splitCnicBackFileName = rowData?.cnicBankPic ? rowData?.cnicBankPic.split('/').pop() : '';
    const splitCustomerVideoFileName = rowData?.customerVideo ? rowData?.customerVideo.split('/').pop() : '';
    const splitProofOfIncomePicFileName = rowData?.proofOfIncomePic ? rowData?.proofOfIncomePic.split('/').pop() : '';
    const splitUtilityBillFileName = rowData?.utilityBill ? rowData?.utilityBill.split('/').pop() : '';

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData.find((f) => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === 'Y',
            updateAllowed: field?.updateAllowed === 'Y'
        };
    };

    const getCustomerAccountDocs = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    customerId: rowData?.customerId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/documents/v1/customeraccounts/getcustomeraccountdocs', true, true, 'accountsmanagement'));
        if (res?.responseCode === '070000') {
            const documents = res?.payLoad;
            const cnicFrontObj = documents.filter((item) => item?.lkpDocumentType?.documentTypeName === 'CNIC_FRONT');
            const cnicBackObj = documents.filter((item) => item?.lkpDocumentType?.documentTypeName === 'CNIC_BACK');
            const cnicFrontBase64 = 'data:image/png;base64,' + cnicFrontObj[0]?.documentPath;
            const cnicBackBase64 = 'data:image/png;base64,' + cnicBackObj[0]?.documentPath;
            setCnicFront(cnicFrontBase64);
            setCnicBack(cnicBackBase64);
            // setViewCustomerDocData(res?.payLoad);
        }
    };

    const cnicFrontPicFieldPermissions = checkFieldPermissions('cnicFrontPic');
    const cnicBankPicFieldPermissions = checkFieldPermissions('cnicBankPic');
    const customerVideoFieldPermissions = checkFieldPermissions('customerVideo');
    const dualNationalityFieldPermissions = checkFieldPermissions('dualNationality');
    const professionNameFieldPermissions = checkFieldPermissions('professionName');
    const ultraUsageNameFieldPermissions = checkFieldPermissions('ultraUsageName');
    const monthlySpendingFieldPermissions = checkFieldPermissions('monthlySpending');
    const currencyNameFieldPermissions = checkFieldPermissions('currencyName');
    const proofOfIncomePicFieldPermissions = checkFieldPermissions('proofOfIncomePic');
    const chequeBookFieldPermissions = checkFieldPermissions('chequeBook');
    const signatureFieldPermissions = checkFieldPermissions('signature');
    const utilityBillFieldPermissions = checkFieldPermissions('utilityBill');
    const cityNameFieldPermissions = checkFieldPermissions('cityName');
    const areaNameFieldPermissions = checkFieldPermissions('areaName');
    const streetNoFieldPermissions = checkFieldPermissions('streetNo');
    const houseNoFieldPermissions = checkFieldPermissions('houseNo');

    const handleDialog = () => {
        setDisplayDialog(true);
    };

    useEffect(() => {
        getCustomerAccountDocs();
    }, []);

    return (
        <>
            {/* Customer Video Dialog */}
            <Dialog header="Customer Video" visible={isModalVisible} onHide={closeModal} style={{ width: '20vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <div className="modal">
                    <div className="modal-content text-center">
                        {rowData?.customerVideo ? (
                            <video controls width="320" height="240">
                                <source src={`${baseURL}/document` + rowData?.customerVideo?.substring(33)} type="video/mp4" />
                            </video>
                        ) : (
                            'No Video Found'
                        )}
                    </div>
                </div>
            </Dialog>

            <Dialog header="Freelance Account Statement" blockScroll draggable={false} visible={displayDialog} style={{ width: '30vw' }} onHide={() => setDisplayDialog(false)}>
                <EditFreelanceStatement rowData={rowData} onHide={setDisplayDialog} />
            </Dialog>

            <div className="p-fluid p-formgrid grid mb-3">
                {cnicFrontPicFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CNIC Front</label>
                            <div className="flex align-items-center">
                                <InputText id="cnicFrontPic" value={splitCnicFrontFileName ?? ''} className="Input__Round mr-2" disabled />
                                <Image className="img-viewer-ultra" src={eyeIcon} zoomSrc={cnicFront ? cnicFront : 'No Image Available'} alt="Image" width="80" height="60" preview />
                            </div>
                        </div>
                    </div>
                )}
                {cnicBankPicFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">CNIC Back</label>
                            <div className="flex align-items-center">
                                <InputText id="cnicBankPic" value={splitCnicBackFileName ?? ''} className="Input__Round mr-2" disabled />
                                <Image className="img-viewer-ultra" src={eyeIcon} zoomSrc={cnicBack ? cnicBack : 'No Image Available'} alt="Image" width="80" height="60" preview />
                            </div>
                        </div>
                    </div>
                )}
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Video</label>
                        <div className="flex align-items-center">
                            <InputText id="customerVideo" value={splitCustomerVideoFileName ?? ''} className="Input__Round mr-2" disabled />
                            {customerVideoFieldPermissions.viewAllowed && <Image className="img-viewer-ultra" src={eyeIcon} alt="Image" width="80" height="60" onClick={openModal} style={{ cursor: 'pointer' }} />}
                        </div>
                    </div>
                </div>
                {dualNationalityFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Dual Nationality</label>
                            <Dropdown placeholder={rowData?.dualNationality === 'Y' ? 'Yes' : rowData?.dualNationality === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                        </div>
                    </div>
                )}
            </div>

            {rowData && rowData?.dualNationality === 'Y' ? (
                <div className="custom-card p-5 mb-3">
                    <UltraDualNationalityFields rowData={rowData} userTabsFieldData={userTabsFieldData} />
                </div>
            ) : (
                ''
            )}

            <div className="p-fluid p-formgrid grid mb-3">
                {professionNameFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Occupation</label>
                            <Dropdown placeholder={rowData?.professionName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                        </div>
                    </div>
                )}

                {ultraUsageNameFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">
                                Purpose <small>(Ultra Usage)</small>
                            </label>
                            <Dropdown placeholder={rowData?.ultraUsageName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                        </div>
                    </div>
                )}

                {monthlySpendingFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Monthly Spending</label>
                            <Dropdown placeholder={rowData?.monthlySpending ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                        </div>
                    </div>
                )}

                {IsUltraFreelanceAccount && IsUltraFreelanceAccount ? (
                    currencyNameFieldPermissions.viewAllowed && (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Account Currency</label>
                                <Dropdown placeholder={rowData?.currencyName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                            </div>
                        </div>
                    )
                ) : (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Proof of Income</label>
                            <div className="flex align-items-center">
                                <Dropdown placeholder={splitProofOfIncomePicFileName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                {proofOfIncomePicFieldPermissions.viewAllowed && (
                                    <Image
                                        className="img-viewer-ultra"
                                        src={eyeIcon}
                                        zoomSrc={rowData?.proofOfIncomePic ? `${baseURL}/document` + rowData?.proofOfIncomePic?.substring(33) : 'No Image Available'}
                                        alt="Image"
                                        width="80"
                                        height="60"
                                        preview
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {chequeBookFieldPermissions.viewAllowed && (
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Cheque Book</label>
                            <div className="flex align-items-center">
                                <Dropdown placeholder={rowData?.chequeBook === 'Y' ? 'Yes' : rowData?.chequeBook === 'N' ? 'No' : ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                {/* {
                                rowData && rowData?.chequeBook === "Y" ?
                                    <i className="field-eye-icon pi pi-eye"></i>
                                    :
                                    ""
                            } */}
                            </div>
                        </div>
                    </div>
                )}
                {signatureFieldPermissions.viewAllowed &&
                    (rowData && rowData?.chequeBook === 'Y' ? (
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Signature</label>
                                <div className="flex align-items-center">
                                    <InputText id="signaturePic" value={rowData?.signaturePic ?? ''} className="Input__Round mr-2" disabled />
                                    {signatureFieldPermissions.viewAllowed && (
                                        <Image
                                            className="img-viewer-ultra"
                                            src={eyeIcon}
                                            zoomSrc={rowData?.signaturePic ? `${baseURL}/document` + rowData?.signaturePic?.substring(33) : 'No Image Available'}
                                            alt="Image"
                                            width="80"
                                            height="60"
                                            preview
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    ))}
                {utilityBillFieldPermissions.viewAllowed && IsUltraSignatureAccount && IsUltraSignatureAccount ? (
                    <>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Utility Bill</label>
                                <div className="flex align-items-center">
                                    <Dropdown placeholder={splitUtilityBillFileName ?? ''} className="Dropdown__Round mr-2 custom-dropdown" disabled />
                                    <Image className="img-viewer-ultra" src={eyeIcon} zoomSrc={rowData?.utilityBill ? `${baseURL}/document` + rowData?.utilityBill?.substring(33) : 'No Image Available'} alt="Image" width="80" height="60" preview />
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </div>

            {IsUltraFreelanceAccount && (
                <>
                    <div className="flex justify-content-center">
                        <div className="Down__Btn" style={{ width: '300px', marginBottom: '25px' }}>
                            <Button label="Freelance Account Statement" className="Btn__Dark w-100" onClick={handleDialog} disabled={isChecker} />
                        </div>
                    </div>
                </>
            )}

            {IsUltraSignatureAccount && IsUltraSignatureAccount ? (
                <>
                    <div>
                        <h4>ADDRESS DETAIL</h4>
                    </div>

                    <Divider />

                    <div className="p-fluid p-formgrid grid mb-3">
                        {cityNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <label className="Label__Text">City</label>
                                <Dropdown placeholder={rowData?.cityName ?? ''} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        )}
                        {areaNameFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <label className="Label__Text">Area</label>
                                <Dropdown placeholder={rowData?.areaName ?? ''} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        )}
                        {streetNoFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <label className="Label__Text">Street Number</label>
                                <InputText placeholder={rowData?.streetNo ?? ''} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        )}
                        {houseNoFieldPermissions.viewAllowed && (
                            <div className="p-field col-12 md:col-4">
                                <label className="Label__Text">House Number</label>
                                <InputText placeholder={rowData?.houseNo ?? ''} className="Dropdown__Round custom-dropdown" disabled />
                            </div>
                        )}
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default UltraFreeLanceFields;

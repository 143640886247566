import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import { handleGetRequest } from '../../../../services/GetTemplate';

const EditAccountStatus = ({ onAccountStatusChange, accountStatus, formikValuees, onHide, userTabsFieldData }) => {
    const [acStatusLov, setAcStatusLov] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState('');
    const [statusWarning, setStatusWarning] = useState('');

    const msgs = useRef(null);

    const validationSchema = Yup.object().shape({
        accountStatusId: Yup.string().required('This Field is required.'),
        remarks: Yup.string().min(10).max(200).required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountStatusId: formikValuees?.accountStatusId ? formikValuees?.accountStatusId : '',
            remarks: ''
        },

        onSubmit: async () => {
            let accountStatusValues = {
                accountStatusId: formik.values.accountStatusId,
                accountStatusName: formik.values.accountStatusName,
                remarks: formik.values.remarks
            };
            onAccountStatusChange(accountStatusValues);
            onHide();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const userDetails = sessionStorage.getItem('userDetails');
    const parsedUserDetails = JSON.parse(userDetails);
    const userId = parsedUserDetails?.userId;

    const getAccStatusByUserId = async () => {
        const res = await handleGetRequest(`/account/v1/customeraccounts/getCxStatusLov/${userId}`);

        if (res?.responseCode === '010000') {
            setAcStatusLov(res?.payLoad);
        }
    };

    useEffect(() => {
        getAccStatusByUserId();
    }, []);

    useEffect(() => {
        if (formik.values.accountStatusName === 'CLOSED') {
            setDisableSubmit(true);
            msgs.current.show([{ severity: 'warn', detail: 'Account Closure Request Denied. To close your account, please select "Close Account Disown CNIC" or "Close Account Via Mobile Number" option!' }]);
        } else if (formik.values.accountStatusName === 'DORMANT') {
            setDisableSubmit(true);
            msgs.current.show([{ severity: 'warn', detail: 'Status Change Not Allowed!' }]);
        } else {
            setDisableSubmit(false);
        }
    }, [formik.values.accountStatusName]);

    useEffect(() => {
        if (formik.values.remarks) {
            if (formik.values.remarks.trim() !== '' && formik.values.remarks === formik.values.remarks.trim()) {
                setDisableSubmit(false);
                setError('');
            } else {
                setDisableSubmit(true);
                setError('Whitespaces and leading/trailing spaces are not allowed');
            }
        }
    }, [formik.values.remarks]);

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData.find((f) => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === 'Y',
            updateAllowed: field?.updateAllowed === 'Y'
        };
    };

    const accountStatusIdFieldPermissions = checkFieldPermissions('accountStatusId');
    const remarksFieldPermissions = checkFieldPermissions('remarks');

    return (
        <>
            <Messages ref={msgs} />

            <form onSubmit={formik.handleSubmit}>
                <div className="Card__Round">
                    <div className="p-fluid p-formgrid grid">
                        {accountStatusIdFieldPermissions.viewAllowed && (
                            <div className="p-field col-12">
                                <label className="Label__Text">Name</label>
                                <span className="steric">*</span>
                                <Dropdown
                                    id="accountStatusId"
                                    name="accountStatusId"
                                    placeholder="--Select--"
                                    options={acStatusLov}
                                    optionLabel="accountStatusName"
                                    optionValue="accountStatusId"
                                    value={formik.values.accountStatusId}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        // Manually map the selected value to accountStatusName
                                        const selectedAccountStatusId = e.value;
                                        console.log('selectedAccountStatusId', selectedAccountStatusId);
                                        const selectedAccountStatus = accountStatus.find((option) => parseInt(option.accountStatusId) === selectedAccountStatusId);
                                        const selectedAccountStatusName = selectedAccountStatus ? selectedAccountStatus.accountStatusName : '';
                                        formik.setFieldValue('accountStatusName', selectedAccountStatusName);
                                        if (formikValuees?.accountStatusId == selectedAccountStatusId) {
                                            setDisable(true);
                                            setStatusWarning(
                                                <>
                                                    <small>This status is already selected</small>
                                                </>
                                            );
                                        } else {
                                            setStatusWarning('');
                                            setDisable(false);
                                        }
                                    }}
                                    // optionDisabled={(item) => {
                                    //     return !['SUSPENDED', 'ACTIVE', 'CLOSED', 'DORMANT', 'BACKOFFICE BLOCKED'].includes(item?.accountStatusName);
                                    // }}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountStatusId') }, 'Dropdown__Round')}
                                    appendTo="self"
                                />
                                {getFormErrorMessage('accountStatusId')}
                                {statusWarning ? statusWarning : ''}
                            </div>
                        )}
                        {remarksFieldPermissions.viewAllowed && (
                            <div className="p-field col-12">
                                <label className="Label__Text">Remarks</label>
                                <span className="steric">*</span>
                                <InputTextarea
                                    id="remarks"
                                    name="remarks"
                                    value={formik.values.remarks}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('remarks') }, 'TextArea__Round')}
                                    rows={5}
                                    cols={30}
                                    autoResize
                                    min={10}
                                />
                                {error && <div style={{ color: 'red' }}>{error}</div>}
                                {getFormErrorMessage('remarks')}
                            </div>
                        )}
                    </div>

                    <div className="Down__Btn mt-4">
                        <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" disabled={disableSubmit || disable} />
                        <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditAccountStatus;

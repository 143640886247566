import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';

const DialogAccountDownGrade = ({ onHideAccDownGrade }) => {
    return (
        <>
            <div className="card Card__Round">
                <div className="grid">
                    <div className="col-12">
                        <label className="Label__Text">Current Account Level</label>
                        <Dropdown
                            id="fullName"
                            // value={formik.values.fullName ?? ''}
                            // onChange={formik.handleChange}
                            placeholder="Select"
                            className={`Dropdown__Round mr-2`}
                            readOnly
                        />
                    </div>
                    <div className="col-12">
                        <label className="Label__Text">Target Account Level</label>
                        <Dropdown
                            id="fullName"
                            // value={formik.values.fullName ?? ''}
                            // onChange={formik.handleChange}
                            placeholder="Select"
                            className={`Dropdown__Round mr-2`}
                            readOnly
                        />
                    </div>
                    <div className="col-12">
                        <label className="Label__Text">Comments</label>
                        <InputTextarea autoResize placeholder="-" rows={5} cols={30} id="reason" name="reason" className="TextArea__Round" />
                    </div>
                </div>
            </div>
            <div className="Down__Btn">
                <Button label="Submit" type="submit" className="Btn__Dark" />
                <Button label="Cancel" className="Btn__Transparent" onClick={onHideAccDownGrade} />
            </div>
        </>
    );
};

export default DialogAccountDownGrade;

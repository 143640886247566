import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { Dropdown } from 'primereact/dropdown';
import { getStatusLov } from '../../../../redux/slices/LkpStatusLovSlice';
import { getTblUser } from '../../../../redux/slices/TblUserSlice';
import { PaginationCall, PaginationTemplate } from '../../../../components/Pagination';
import { Paginator } from 'primereact/paginator';

const ManageAccounts = () => {
    const lkpStatusLov = useSelector((state) => state?.LkpStatusLovSlice?.data);
    const tblUserLov = useSelector((state) => state?.TblUserSlice?.data);

    const [disabled, setDisabled] = useState(true);
    const [manageAccountsData, setManageAccountsData] = useState([]);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);
    const [apiHit, setApiHit] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const dataTableRef = useRef(null);

    const validationSchema = Yup.object().shape({
        name: Yup.string().notRequired('This Field is notRequired.'),
        accountNumber: Yup.number().notRequired('This Field is notRequired.'),
        cnic: Yup.number().notRequired('This Field is notRequired.'),
        mobileNumber: Yup.number().notRequired('This Field is notRequired.'),
        email: Yup.string().email().notRequired('This Field is notRequired.'),
        dateFrom: Yup.string().notRequired('This Field is notRequired.'),
        dateTo: Yup.string().notRequired('This Field is notRequired.'),
        statusId: Yup.string().notRequired('This Field is notRequired.'),
        createdBy: Yup.string().notRequired('This Field is notRequired.'),
        updatedBy: Yup.string().notRequired('This Field is required.'),
        type: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: '',
            accountNumber: '',
            cnic: '',
            mobileNumber: '',
            email: '',
            dateFrom: '',
            dateTo: '',
            statusId: '2',
            createdBy: '',
            updatedBy: '',
            type: '',
            ntn: '',
            page: '1',
            size: customRows1
        },
        onSubmit: async (data) => {
            const jsonObj = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },

                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },

                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',

                    payLoad: data,

                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],

                    checkSum: ''
                }
            };
            dataTableRef.current.reset();
            setApiHit(jsonObj?.data?.payLoad);

            const res = await dispatch(handlePostRequest(jsonObj, '/account/v1/customeraccounts/getallcustomers', true, true));
            if (res?.responseCode === '010000') {
                setManageAccountsData(res?.payLoad);
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => editHandler(rowData)} className="p-button-rounded p-button-warning mr-3" />

                <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => handleCustomerView(rowData)} className="p-button-rounded p-button-primary" />
            </div>
        );
    };

    const editHandler = (rowData) => {
        if (rowData.searchType === 'C') {
            navigate('/viewaccountdetails', {
                state: { rowData }
            });
            localStorage.setItem('cxLastSearchValues', JSON.stringify(formik.values));
        } else if (rowData.searchType === 'A') {
            navigate('/editagentaccountmaintenance', { state: { rowData } });
        }
    };

    const handleCustomerView = (rowData) => {
        if (rowData.searchType === 'C') {
            navigate('/viewcustomerdetails', {
                state: { rowData }
            });
            localStorage.setItem('cxLastSearchValues', JSON.stringify(formik.values));
        } else if (rowData.searchType === 'A') {
            navigate('/viewagentaccountmaintenance', { state: { rowData } });
        }
    };

    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusName === 'Approved' ? '#14A38B' : rowData?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';

        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusName}</b>
            </p>
        );
    };

    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
        setManageAccountsData('');
        localStorage.removeItem('cxLastSearchValues');
    };

    //Account type dropdown options
    const accountType = [
        {
            name: 'Agent',
            id: 'A'
        },
        {
            name: 'Customer',
            id: 'C'
        }
    ];

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        ...apiHit,
        page: currentPage,
        size: customRows1
    };

    useEffect(() => {
        if (
            formik.values.name === '' &&
            formik.values.accountNumber === '' &&
            formik.values.cnic === '' &&
            formik.values.mobileNumber === '' &&
            formik.values.email === '' &&
            formik.values.dateFrom === '' &&
            formik.values.dateTo === '' &&
            formik.values.statusId === '' &&
            formik.values.createdBy === '' &&
            formik.values.updatedBy === '' &&
            formik.values.type === '' &&
            formik.values.ntn === ''
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [formik.values]);

    useEffect(() => {
        const { state } = location;
        if (state) {
            if (localStorage.getItem('cxLastSearchValues') !== null && (state?.prevPath === '/editaccountdetails' || state?.prevPath === '/viewaccountdetails' || state?.prevPath === '/viewcustomerdetails')) {
                const cxLastSearchValues = localStorage.getItem('cxLastSearchValues');
                const parsedCxLastSearchValues = JSON.parse(cxLastSearchValues);
                formik.setFieldValue('name', parsedCxLastSearchValues?.name && parsedCxLastSearchValues?.name);
                formik.setFieldValue('accountNumber', parsedCxLastSearchValues?.accountNumber && parsedCxLastSearchValues?.accountNumber);
                formik.setFieldValue('cnic', parsedCxLastSearchValues?.cnic && parsedCxLastSearchValues?.cnic);
                formik.setFieldValue('mobileNumber', parsedCxLastSearchValues?.mobileNumber && parsedCxLastSearchValues?.mobileNumber);
                formik.setFieldValue('email', parsedCxLastSearchValues?.email && parsedCxLastSearchValues?.email);
                formik.setFieldValue('fromDate', parsedCxLastSearchValues?.fromDate && parsedCxLastSearchValues?.fromDate);
                formik.setFieldValue('toDate', parsedCxLastSearchValues?.toDate && parsedCxLastSearchValues?.toDate);
                formik.setFieldValue('statusId', parsedCxLastSearchValues?.statusId && parsedCxLastSearchValues?.statusId);
                formik.setFieldValue('createdBy', parsedCxLastSearchValues?.createdBy && parsedCxLastSearchValues?.createdBy);
                formik.setFieldValue('updatedBy', parsedCxLastSearchValues?.updatedBy && parsedCxLastSearchValues?.updatedBy);
                formik.setFieldValue('type', parsedCxLastSearchValues?.type && parsedCxLastSearchValues?.type);
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('hashchange', (event) => {
            const oldUrl = event.oldURL.split('/').pop();
            const newUrl = event.newURL.split('/').pop();

            if (newUrl === 'accountsmanagement' && (oldUrl === 'editaccountdetails' || oldUrl === 'viewaccountdetails' || oldUrl === 'viewcustomerdetails')) {
                const cxLastSearchValues = localStorage.getItem('cxLastSearchValues');
                const parsedCxLastSearchValues = JSON.parse(cxLastSearchValues);
                formik.setFieldValue('name', parsedCxLastSearchValues?.name && parsedCxLastSearchValues?.name);
                formik.setFieldValue('accountNumber', parsedCxLastSearchValues?.accountNumber && parsedCxLastSearchValues?.accountNumber);
                formik.setFieldValue('cnic', parsedCxLastSearchValues?.cnic && parsedCxLastSearchValues?.cnic);
                formik.setFieldValue('mobileNumber', parsedCxLastSearchValues?.mobileNumber && parsedCxLastSearchValues?.mobileNumber);
                formik.setFieldValue('email', parsedCxLastSearchValues?.email && parsedCxLastSearchValues?.email);
                formik.setFieldValue('fromDate', parsedCxLastSearchValues?.fromDate && parsedCxLastSearchValues?.fromDate);
                formik.setFieldValue('toDate', parsedCxLastSearchValues?.toDate && parsedCxLastSearchValues?.toDate);
                formik.setFieldValue('statusId', parsedCxLastSearchValues?.statusId && parsedCxLastSearchValues?.statusId);
                formik.setFieldValue('createdBy', parsedCxLastSearchValues?.createdBy && parsedCxLastSearchValues?.createdBy);
                formik.setFieldValue('updatedBy', parsedCxLastSearchValues?.updatedBy && parsedCxLastSearchValues?.updatedBy);
                formik.setFieldValue('type', parsedCxLastSearchValues?.type && parsedCxLastSearchValues?.type);
            }
        });
    }, []);

    useEffect(() => {
        if (lkpStatusLov === undefined || lkpStatusLov.length === 0) dispatch(getStatusLov());
        if (tblUserLov === undefined || tblUserLov.length === 0) dispatch(getTblUser());
    }, []);

    return (
        <div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Name</label>
                                <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')} keyfilter={/^[A-Za-z\s]+$/} maxLength={30} />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Account No</label>
                                <InputText
                                    id="accountNumber"
                                    name="accountNumber"
                                    value={formik.values.accountNumber}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountNumber') }, 'Input__Round')}
                                    keyfilter="int"
                                />
                                {getFormErrorMessage('accountNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">CNIC</label>
                                <InputText id="cnic" name="cnic" value={formik.values.cnic} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')} keyfilter="int" maxLength={13} />
                                {getFormErrorMessage('cnic')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Mobile No.</label>
                                <InputText
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    value={formik.values.mobileNumber}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNumber') }, 'Input__Round')}
                                    keyfilter="int"
                                    maxLength={11}
                                />
                                {getFormErrorMessage('mobileNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label className="Label__Text">Email</label>
                                <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')} keyfilter={/^[\w.@]+$/} />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText id="dateFrom" type="date" name="dateFrom" value={formik?.values?.dateFrom} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')} />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText id="dateTo" type="date" name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="statusId" className={classNames({ 'p-error': isFormFieldValid('statusId') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Filter"
                                    options={lkpStatusLov}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={tblUserLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={tblUserLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    id="type"
                                    placeholder="Select Account Type"
                                    options={accountType}
                                    optionLabel="name"
                                    name="type"
                                    optionValue="id"
                                    value={formik.values.type || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('type')}
                            </div>
                        </div>
                        {formik.values.type === 'A' && (
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="ntn" className={classNames({ 'p-error': isFormFieldValid('ntn') }, 'Label__Text')}>
                                        NTN
                                    </label>
                                    <InputText id="ntn" name="ntn" value={formik.values.ntn || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('ntn') }, 'Input__Round')} />

                                    {getFormErrorMessage('ntn')}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="p-field col-12 flex justify-content-center">
                        <div className="p-field">
                            <div className="Down__Btn">
                                <Button label="Search" disabled={disabled} iconPos="right" className="Btn__Dark" />
                                <Button label="Reset" onClick={(e) => handleReset(e)} className="Btn__Transparent" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="card p-datatable-sm custom-border">
                <div>
                    <h4>Account Details</h4>
                </div>
                <PaginationCall
                    render={render}
                    customFirst1={customFirst1}
                    customRows1={customRows1}
                    currentPage={currentPage}
                    dataTableRef={dataTableRef}
                    setData={setManageAccountsData}
                    RequestData={RequestData}
                    url={'/account/v1/customeraccounts/getallcustomers'}
                    responseCode={'010000'}
                />
                <div className="custom-datatable">
                    <DataTable
                        // header={header}
                        value={manageAccountsData}
                        ref={dataTableRef}
                        emptyMessage="No data found."
                        className="datatable-responsive"
                        responsiveLayout="scroll"
                    >
                        <Column field="mobileNo" header="Mobile No." />
                        <Column field="fullName" header="Name" />
                        <Column field="accountLevelName" header="Account Level" />
                        <Column field="accountOpeningDate" header="Created Date & Time" />
                        <Column field="cnic" header="CNIC" />
                        <Column field="email" header="Email" />
                        <Column body={statusBodyTemplate} header="Status" />
                        <Column field="accountStatusDescr" header="Account Status" />
                        <Column className="Table__ActionHeading__CentredAlign" body={actionTemplate} header="Actions" />
                    </DataTable>
                    <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={manageAccountsData?.[1]?.totalRecordCount} onPageChange={onCustomPageChange1}></Paginator>
                </div>
            </div>
        </div>
    );
};

export default ManageAccounts;

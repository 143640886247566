import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';
import moment from 'moment';

const EditBirthPlace = ({ rowData, isBirthPlaceBlurRemoved, onBirthPlaceChange, formikValuees, onHide }) => {
    const [isRemoved, setIsRemoved] = useState(false);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        birthPlace: Yup.string()
            .max(30, 'Field cannot exceed 30 characters')
            // .test('name-change-limit', 'You can update or remove up to 20% of your current name spelling', function (value) {
            //     const currentName = rowData?.birthPlace || '';
            //     const maxLengthChange = Math.floor(currentName.length * 0.2);

            //     const differences = value && (Array.from(value).filter((char, index) => char !== currentName.charAt(index)) || '');

            //     return differences?.length <= maxLengthChange && value.length + maxLengthChange >= currentName.length;
            // })
            .required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            birthPlace: formikValuees?.birthPlace ? formikValuees?.birthPlace : ''
        },

        onSubmit: async () => {
            onBirthPlaceChange({ birthPlace: formik.values.birthPlace, isRemoved: isRemoved });
            onHide();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const userDetails = sessionStorage.getItem('userDetails');
    const parsedUserDetails = JSON.parse(userDetails);
    const userId = parsedUserDetails?.userId;
    const userName = parsedUserDetails?.firstName + ' ' + parsedUserDetails?.lastName;

    // HANDLE LOGS SUBMIT
    const handleLogSubmit = async (e) => {
        const isBlur = e?.target?.classList.contains('blur');

        const fieldName = e.target.id;
        const fieldValue = e.target.value;

        if ((userId && userName && e.target.id) || e.target.value) {
            const payloadService = { reqType: 'View', userId: userId, userName: userName, [fieldName]: fieldValue, accountNumber: rowData?.accountNo };

            e.preventDefault();
            if (isBlur) {
                try {
                    const data = {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        payload: {
                            serviceName: rowData?.customerId,
                            endpoint: 'Cx-1',
                            dateTime: moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
                            payloadService: JSON.stringify(payloadService),
                            message: 'CX-1 Logs',
                            loggingLevel: 'INFO',
                            packageName: 'abcservice.ais',
                            loggerID: '(abcservice--0.0.0-8009-3)'
                        },
                        indexName: 'cx'
                    };

                    await dispatch(handlePostRequest(data, '/producer/kafka/logs/publish', true, true));
                } catch (error) {}
            }
        }
    };

    useEffect(() => {
        setIsRemoved(isBirthPlaceBlurRemoved);
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="Card__Round">
                    <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12">
                            <label className="Label__Text">Birth Place</label>
                            <InputText
                                id="birthPlace"
                                name="birthPlace"
                                value={formik.values.birthPlace}
                                onChange={formik.handleChange}
                                onClick={(e) => {
                                    handleLogSubmit(e);
                                    const inputElement = e.target;
                                    inputElement.classList.remove('blur');
                                    setIsRemoved(true);
                                }}
                                className={classNames(
                                    { 'p-invalid': isFormFieldValid('birthPlace') },
                                    'Input__Round',
                                    (isBirthPlaceBlurRemoved === undefined && isRemoved === undefined) || (isBirthPlaceBlurRemoved === undefined && isRemoved === false) || (isBirthPlaceBlurRemoved === false && isRemoved === false) ? 'blur' : '',
                                    'unique__class'
                                )}
                                keyfilter={/^[A-Za-z\s]+$/}
                                maxLength={30}
                            />
                            {getFormErrorMessage('birthPlace')}
                        </div>
                    </div>

                    <div className="Down__Btn mt-4">
                        <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" />
                        <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditBirthPlace;

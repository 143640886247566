import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React from 'react';

const DialogCustomerKycUpdate = ({ onHideCustomerKycUpdate }) => {
    return (
        <>
            <div className="card Card__Round">
                <div className="grid">
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className="Label__Text">Mother Name</label>
                        <InputText
                            id="fullName"
                            // value={formik.values.fullName ?? ''}
                            // onChange={formik.handleChange}
                            placeholder="Enter Mother Name"
                            className={`Input__Round mr-2`}
                            readOnly
                        />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className="Label__Text">Place of Birth</label>
                        <InputText
                            id="fullName"
                            // value={formik.values.fullName ?? ''}
                            // onChange={formik.handleChange}
                            placeholder="Enter Mother Name"
                            className={`Input__Round mr-2`}
                            readOnly
                        />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className="Label__Text">Select Mother Name</label>
                        <Dropdown
                            id="fullName"
                            // value={formik.values.fullName ?? ''}
                            // onChange={formik.handleChange}
                            placeholder="Select Mother Name"
                            className={`Dropdown__Round mr-2`}
                            readOnly
                        />
                    </div>
                    <div className="col-12 md:col-6 lg:col-6">
                        <label className="Label__Text">Expiry Date</label>
                        <InputText
                            type="date"
                            id="toDate"
                            // min={previousDate}
                            name="toDate"
                            className="Input__Round"
                        />
                    </div>
                    <div className="col-12">
                        <label className="Label__Text">Comments</label>
                        <InputTextarea autoResize placeholder="-" rows={5} cols={30} id="reason" name="reason" className="TextArea__Round" />
                    </div>
                </div>
            </div>
            <div className="Down__Btn">
                <Button label="Submit" type="submit" className="Btn__Dark" />
                <Button label="Cancel" className="Btn__Transparent" onClick={onHideCustomerKycUpdate} />
            </div>
        </>
    );
};

export default DialogCustomerKycUpdate;
